import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setSignIn } from "../../components/redux/slicer";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-number-input";
import logoImg from "../../static/images/logo.png";
import "react-phone-number-input/style.css";
import { baseURL } from "../../config";
import { CircularProgress } from "@mui/material";

const Login = () => {
  const [phone, setPhone] = useState("");
  const [otp, setotp] = useState("");
  const [getotp, setgetotp] = useState(null);
  const [toggle, settoggle] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [resetLoading, setIsResendLoading] = useState(false);
  const [timer, setTimer] = useState(15);
  const [canResend, setCanResend] = useState(false);
  const [resendCount, setResendCount] = useState(0);

  const nav = useNavigate();
  const authKey = localStorage.getItem('Key')

  useEffect(() => {
    if (authKey) {
      nav('/dashboard')
    }
  }, [authKey])

  // const handleLogin = async (e) => {
  //   e.preventDefault();
  //   if (phone.length < 10) {
  //     toast("Phone number should contain 10 characters");
  //     return;
  //   }
  //   const loginData = {
  //     phone: phone,
  //     fcm_key: null,
  //   };
  //   const options = {
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   };
  //   try {
  //     setIsBtnLoading(true)
  //     const resp = await axios.post(
  //       `${process.env.REACT_APP_BASE_URL}signin/?generateotp=true`,
  //       loginData,
  //       options
  //     );
  //     setgetotp(resp.data.data.otp);
  //     setUserInfo(resp.data)
  //     console.log(resp)
  //     if (
  //       resp.data?.copany_info?.user_type === "Driver" ||
  //       resp.data?.usertype === "END_USER"
  //     ) {
  //       toast("You can't login in this panel");
  //       return;
  //     }
  //     toast("otp sent sucessfully");
  //     setIsBtnLoading(false)
  //     settoggle(true);
  //   } catch (error) {
  //     setIsBtnLoading(false)
  //     console.log(error, "err");
  //     if (error.response && error.response.status === 401) {
  //       toast("invalid Login credentials");
  //     } else {
  //       toast("Somthing went wrong, please try later");
  //     }
  //   }
  // };
  // const handleotp = (e) => {
  //   e.preventDefault();
  //   if (getotp !== otp) {
  //     toast("OTP not verified, please try again later");
  //     return;
  //   }
  //   localStorage.setItem("A_phone", userInfo.data.phone);
  //   localStorage.setItem("A_usertype", userInfo.data.usertype);
  //   localStorage.setItem("A_firstname", userInfo.data.firstname);
  //   localStorage.setItem("A_lastname", userInfo.data.lastname);
  //   localStorage.setItem("A_userid", userInfo.data.id);
  //   localStorage.setItem("A_companyinfo", userInfo.data.copany_info);
  //   localStorage.setItem("Key", userInfo.access);
  //   dispatch(
  //     setSignIn({
  //       phone: userInfo.data.phone,
  //       usertype: userInfo.data.usertype,
  //       firstname: userInfo.data.firstname,
  //       lastname: userInfo.data.lastname,
  //       userid: userInfo.data.id,
  //       companyinfo: userInfo.data.copany_info,
  //     })
  //   );
  //   toast("Login SucessFully");
  //   nav("/dashboard");
  // };

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer(prevTimer => prevTimer - 1);
      }, 1000);
    } else {
      clearInterval(interval);
      setCanResend(true);
    }
    return () => clearInterval(interval);
  }, [timer]);


  const requestResendOtp = async () => {
    try {
      setIsResendLoading(true)
      const resp = await axios.get(
        `${baseURL}/send-otp/?phone=${phone}&login=true`,
      ).then((response) => {
        if (response.data.is_exist) {
          setgetotp(response.data.data);
          settoggle(true)
          setIsResendLoading(false)
        }
        else {
          toast('User does not exist. Please register first.')
          setPhone("")
        }
        return response;
      })
    } catch (error) {
      setIsResendLoading(false)
      toast("Something went wrong");
    }
  }


  const handleResendOtp = async () => {
    console.log(canResend)
    if (canResend) {
      setResendCount(prevCount => prevCount + 1);
      if (resendCount < 5) {
        toast("OTP send to your phone number");
        setTimer(20);
        requestResendOtp();
      }
      else if (resendCount >= 5 && resendCount < 10) {
        toast("OTP send to your phone number");
        setTimer(40);
        requestResendOtp();
      } else if (resendCount >= 10) {
        toast("You have requested multiple times. Please try again later.");
        setTimer(60);
        // requestResendOtp();
        // Possibly make a call to the backend to block the user for a day
        // ... blockUserForDay(); (you'd have to implement this)
      } else {
        toast('OTP send to your phone number')
        requestResendOtp();
      }
    }
  };


  const handleSendOtp = async () => {
    if (phone.length >= 14 || phone.length < 10) {
      toast("Enter Valid Number");
      return;
    }
    try {
      setIsBtnLoading(true)
      const resp = await axios.get(
        `${baseURL}send-otp/?phone=${phone}&login=true&manager=true`,
      ).then((response) => {
        console.log(response.data, "data")
        if (response.data.is_exist) {
          setgetotp(response.data.data);
          settoggle(true)
          setCanResend(false);
          setTimer(15)
          toast('OTP send to your phone number')
        }
        else {
          toast('User does not exist. Please register first.')
          setPhone("")
        }
        setIsBtnLoading(false)
        return response;
      })
    } catch (error) {
      setIsBtnLoading(false)
      toast("Invalid Phone Number");
    }

  }


  const handleLogin = async () => {
    if (getotp !== otp) {
      toast("Invalid Otp");
      return;
    }
    const loginData = {
      phone: phone,
      fcm_key: "",
    };
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      setIsBtnLoading(true)
      const resp = await axios.post(
        `${baseURL}signin/`,
        loginData,
        options
      );
      if (resp.status === 200) {
        localStorage.setItem("A_phone", resp.data.data.phone);
        localStorage.setItem("A_usertype", resp.data.data.usertype);
        localStorage.setItem("A_firstname", resp.data.data.firstname);
        localStorage.setItem("A_lastname", resp.data.data.lastname);
        localStorage.setItem("A_userid", resp.data.data.id);
        localStorage.setItem("A_companyinfo", resp.data.data.copany_info);
        localStorage.setItem("Key", resp.data.access);
        localStorage.setItem("A_profile_image", resp.data.data.profile_image);

        // dispatch(
        //   setSignIn({
        //     phone: resp.data.data.phone,
        //     usertype: resp.data.data.usertype,
        //     firstname: resp.data.data.firstname,
        //     lastname: resp.data.data.lastname,
        //     userid: resp.data.data.id,
        //     companyinfo: resp.data.data.copany_info
        //   })
        // );
        setIsBtnLoading(false)
        toast("Login SucessFully");
        nav("/dashboard");
        window.location.reload()
      }
    } catch (error) {
      setIsBtnLoading(false)
      toast("Something went wrong, please try again later");
      nav("/");
      window.location.reload()
    }
  };





  return (
    <div className="container-scroller">
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="content-wrapper d-flex align-items-center auth" style={{padding: 0}}>
          <div className="row flex-grow">
            <div className="col-lg-4 mx-auto">
              <div className="auth-form-light text-left p-5">
                <div className="brand-logo text-center">
                  <img src={logoImg} style={{ width: "25%" }} />
                </div>
                <h6 className="text-center font-weight-light mb-4">Sign in to company portal.</h6>
                
                  <div className="form-group">
                    <PhoneInput
                      className="form-control"
                      defaultCountry="SE"
                      value={phone}
                      onChange={setPhone}
                      limitMaxLength={true}
                      placeholder={"Phone Number"}
                      readOnly={toggle}
                      autoFocus />
                  </div>

                  {toggle ? <div className="d-flex justify-content-end mb-2">
                    {timer > 0 ?
                      <span>{`You can resend code in ${timer} seconds`}</span> :
                      <span onClick={handleResendOtp} className="ms-2 resendBtn" style={{ cursor: canResend ? 'pointer' : 'not-allowed', opacity: canResend ? 1 : 0.5 }}>
                        {resetLoading ? 'sending...' : <strong style={{color: "#A660FF"}}>Resend OTP</strong>}
                      </span>
                    }
                  </div> : null}

                  {toggle ? <div className="form-group">
                    <input
                      type="text"
                      maxLength={4}
                      onChange={(e) => setotp(e.target.value)}
                      className="form-control"
                      id="exampleInputPassword"
                      placeholder="OTP"
                    />
                  </div> : null}

                  <div className="mt-3 text-center">
                    <button type="button"
                      onClick={toggle === true ? handleLogin : handleSendOtp}
                      className="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn"
                      disabled={isBtnLoading}
                    >
                      {isBtnLoading ? (
                        <CircularProgress size={"1.2rem"} sx={{ color: "#FFF" }} />
                      ) : (
                        toggle ? "Login" : "Send OTP" 
                      )}
                    </button>

                  </div>
               
              </div>
            </div>
          </div>
        </div>

      </div>

    </div >
  );
};

export default Login;
