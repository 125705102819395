import React, { useEffect, useState } from "react";
import axios from "axios";
import { selectUserType } from "../../redux/slicer";
import { useSelector } from "react-redux";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import PaginationComponent from "../PaginationComponent";
import { toast } from "react-toastify";
import DeleteConfirmationModel from "../deleteConfirmationModel";
import { Button, Rating } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


const CompanyCards = () => {
  // const userType = useSelector(selectUserType)
  const userType = localStorage.getItem("A_usertype");
  const nav = useNavigate();
  const [IsLoading, setIsLoading] = useState(false);
  const [companyData, setCompanyData] = useState({ "results": [] });
  const [currentPage, setCurrentPage] = useState(1);
  const [update, setupdate] = useState(false);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const [showModel, setShowModel] = useState({
    "show": false,
    "id": null
  });

  const handleShowModel = (id) => {
    setShowModel({
      "show": true,
      "id": id
    })
  }
  const handleCloseModel = () => {
    setShowModel({
      "show": false,
      "id": null
    })
  }

  const accessToken = localStorage.getItem("Key");
  const handleGetCompanyData = async () => {
    setIsLoading(true);
    try {
      console.log("acce");
      if (userType === "ADMIN_USER") {
        const resp = await axios
          .get(`${process.env.REACT_APP_BASE_URL}all-company/?paginate=true&page=${currentPage}`, {
            headers: {
              Authorization: "Bearer " + accessToken,
            },
          })
          .then((response) => {
            return response;
          });
        let results = resp.data
        setCompanyData(results);
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    handleGetCompanyData();
  }, [update, currentPage]);

  const handleDeleteCompany = async (id) => {
    handleCloseModel()
    setIsLoading(true);
    try {
      console.log("acce");
      const resp = await axios
        .delete(
          `${process.env.REACT_APP_BASE_URL}retrive-delete-company-account/${id}`,
          {
            headers: {
              Authorization: "Bearer " + accessToken,
            },
          }
        )
        .then((response) => {
          console.log(response, "response");
          setupdate(!update);
          return response.data.msg;
        });
      toast(resp);
    } catch (error) {
      console.log(error);
      // setMessege("Your session is expired, please Login again")
    }
    setIsLoading(false);
  };

  return (
    <>
      <DeleteConfirmationModel showModel={showModel} onHandleDelete={handleDeleteCompany} handleCloseModel={handleCloseModel}></DeleteConfirmationModel>


      {/* {showFilter && <FilterForm
        fields={ridesFields}
        onFilter={handleRidesFilter}
        onClearFilters={handleClearFilters}
        isLoading={isFilterLoading}
      />} */}


{IsLoading ?
        <div className="d-flex justify-content-center align-items-center my-5 py-5">
          <CircularProgress />
        </div>
        :

        <>{
          companyData.results.map((el, i) => {
            return (
              <div className="card shadow mb-3 grid-margin stretch-card" key={i}>
                <div className="card-body">
                  <h5 className="mb-3"><strong>Company {el.id}</strong></h5>
                  <div className="row">
                    <div className="col-md-4">
                      <p><strong>Name</strong><br /> <span className="text-dark">{el.name}</span></p>
                      <p><strong>About</strong><br /> <span className="text-dark">{el.about}</span></p>
                      <p><strong>Email</strong><br /> <span className="text-dark"> {el.organization_email}</span></p>
                    </div>
                    <div className="col-md-4">
                      <p><strong>Phone</strong><br /> <span className="text-dark">{el.phone_number}</span></p>
                      <p><strong>Address</strong><br /> <span className="text-dark"> {el.address}</span></p>
                      <p><strong>Organization Number</strong><br /> <span className="text-dark"> {el.organization_number}</span></p>
                    </div>
                    <div className="col-md-4">
                      <p><strong>Website</strong><br /> <span className="text-dark"><a href="{el.website}" target="_blank">link</a></span></p>
                      <p><strong>Rating</strong><br /> 
                      <span className="text-dark">
                      <Rating defaultValue={el.rating} readOnly />
                        </span></p>
                    </div>
                  </div>
                  <div className="mt-3">

                    <Link to={`/dashboard/all-company-earning/${el.id}`} className="btn btn-gradient-success btn-sm mx-2 my-1" >
                      View Earning
                    </Link>
                    <Link to={"/dashboard/edit-company/" + el.id} className="btn btn-gradient-primary btn-sm mx-2 my-1">
                      Edit
                    </Link>
                    <button className="btn btn-gradient-danger btn-sm mx-2 my-1" onClick={() => handleShowModel(el.id)}>
                      Delete
                    </button>

                  </div>
                </div>
              </div>
            )
          })
        }</>
      }

      <div className="my-3">
        <PaginationComponent
          currentPage={currentPage}
          totalPages={companyData.total_pages}
          onPageChange={handlePageChange}
        />
      </div>
    </>
  );
};

export default CompanyCards;
