import React from "react";
import SidebarComp from "../../components/sidebar/sidebar";
import NavbarComp from "../../components/navbar/navbar";
import AddEmployeeMainComp from "../../components/body/employee/addemployeemaincomp/addemployeemaincomp";

const AddEmployeeScreen = () => {
    return (
        <div className="container-scroller">
            <NavbarComp />
            <div className="container-fluid page-body-wrapper">
                <SidebarComp />
                <div className="main-panel">
                    <div className="content-wrapper">
                        <AddEmployeeMainComp />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddEmployeeScreen;