import React from 'react'
import OnGoingRides from '../../../cards/rides/onGoingRides'
import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import AllDataCommonComp from '../common/common'

const DashboardOngoingRides = () => {
  return (
    <>

      <AllDataCommonComp
        allemployee="Ongoing Rides"
        headerIcon={faCalendar}
      ></AllDataCommonComp>
      <OnGoingRides />
    </>
  )
}

export default DashboardOngoingRides