import React from "react";
import SidebarComp from "../sidebar/sidebar";
import NavbarComp from "../navbar/navbar";

import ProfileContainer from "./ProfileContainer";

const Profile = () => {
  return (
    <div className="container-scroller">
      <NavbarComp />
      <div className="container-fluid page-body-wrapper">
        <SidebarComp />
        <div className="main-panel">
          <div className="content-wrapper">
            <ProfileContainer />
          </div>
        </div>
      </div>
    </div>

  );
};

export default Profile;
