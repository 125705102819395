import React, { useState, useEffect } from "react";
import axios from "axios";
import { selectUserType } from "../../redux/slicer";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import CurrentChanges from "./currentchanges/currentchanges";
import PreviousData from "./previousdata/previousdata";
import CircularProgress from "@mui/material/CircularProgress";
import { baseSocketURL } from "../../../config";
import { toast } from "react-toastify";
import { faFileCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ReportDetailsCards = () => {
  // const userType = useSelector(selectUserType)
  const userType = localStorage.getItem("A_usertype");
  const userId = localStorage.getItem("A_userid");

  const [reportData, setReportData] = useState(null);
  const [carOrEmployeeData, setCarOrEmployeeData] = useState(null);
  const [isReportData, setIsReportData] = useState(false);
  const nav = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { reportId } = useParams();
  const [deletedDocumentsId, setDeletedDocumentsId] = useState("");

  const handleGetReportData = async () => {
    setIsLoading(true);
    try {
      const accessToken = localStorage.getItem("Key");
      if (userType === "ADMIN_USER") {
        const url =
          `${process.env.REACT_APP_BASE_URL}retrive-report/` + reportId;
        const resp = await axios
          .get(url, {
            headers: {
              Authorization: "Bearer " + accessToken,
            },
          })
          .then((response) => {
            if (response.status === 200) {
              setIsReportData(true);
            }
            return response.data.data;
          });
        console.log(resp, 'sdfsdfsf');

        let deletedDocumentsIdString = "";
        if (resp.kwargs.deleted_documents) {
          resp.kwargs.deleted_documents.map((el, i) => {
            if (deletedDocumentsIdString === "") deletedDocumentsIdString = `${el.id}`;
            else deletedDocumentsIdString = deletedDocumentsIdString + "," + el.id;
          });
        }
        setDeletedDocumentsId(deletedDocumentsIdString)
        setReportData(resp);
      }
    } catch (error) {
      console.log(error);
      console.log(error.response.status);
      setReportData(null);
    }
    setIsLoading(false);
  };
  const handleGetCarOrEmployeeData = async () => {
    setIsLoading(true);
    try {
      const accessToken = localStorage.getItem("Key");
      const baseUrl = process.env.REACT_APP_BASE_URL;
      const url =
        reportData.created_for.type === "car"
          ? "retrive-delete-car/" + reportData.kwargs.car_id
          : "retrive-delete-employee-account/" + reportData.kwargs.emp_id;
      console.log(baseUrl + url);
      if (url !== null) {
        const resp = await axios
          .get(baseUrl + url, {
            headers: {
              Authorization: "Bearer " + accessToken,
            },
          })
          .then((response) => {
            return response.data;
          });
        console.log(resp);
        setCarOrEmployeeData(resp);
      }
    } catch (error) {
      console.log(error.response.status);
      if (error.response.status) {
        setCarOrEmployeeData(null);
      }
    }
    setIsLoading(false);
  };


  useEffect(() => {
    const socket = new WebSocket(`${baseSocketURL}/ws/entity_listener/${userId}`);
    socket.addEventListener('message', (event) => {
      const { message } = JSON.parse(event.data);
      if (message.type === "send_report_message") {
        handleGetReportData()
        handleGetCarOrEmployeeData()
        toast.info(message.info)
      }
    });
    return () => {
      socket.close();
    };
  }, []);

  useEffect(() => {
    handleGetReportData();
  }, []);
  useEffect(() => {
    if (isReportData) {
      handleGetCarOrEmployeeData();
    }
  }, [isReportData]);
  return (
    <>
      <div class="d-sm-flex align-items-center justify-content-between mb-3">
        <h3 class="page-title">
          <span class="page-title-icon bg-gradient-primary text-white me-2">
            <i><FontAwesomeIcon icon={faFileCircleCheck} /></i>
          </span> Report Details
        </h3>
      </div>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "5rem 1rem",
          }}
        >
          <CircularProgress />
        </div>
      ) : (

        <>
          <div className="row">
            <div className="col-md-6">
              <div className="card mb-3 shadow">
                <div className="card-body">
                  <h4 className="mb-3">Requested Data</h4>
                  <CurrentChanges
                    reportData={reportData}
                    deletedDocumentsId={deletedDocumentsId}
                    reportId={reportId}
                  ></CurrentChanges>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card mb-3 shadow">
                <div className="card-body">
                  <h4 className="mb-3">Previous Data</h4>
                  <PreviousData
                    reportData={reportData}
                    carOrEmployeeData={carOrEmployeeData}
                  ></PreviousData>
                </div>
              </div>
            </div>
          </div>
        </>

      )}
    </>
  );
};

export default ReportDetailsCards;
