import React from "react";
import SidebarComp from "../../../components/sidebar/sidebar";
import NavbarComp from "../../../components/navbar/navbar";
import AllCarMainComp from "../../../components/body/alldatacomponent/car/allcarsmaincomp";
const MyCarScreen = () => {
    return (
        <div className="container-scroller">
            <NavbarComp />
            <div className="container-fluid page-body-wrapper">
                <SidebarComp />
                <div className="main-panel">
                    <div className="content-wrapper">
                        <AllCarMainComp />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyCarScreen