import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { selectUserType, selectCompanyInfo } from "../redux/slicer";
import Select from "react-select";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { AiOutlineLink } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";

const EditEmployeeForm = () => {
  const [companyId, setCompanyId] = useState(null);
  const [employeeName, setEmployeeName] = useState("");
  const [employeeEmail, setEmployeeEmail] = useState("");
  const [employeePhone, setEmployeePhone] = useState(null);
  const [userId, setUserId] = useState(null);
  const [messege, setMessege] = useState("");
  const [companyData, setCompanyData] = useState([]);
  const [isCompanyData, setIsCompanyData] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [companyLabel, setCompanyLabel] = useState([]);
  const [employeeAddress, setEmployeeAddress] = useState("");
  const [employeeDocumentsFiles, setEmployeeDocumentsFiles] = useState([]);
  const [addedDocumentsUrl, setAddedDocumentsUrl] = useState([]);
  const [deletedDocumentsId, setDeletedDocumentsId] = useState("");
  const [deletedDocumentsDataList, setdeletedDocumentsDataList] = useState([]);

  const nav = useNavigate();
  // const userType = useSelector(selectUserType);
  const userType = localStorage.getItem("A_usertype");
  const ci = localStorage.getItem("A_companyinfo");
  let ccid = null;
  let empid = null;
  if (ci !== "undefined" && ci !== "null") {
    ccid = JSON.parse(ci.replace(/\\/g, "")).company_id;
    empid = JSON.parse(ci.replace(/\\/g, "")).employee_id;
  }
  console.log("cid", ccid);
  const handleFileInput = (e) => {
    const selectedFiles = e.target.files;
    setEmployeeDocumentsFiles(selectedFiles);
  };

  const companyObj = useSelector(selectCompanyInfo);
  const { employeeId } = useParams();
  const accessToken = localStorage.getItem("Key");

  // Get car instance data
  const handleGetInstanceData = () => {
    setIsloading(true);
    try {
      const resp = axios
        .get(
          `${process.env.REACT_APP_BASE_URL}update-driver-account/` + employeeId,
          {
            headers: {
              Authorization: "Bearer " + accessToken,
            },
          }
        )
        .then((response) => {
          console.log("response", response);
          setCompanyId(response.data.company_id);
          setEmployeeName(response.data.employee_name);
          setEmployeeEmail(response.data.employee_email);
          setEmployeePhone(response.data.employee_phone);
          setEmployeeAddress(response.data.employee_address);
          setAddedDocumentsUrl(response.data.added_document_urls);
          setUserId(response.data.user_id);
          setIsloading(false);
          return response.data;
        });
    } catch (error) {
      setIsloading(false);
      toast("Somthing went wrong not able to fetch user data");
      console.log(error);
    }
  };
  console.log(
    companyId,
    employeeAddress,
    employeeEmail,
    employeeName,
    employeePhone
  );

  const handleUpdateLicenceNumber = async (e) => {
    e.preventDefault();
    setMessege("");
    if (userType === "ADMIN_USER") {
      if (companyId === null) {
        setMessege("Company Must not be Blank");
        return;
      }
    }
    if (employeeName.length < 4) {
      setMessege("Employee name must be 4 characters long");
      return;
    }
    if (employeePhone === "") {
      if (employeePhone.length < 13 || !employeePhone.startsWith("+")) {
        setMessege("Phone number is not valid or must start with country code");
        return;
      }
    }
    if (employeeAddress === "") {
      setMessege("Address Must not be Blank");
      return;
    }
    setIsloading(true);
    try {
      let data = new FormData();
      data.append("user_id", userId);
      data.append("employee_name", employeeName);
      data.append("employee_email", employeeEmail);
      data.append("employee_phone", employeePhone);
      data.append("employee_type", "Manager");
      data.append("employee_address", employeeAddress);
      data.append("driver_licence_number", null);
      if (employeeDocumentsFiles.length !== 0) {
        for (let i = 0; i < employeeDocumentsFiles.length; i++) {
          data.append("added_documents", employeeDocumentsFiles[i]);
        }
      }
      if (deletedDocumentsId !== "") {
        data.append("deleted_documents", deletedDocumentsId);
      }
      // console.log(licenceData, "data to edit");
      if (userType == "ADMIN_USER") {
        data.append("company_id", parseInt(companyId));
        const url =
          `${process.env.REACT_APP_BASE_URL}` +
          "update-driver-account" +
          "/" +
          employeeId;
        const resp = await axios
          .put(url, data, {
            headers: {
              Authorization: "Bearer " + accessToken,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            return response.data.data;
          });
        toast("Successfully updated");
        setIsloading(false);

      } else {
        data.append("deleted_documents", JSON.stringify(deletedDocumentsDataList))
        data.append("type", "Manager");
        data.append("send_type", "edit");
        data.append("emp_id", parseInt(employeeId));
        data.append("company_id", ccid);
        data.append("created_by", empid);
        const url = `${process.env.REACT_APP_BASE_URL}create-report-firebase/`;
        const resp = await axios
          .post(url, data, {
            headers: {
              Authorization: "Bearer " + accessToken,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            toast("Employee updated request is send to admin");
            return response.data;
          });
      }
      // console.log(resp);
    } catch (error) {
      console.log(error);
      setIsloading(false);
      toast("Your Session is Expired, Please Login Again");
    }
  };

  const handleGetCompanyData = async () => {
    try {
      if (userType === "ADMIN_USER") {
        const resp = await axios
          .get(`${process.env.REACT_APP_BASE_URL}all-company/`, {
            headers: {
              Authorization: "Bearer " + accessToken,
            },
          })
          .then((response) => {
            setCompanyData(response.data);
            setIsCompanyData(true);
            return response.data;
          });
      }
    } catch (error) {
      console.log(error);
      setMessege("Somthing went wrong not able to fetch user data");
      setIsCompanyData(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (userType === "ADMIN_USER") {
      handleGetCompanyData();
    }
    handleGetInstanceData();
  }, []);

  useEffect(() => {
    if (companyData.length > 0) {
      const company_list = companyData.map((el, i) => {
        return {
          label: el.name,
          id: el.id,
          name: el.name,
        };
      });
      setCompanyLabel(company_list);
    }
  }, [isCompanyData]);

  const handleDeleteDocument = (id) => {
    let updatedId;
    if (deletedDocumentsId === "") updatedId = id;
    else updatedId = deletedDocumentsId + "," + id;
    setDeletedDocumentsId(`${updatedId}`);
    let filterdata = addedDocumentsUrl.filter((item) => item.id !== id);
    setAddedDocumentsUrl(filterdata);
    if (userType === "COMPANY_USER") {
      let removedObject = addedDocumentsUrl.filter((item) => item.id === id);
      let data = deletedDocumentsDataList
      data.push(removedObject[0])
      setdeletedDocumentsDataList(data)
    }
  };


  return (
    <div class="card-body">
      {isLoading ? (
        <div
          className="mt-3"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 20,
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <form className="user" onSubmit={(e) => handleUpdateLicenceNumber(e)}>
          <p style={{ color: "red", fontSize: 14, fontFamily: "serif" }}>
            {messege}
          </p>
          {userType === "ADMIN_USER" ? (
            <>
              <div className="form-group">
                {isCompanyData ? (
                  <Select
                    required
                    options={
                      companyLabel.length > 0
                        ? companyLabel
                        : [{ id: "n/a", name: "n/a", label: "n/a" }]
                    }
                    onChange={(e) => setCompanyId(e.id)}
                    placeholder="Select Company"
                    defaultValue={companyLabel.find(
                      (option) => option?.id === companyId
                    )}
                  ></Select>
                ) : null}
              </div>
            </>
          ) : (
            <div></div>
          )}

          <div className="row">
            <div className="form-group col-md-6">
              <input
                className="form-control"
                type="text"
                required
                maxLength={50}
                onChange={(e) => setEmployeeName(e.target.value)}
                style={{ width: "100%", position: "relative" }}
                placeholder="Enter Employee Name"
                defaultValue={employeeName}
              />
            </div>
            <div className="form-group col-md-6">
              <input
                className="form-control"
                type="email"
                required
                maxLength={50}
                onChange={(e) => setEmployeeEmail(e.target.value)}
                style={{ width: "100%", position: "relative" }}
                placeholder="Enter Employee Email"
                defaultValue={employeeEmail}
              />
            </div>
          </div>

          <div className="form-group">
            <textarea
              className="form-control"
              required
              maxLength={100}
              onChange={(e) => setEmployeeAddress(e.target.value)}
              style={{ width: "100%", position: "relative" }}
              placeholder="Enter Residential Address"
              defaultValue={employeeAddress}
            ></textarea>
          </div>

          <div className="row">
            <div className="form-group col-md-6">
              <PhoneInput
                className="form-control"
                defaultCountry="SE"
                value={employeePhone}
                onChange={setEmployeePhone}
                limitMaxLength={true}
                placeholder={"Enter Employee Number"}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <input
                  type="file"
                  className="form-control"
                  onChange={handleFileInput}
                  multiple
                />
                {addedDocumentsUrl.length !== 0 ? (
                  <div className="row mt-2">
                    {addedDocumentsUrl.map((elem, i) => {
                      return (
                        <div className="col-12 mb-1" key={i}>
                          <a
                            href={elem.document_url}
                            className="fa fa-download"
                            target="_blank"
                            style={{ cursor: "pointer" }}
                          >
                            <AiOutlineLink /> {elem.filename}
                          </a>{" "}
                          <RxCross2
                            onClick={() => handleDeleteDocument(elem.id)}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      );
                    })}
                  </div>
                ) : null}
              </div>
            </div>

          </div>

          <button
            type="submit"
            className="btn btn-primary btn-user my-3 btn-block"
          >
            UPDATE PROFILE
          </button>
        </form>
      )}
    </div>
  );
};

export default EditEmployeeForm;
