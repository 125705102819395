import React from "react";
import EditCompanyMidComp from "./editcompanymidcompo";

const EditCompanyMainComp=()=>{
    return(
        <div class="container-fluid">
            <EditCompanyMidComp></EditCompanyMidComp>
        </div>
    )
}

export default EditCompanyMainComp;