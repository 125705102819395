import React from "react";
import SidebarComp from "../../components/sidebar/sidebar";
import NavbarComp from "../../components/navbar/navbar";
import AddCompanyMainComp from "../../components/body/company/addcompanymaincomp/addcompanymaincomp";

const AddCompanyScreen=()=>{
    return(
        <div className="container-scroller">
            <NavbarComp />
            <div className="container-fluid page-body-wrapper">
                <SidebarComp />
                <div className="main-panel">
                    <div className="content-wrapper">
                        <AddCompanyMainComp />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddCompanyScreen;