import React, { useState, useEffect } from "react";
import axios from "axios";
import { selectUserType } from "../../redux/slicer";
import { useSelector } from "react-redux";
import { AiFillDelete } from "react-icons/ai";
import CircularProgress from "@mui/material/CircularProgress";
import PaginationComponent from "../PaginationComponent";
import { TbArrowsSort } from "react-icons/tb";
import { toast } from "react-toastify";
import stockholmDate from "../dateformatConvert";
import { useNavigate } from "react-router-dom";
import FilterForm from "../../filter/FilterForm";
import { formatStops, queryParams } from "../../../shared/utils";
import MapInfoModal from "../../map/MapInfoModal";
import './Ride.css'


const OnGoingRides = () => {
  const [rideseData, setRidesData] = useState({ results: [] });
  const [IsLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [scurrentPage, setsCurrentPage] = useState(1);
  const [status, setStatus] = useState("online");
  const [cancleRide, setCancelRide] = useState(false);
  const [orderBy, setOrderBy] = useState("-ride_date");
  const [isFilterLoading, setIsFilterLoading] = useState(false);
  const [mapOpen, setMapOpen] = useState(false)
  const [rideInfo, setMapRideInfo] = useState({
    origin: null,
    destination: null,
    via: null
  })
  const showFilter = useSelector((state) => state.showFilter.showFilter);

  const accessToken = localStorage.getItem("Key");
  const usertype = localStorage.getItem("A_usertype");


  const nav = useNavigate();
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handlSPageChange = (pageNumber) => {
    setsCurrentPage(pageNumber);
  };

  const handleGetRidesData = async () => {
    try {
      setIsLoading(true);
      const accessToken = localStorage.getItem("Key");
      const url = usertype === "ADMIN_USER"?`${process.env.REACT_APP_BASE_URL}all-rides/?paginate=true&page=${currentPage}&status=${status}&order_by=${orderBy}&ride=ongoing`
      :`${process.env.REACT_APP_BASE_URL}my-rides/?paginate=true&page=${currentPage}&status=${status}&order_by=${orderBy}&ride=ongoing`;
      const resp = await axios
        .get(
          url,
          {
            headers: {
              Authorization: "Bearer " + accessToken,
            },
          }
        )
        .then((response) => {
          let results = response.data;
          setRidesData(results);
          return response.data;
        });

      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const handleOnlineRides = () => {
    setStatus("online");
  };
  const handleScheduleRides = () => {
    setStatus("schedule");
  };

  useEffect(() => {
    handleGetRidesData();
  }, [currentPage, status, cancleRide, orderBy, scurrentPage]);

  const handleSortRideDate = () => {
    if (orderBy === "-ride_date") {
      setOrderBy("ride_date");
    } else {
      setOrderBy("-ride_date");
    }
  };
  const handleSortBookDate = () => {
    if (orderBy === "-created_date") {
      setOrderBy("created_date");
    } else {
      setOrderBy("-created_date");
    }
  };

  const ridesFields = [
    { name: "ride_id", label: "Order Id", type: "number" },
    { name: "name", label: "Name" },
    { name: "mobile_number", label: "Mobile Number", type: "number" },
    { name: "start_date", label: "Start Date", type: "date" },
    { name: "end_date", label: "End Date", type: "date" },
    { name: "pickuplocation", label: "Pickup Location" },
    { name: "droplocation", label: "Drop Location" },
    { name: "car_type", label: "Car Type" },
    { name: "booking_date", label: "Booking Date", type: "date" },
  ];

  const handleRidesFilter = (data) => {
    const params = queryParams(data);
    setIsFilterLoading(true);
    axios
      .get(`${process.env.REACT_APP_BASE_URL}filter-all-rides/?${params}&status=${status}&ride=ongoing&paginate=true&page=${currentPage}`, {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      })
      .then((res) => {
        res.data.msg ? toast.error(res.data.msg) : setRidesData(res.data);
        setIsFilterLoading(false);
      })
      .catch((err) => {
        setIsFilterLoading(false);
        toast.error(err);
      });
  };

  const handleClearFilters = () => handleGetRidesData();
  const handleMapOpen = (ride) => {
    setMapOpen(true)
    setMapRideInfo({ origin: ride.picup_location_name, destination: ride.drop_location_name, via: formatStops(ride.stops) })
  }

  return (
    <>

      {showFilter && <FilterForm
        fields={ridesFields}
        onFilter={handleRidesFilter}
        onClearFilters={handleClearFilters}
        isLoading={isFilterLoading}
      />}
       <MapInfoModal isOpen={mapOpen} onClose={() => setMapOpen(false)} title="Map" origin={rideInfo.origin} destination={rideInfo.destination} via={rideInfo.via} />



      <div className="d-flex justify-content-start mb-3 rides-btn">
        <button className={`${status === "online" ? 'btn btn-gradient-primary me-3' : 'btn btn-gradient-success me-3'}`} disabled={status === "online" ? true : false} onClick={handleOnlineRides}>
          Online Rides
        </button>
        <button className={`${status === "schedule" ? 'btn btn-gradient-primary me-3' : 'btn btn-gradient-success me-3'}`} disabled={status === "schedule" ? true : false} onClick={handleScheduleRides}>
          Schedule Rides
        </button>
      </div>



      {
        IsLoading ?
          <div className="d-flex justify-content-center align-items-center my-5 py-5">
            <CircularProgress />
          </div>
          :

          <>{
            rideseData.results.map((ride, i) => {
              return (
                <div className="card shadow mb-3 grid-margin stretch-card" key={i}>
                  <div className="card-body">
                    <h5 className="mb-3"><strong>Ride {ride.id}</strong></h5>
                    <div className="row">
                      <div className="col-md-4">

                        <p><strong>From</strong><br /> <span className="text-dark">{ride.picup_location_name}</span></p>
                        <p><strong>To</strong><br />  <span className="text-dark">{ride.drop_location_name}</span></p>
                        <p><strong>Stops</strong><br /> <span className="text-dark">{ride.stops &&
                          ride.stops.length !== 0 ? (
                          <span>{formatStops(ride.stops)}</span>
                        ) : (
                          <span>No Stop</span>
                        )}</span></p>
                        <p><strong>Booking Date & Time</strong><br />  <span className="text-dark">{stockholmDate(ride.ride_date)}</span></p>
                        <p><strong>Ride Date & Time</strong><br /> <span className="text-dark">{stockholmDate(ride.created_date)}</span></p>
                      </div>
                      <div className="col-md-4">
                        <p><strong>Rider Name</strong><br />  <span className="text-dark">{ride.ridername}</span></p>
                        <p><strong>Rider Phone</strong><br /> <span className="text-dark">{ride.riderphone}</span></p>
                        <p><strong>Fare</strong><br /> <span className="text-dark">{ride.fare} S.E.K</span></p>
                        <p><strong>Distance</strong><br />  <span className="text-dark">{ride.distance} KM</span></p>
                        <p><strong>Company Name</strong><br />  <span className="text-dark">{ride.company_name
                          ? ride.company_name
                          : "Company Not Assigned"}</span></p>

                      </div>
                      <div className="col-md-4">
                        <p><strong>Car Type</strong><br />  <span className="text-dark">{ride.car_type}</span></p>
                        <p><strong>Car Model</strong><br /> <span className="text-dark">{ride.car_model ? ride.car_model : "Car Not Assigned"}</span></p>
                        <p><strong>Car Registration Number</strong><br /> <span className="text-dark">{ride.car_registration_number
                          ? ride.car_registration_number
                          : "Car Not Assigned"}</span></p>
                        <p><strong>Driver Name</strong><br /> <span className="text-dark">{ride.driver_name
                          ? ride.driver_name
                          : "Driver Not Assigned"}</span></p>
                        <p><strong>Driver Number</strong><br /> <span className="text-dark">  {ride.driver_phone
                          ? ride.driver_phone
                          : "Driver Not Assigned"}</span></p>
                      </div>
                    </div>
                    <div className="mt-3">




                      <button
                        className="btn btn-gradient-info btn-sm mx-2 my-1"
                        onClick={() => handleMapOpen(ride)}
                      >
                        Route
                      </button>

                      <span
                        className="btn btn-gradient-primary btn-sm mx-2 my-1"
                        style={{ cursor: "none" }}
                      >
                        {ride.status}
                      </span>



                    </div>
                  </div>
                </div>
              )
            })
          }</>
      }

      <div className="my-3">
        {status === "online" && (
          <PaginationComponent
            currentPage={currentPage}
            totalPages={rideseData.total_pages}
            onPageChange={handlePageChange}
          />
        )}
        {status === "schedule" && (

          <PaginationComponent
            currentPage={scurrentPage}
            totalPages={rideseData.total_pages}
            onPageChange={handlSPageChange}
          />

        )}
      </div>

    </>
  );
};

export default OnGoingRides;
