import React, { useEffect, useState } from "react";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";
import { AiFillEdit, AiFillDelete, AiOutlineLink } from "react-icons/ai";
import { BsFillTelephoneForwardFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { selectUserType } from "../../redux/slicer";
import PaginationComponent from "../PaginationComponent"; // Replace "path/to" with the actual path
import FilterForm from "../../filter/FilterForm";
import { queryParams } from "../../../shared/utils";
import DeleteConfirmationModel from "../deleteConfirmationModel";
import AssignedCar from "./assignedCar";
import { Button } from "@mui/material";


const DriverCard = () => {
  const userType = localStorage.getItem("A_usertype");
  const nav = useNavigate();
  const accessToken = localStorage.getItem("Key");
  const [driverData, setDriverData] = useState({ results: [] });
  const [licenceNumber, setLicenceNumber] = useState(null);
  const [update, setUpdate] = useState(false);
  const [showAssignCars, setShowAssignCars] = useState(null);
  const showFilter = useSelector((state) => state.showFilter.showFilter);
  const [isLoading, setIsLoading] = useState(true); // Add loading state
  const [currentPage, setCurrentPage] = useState(1);
  const [isFilterLoading, setIsFilterLoading] = useState(false);
  const [showModel, setShowModel] = useState({
    "show": false,
    "id": null
  });

  const handleShowModel = (id) => {
    setShowModel({
      "show": true,
      "id": id
    })
  }
  const handleCloseModel = () => {
    setShowModel({
      "show": false,
      "id": null
    })
  }


  const handleGetDriverData = async () => {
    const endpoint = userType === "ADMIN_USER" ? "all-driver" : "my-drivers";
    try {
      const resp = await axios
        .get(
          `${process.env.REACT_APP_BASE_URL}${endpoint}/?paginate=true&page=${currentPage}`,
          {
            headers: {
              Authorization: "Bearer " + accessToken,
            },
          }
        )
        .then((response) => {
          return response;
        });
      let results = resp.data;
      setDriverData(results);
      console.log(results);
      setIsLoading(false); // Update loading state
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteDriver = async (id) => {
    setIsLoading(true)
    handleCloseModel()
    try {
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}retrive-or-delete-driver/${id}`,
        {},
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      setIsLoading(false)
      setUpdate(!update);
    } catch (error) {
      setIsLoading(false)
      console.log(error);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    handleGetDriverData();
  }, [update, currentPage]);

  const ridesFields = [
    { name: 'name', label: 'Name' },
    { name: 'mobile_number', label: 'Mobile Number', type: 'number' },
    { name: 'email', label: 'Email', type: 'email' },
    { name: 'licence_number', label: 'Licence Number' },
  ];

  const handleRidesFilter = (data) => {
    const params = queryParams(data)
    setIsFilterLoading(true)
    axios.get(`${process.env.REACT_APP_BASE_URL}filter-all-driver/?${params}&paginate=true`, {
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    }).then((res) => {
      res.data.msg ? toast.error(res.data.msg) : setDriverData(res.data)
      setIsFilterLoading(false)
    }).catch((err) => {
      setIsFilterLoading(false)
      toast.error(err);
    })
  };

  const handleClearFilters = () => handleGetDriverData();
  const handleShowAssignCars = (index) => {
    setShowAssignCars(index === showAssignCars ? null : index);
  };

  return (
    <>
      <DeleteConfirmationModel showModel={showModel} onHandleDelete={handleDeleteDriver} handleCloseModel={handleCloseModel}></DeleteConfirmationModel>
      {showFilter && <FilterForm
        fields={ridesFields}
        onFilter={handleRidesFilter}
        onClearFilters={handleClearFilters}
        isLoading={isFilterLoading}
      />}

      {isLoading ? (
        <div
          className="my-5 py-5"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          {
            driverData.results.map((el, i) => {
              return (
                <div className="card mb-3 shadow">
                  <div className="card-body">

                    <h6><span className="text-dark font-weight-bold mr-1">{String(el.name).toLocaleUpperCase()} </span> ({el.company_name}) <span title={el.status}>{el.status === "online" ? "🟢" : el.status === "offline" ? "🔴" : "🔵"}</span></h6>
                    <div className="row">
                      <div className="col-md-4">
                        <p className="text-dark mb-1">License Number: &nbsp;{el.license_number}</p>
                        <p className="text-dark mb-0"><BsFillTelephoneForwardFill className="mr-2" />{userType !== "ADMIN_USER" ?
                          <>{el.user_phone}</>
                          :
                          <>{el.registered_phone_number}</>
                        }</p>
                      </div>
                      <div className="col-md-4">
                        {el.added_documents_url.length !== 0 ? (
                          <div className="row">
                            {el.added_documents_url.map((elem, i) => {
                              const extension = elem.filename.split('.').pop();
                              const newFilename = `${el.name.replace(/\s+/g, '_').toLowerCase()}_${i + 1}.${extension}`;
                              return (
                                <div className="col-12">
                                  <a
                                    href={elem.document_url}
                                    target="_blank"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <AiOutlineLink /> {newFilename}
                                  </a>
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          "No Documents"
                        )}
                      </div>
                      <div className="col-md-4 d-flex justify-content-end">
                        <div>
                          <span
                            className="ms-3"
                            style={{ cursor: "pointer" }}
                            onClick={() => nav("/dashboard/edit-driver/" + el.id)}
                          >
                            <AiFillEdit className="text-gray" />
                          </span>

                          {userType === "ADMIN_USER" ? <span
                            className="ms-3"
                            style={{
                              alignContent: "center",
                              width: "100%",
                              height: "100%",
                              position: "relative",
                              cursor: "pointer",
                            }}
                          >
                            <AiFillDelete
                              className="text-gray"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleShowModel(el.id)}
                            />
                          </span> : null}
                          <button
                            className="ms-3 btn-sm btn btn-gradient-success"
                            onClick={() =>
                              nav(`/dashboard/all-driver-earning/${el.id}/${el.company_id}`)
                            }
                          >
                            Earning
                          </button>

                          <button
                            className={`ms-3 btn-gradient-${showAssignCars === el.id ? "danger" : "primary"} btn btn-sm`}
                            onClick={() => handleShowAssignCars(el.id)}
                          >
                            {showAssignCars === el.id ? "Close" : "View Cars"}
                          </button>
                        </div>
                      </div>

                    </div>

                    {showAssignCars === el.id &&
                      (<AssignedCar driverId={el.id} driverName={el.name} />)
                    }
                  </div>
                </div>

              )
            })
          }</>
      )}

      <div className="my-3">
        <PaginationComponent
          currentPage={currentPage}
          totalPages={driverData.total_pages}
          onPageChange={handlePageChange}
        />
      </div>

    </>
  );
};

export default DriverCard;