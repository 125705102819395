import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getStorage} from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBhnyPvr7GnOpyI5gx6k2uQYRMDK4hMqr0",
  authDomain: "trixitaxi-2cd46.firebaseapp.com",
  projectId: "trixitaxi-2cd46",
  storageBucket: "trixitaxi-2cd46.appspot.com",
  messagingSenderId: "956430500403",
  appId: "1:956430500403:android:5996634e36dd09848e9946",
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const storage = getStorage(app);