import React from 'react'
import SidebarComp from "../../../components/sidebar/sidebar";
import NavbarComp from "../../../components/navbar/navbar";
import AllRidesMainComp from '../../../components/body/alldatacomponent/rides/allridesmaincomp';
const MyRides = () => {
  return (
    <div className="container-scroller">
      <NavbarComp />
      <div className="container-fluid page-body-wrapper">
        <SidebarComp />
        <div className="main-panel">
          <div className="content-wrapper">
            <AllRidesMainComp />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MyRides