import React from "react";
import EditCarForm from "../../../forms/editcarform";

const EditCarMidComp = () => {
  return (
    <EditCarForm></EditCarForm>
  );
};

export default EditCarMidComp;
