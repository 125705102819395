import React,{useEffect} from "react";
import SidebarComp from "../../components/sidebar/sidebar";
import NavbarComp from "../../components/navbar/navbar";
import DriverRidesMainComp from "../../components/body/alldatacomponent/rides/driverridemaincomp";

const DriverRides=()=>{
   
    return(
        <div className="container-scroller">
        <NavbarComp />
        <div className="container-fluid page-body-wrapper">
            <SidebarComp />
            <div className="main-panel">
                <div className="content-wrapper">
                    <DriverRidesMainComp />
                </div>
            </div>
        </div>
    </div>
    )
}

export default DriverRides;