import Select from "react-select";
import React, { useState, useEffect } from 'react'
import { BsFillTaxiFrontFill, BsPlusCircle } from "react-icons/bs";
import axios from "axios";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import CustomConfirmationModel from "../customConfirmation";

const AssignedCar = ({ driverId, driverName }) => {
    const [driverCarData, setdriverCarData] = useState([]);
    const [showAddAssignCar, setShowAddAssignCar] = useState(false);
    const [isAssigendCar, setisAssigendCar] = useState(false);
    const [carData, setcarData] = useState([]);
    const [carDetails, setCarDetails] = useState([]);
    const [selectedCar, setselectedCar] = useState(null);
    const [isLoading, setisLoading] = useState(false);
    const accessToken = localStorage.getItem("Key");
    const [showModel, setShowModel] = useState({
        "show": false,
        "id": null
      });
    
      const handleShowModel = (id) => {
        setShowModel({
          "show": true,
          "id": id
        })
      }
      const handleCloseModel = () => {
        setShowModel({
          "show": false,
          "id": null
        })
      }

    const handleAddAssignCar = () => {
        setShowAddAssignCar(true)
        handleSelectCar()
    }

    const handleCarUpdates = async () => {
        setisLoading(true)
        try {
            const re = await axios
                .get(
                    `${process.env.REACT_APP_BASE_URL}assign-cars-list/${driverId}?car=self`,
                    {
                        headers: {
                            Authorization: "Bearer " + accessToken,
                        },
                    }
                )
                .then((response) => {
                    setdriverCarData(response.data)
                    setisLoading(false)
                    return response;
                });

        } catch (error) {
            setisLoading(false)
            console.log(error);
        }
    }

    useEffect(() => {
        handleCarUpdates()
    }, [isAssigendCar])

    const handleAssignCarToDriver = ()=>{
        let obj = carDetails.filter((elem)=>elem.id===selectedCar)[0]
        if(obj.driver__employee_name){
            handleShowModel(selectedCar)
        }else{
            AssignDriverToCar("connect", selectedCar)
        }
    }

    const AssignDriverToCar = async (action, carId) => {
        setisLoading(true)
        handleCloseModel()
        const assignData = {
            driver_id: driverId,
            flag: action,
        };
        console.log(assignData);
        const options = {
            headers: {
                "Content-type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
        };
        try {
            // for initial data creation
            const resp = await axios
                .put(
                    `${process.env.REACT_APP_BASE_URL}driver-car-assign/${carId}`,
                    assignData,
                    options
                )
                .then((response) => {
                    action === "connect" ? toast(`Successfully Assigned Car To ${driverName}`) : toast(`Successfully Removed Car From ${driverName}`)
                    setisAssigendCar(new Date().getTime())
                    setShowAddAssignCar(false)
                    setisLoading(false)
                    return response.data;
                });
        } catch (error) {
            setisLoading(false)
            console.log(error, "erorr");
            if (error.response.status === 401) {
                toast("Session is Expired! Please Login Again");
            } else {
                toast("Somthing went wrong, please try later");
            }
        }
    };


    const handleSelectCar = async () => {
        try {
            const re = await axios
                .get(
                    `${process.env.REACT_APP_BASE_URL}assign-cars-list/${driverId}`,
                    {
                        headers: {
                            Authorization: "Bearer " + accessToken,
                        },
                    }
                )
                .then((response) => {
                    console.log(response.data)
                    const arr = [];
                    const data = response.data;
                    data.map((e) => {
                        arr.push({ value: e.id, label: `${e.car_model} (${e.registration_number})` });
                    });
                    setcarData(arr);
                    setCarDetails(data)
                    return response;
                });

        } catch (error) {
            console.log(error);
        }
    }
    return (
        
        <div className=' pb-2 mt-2'>
            <CustomConfirmationModel showModel={showModel} onHandleFunction={AssignDriverToCar}
             handleCloseModel={handleCloseModel} btnAction={"Assign Car"} 
             message={"This car is currently assigned to a driver. If you proceed with the assignment, it will be unassigned from that driver. Are you sure you want to continue?"}>
             </CustomConfirmationModel>
            <div className="d-flex justify-content-between p-3">
                <div className="d-flex align-items-center justify-content-center">Assigned Cars</div>
                {showAddAssignCar ?
                    <div className="d-flex  w-50 justify-content-end">
                        <div className="form-group mx-3 w-50 mb-0">
                            <Select
                                options={carData}
                                onChange={(e) => setselectedCar(e.value)}
                                placeholder="Select Car "
                            ></Select>
                        </div>
                        <div className="ml-3 mt-1 align-items-center justify-content-center">
                            <button onClick={() => handleAssignCarToDriver()} className="btn btn-secondary btn-sm">Assign Car</button>
                        </div>
                    </div>
                    :
                    <div><BsPlusCircle onClick={() => handleAddAssignCar()} className='mr-3' style={{ fontSize: "1.5rem", cursor: "pointer" }} /></div>
                }

            </div>
            {isLoading ? (
                <div
                    className="my-5"
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: 20,
                    }}
                >
                    <CircularProgress />
                </div>
            ) :
                <> {
                    driverCarData.map((element, i) => {
                        return (
                            <div className="pt-2 mx-3 border-top" key={i}>
                                <div className='d-flex justify-content-between'>
                                    <div>
                                        <h6 className="mb-1"><span className="text-dark font-weight-normal mr-1"><BsFillTaxiFrontFill /> {element.car_model} </span> ({element.registration_number})</h6>
                                        <div className="mb-2">
                                            {element.car_type.map((e, i) => {
                                                return (
                                                    <span>
                                                        {e}
                                                        {i !== element.car_type.length - 1 && ", "}
                                                    </span>
                                                );
                                            })}
                                        </div>
                                    </div>
                                    <div className='py-2'>
                                        <button onClick={() => AssignDriverToCar("disconnect", element.id)} className="btn btn-sm btn-danger">Disconnect</button>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }</>}
        </div>
    )
}

export default AssignedCar