import React from "react";
import DasboarTopComp from "../../main/dashboardtop/dashboardtop";
import DashboardTopMidComp from "../../main/dashboardtopmid/dashboardtopmid";


const AllDataCommonComp = (props) => {
    return (
        <DasboarTopComp headerDash={props.allemployee.toUpperCase()} addUrl={props.addUrl} headerIcon={props.headerIcon} downloadCall={props.downloadCall} selectedIds={props.selectedIds} />
    )
}

export default AllDataCommonComp;