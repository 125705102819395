import React from 'react'
import { useParams } from 'react-router-dom'
import NavbarComp from '../navbar/navbar';
import SidebarComp from '../sidebar/sidebar';
import UserProfileContainer from './userProfileContainer';


const UserProfile = () => {
    const {userId} = useParams();
    return (
        <div className="container-scroller">
        <NavbarComp />
        <div className="container-fluid page-body-wrapper">
          <SidebarComp />
          <div className="main-panel">
            <div className="content-wrapper">
              <UserProfileContainer userId={userId} />
            </div>
          </div>
        </div>
      </div>
    )
}

export default UserProfile