import { createSlice } from "@reduxjs/toolkit";


const initialState={
    authdata:{
        isLoggedIn:false,
        phone:null,
        usertype:null,
        firstname:null,
        lastname:null,
        userid:null,
        companyinfo:null
    }
}

const TaxiAppSlicer=createSlice({
    name:"taxiappdata",
    initialState:initialState,
    reducers:{
        setSignIn:(state,action)=>{
            /* eslint eqeqeq: "off", curly: "error" */
            console.log(action.payload.companyinfo, 'payload')
            state.authdata.isLoggedIn=true,
            state.authdata.phone=action.payload.phone,
            state.authdata.usertype=action.payload.usertype,
            state.authdata.firstname=action.payload.firstname,
            state.authdata.lastname=action.payload.lastname,
            state.authdata.userid=action.payload.id,
            state.authdata.companyinfo=action.payload.companyinfo
        },
        setSignOut:(state)=>{
            /* eslint eqeqeq: "off", curly: "error" */
            state.authdata.isLoggedIn=false,
            state.authdata.phone=null,
            state.authdata.usertype=null,
            state.authdata.firstname=null,
            state.authdata.lastname=null,
            state.authdata.userid=null,
            state.authdata.companyinfo=null
        }
    }
})


export const {setSignIn,setSignOut} = TaxiAppSlicer.actions;

// Authentication data
export const selectIsLoggedIn=(state)=>state.taxiappdata.authdata.isLoggedIn;
export const selectUserId=(state)=>state.taxiappdata.authdata.userid;
export const selectPhone=(state)=>state.taxiappdata.authdata.phone;
export const selectUserType=(state)=>state.taxiappdata.authdata.usertype;
export const selectFirstName=(state)=>state.taxiappdata.authdata.firstname;
export const selectLastname=(state)=>state.taxiappdata.authdata.lastname;
export const selectCompanyInfo=(state)=>state.taxiappdata.authdata.companyinfo;


export default TaxiAppSlicer.reducer;


/* eslint-disable-line */