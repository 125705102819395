import React from "react";
import SidebarComp from "../../components/sidebar/sidebar";
import NavbarComp from "../../components/navbar/navbar";
import AllUsersMainComp from "../../components/body/alldatacomponent/users/allusers";

const AllUsersScreen=()=>{
    return(
        <div className="container-scroller">
            <NavbarComp />
            <div className="container-fluid page-body-wrapper">
                <SidebarComp />
                <div className="main-panel">
                    <div className="content-wrapper">
                        <AllUsersMainComp />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AllUsersScreen;