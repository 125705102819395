import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import { ref, getDownloadURL } from "firebase/storage";
import { storage } from "../../../../firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

const CurrentChanges = (props) => {
  const [Message, setMessege] = useState();
  const [id, setid] = useState(null);
  const [IsDisabled, setIsDisabled] = useState(props.reportData?props.reportData.approval:false);
  const [isCarApprove, setisCarApprove] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [carId, setcarId] = useState(null);
  const nav = useNavigate();
  const [documentURLs, setDocumentURLs] = useState([]);

  useEffect(() => {
    if (
      props.reportData &&
      props.reportData.kwargs &&
      props.reportData.kwargs.added_documents
    ) {
      const fetchDocumentURLs = async () => {
        const urls = await Promise.all(
          props.reportData.kwargs.added_documents.map(async (elem) => {
            try {
              const documentRef = ref(storage, elem);
              const url = await getDownloadURL(documentRef);
              return url;
            } catch (error) {
              console.error("Error getting document URL:", error);
              return null;
            }
          })
        );
        setDocumentURLs(urls);
      };
      fetchDocumentURLs();
    }
  }, [props.reportData]);
  console.log(props.reportData);
  const handlCarAddEdit = async () => {
    setIsLoading(true);
    if (props.reportData) {
      const accessToken = localStorage.getItem("Key");
      if (props.reportData.created_for.send_type === "add") {
        try {
          let data;
          data = new FormData();
          data.append(
            "car_model",props.reportData.kwargs.car_model
          );
          data.append("car_type", props.reportData.kwargs.car_type);
          data.append(
            "registration_number",
            props.reportData.kwargs.registration_number
          );
          data.append("converted", true);
          for (
            let i = 0;
            i < props.reportData.kwargs.added_documents.length;
            i++
          ) {
            data.append(
              "added_documents",
              props.reportData.kwargs.added_documents[i]
            );
          }
          data.append("company_id", props.reportData.kwargs.company_id);
          console.log("payload data", data);
          const resp = await axios
            .post(`${process.env.REACT_APP_BASE_URL}add-car/`, data, {
              headers: {
                Authorization: "Bearer " + accessToken,
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => {
              setcarId(response.data.id);
              console.log(response, "res");
              setisCarApprove(true);
              return response.data;
            });
          console.log(resp);
        } catch (error) {
          console.log(error);
          if (error.response.status === 401) {
            setMessege("Your session is Expired, please login again");
          }
        }
      } else {
        try {
          let data;
          data = new FormData();
          data.append(
            "car_model",
            props.reportData.kwargs.car_model
          );
          data.append("car_type", props.reportData.kwargs.car_type);
          data.append(
            "registration_number",
            props.reportData.kwargs.registration_number
          );
          data.append("converted", true);
          data.append("company_id", props.reportData.kwargs.company_id);
          if (props.reportData.kwargs.added_documents.length !== 0) {
            for (
              let i = 0;
              i < props.reportData.kwargs.added_documents.length;
              i++
            ) {
              data.append(
                "added_documents",
                props.reportData.kwargs.added_documents[i]
              );
            }
          }
          if (props.deletedDocumentsId !== "") {
            data.append("deleted_documents", props.deletedDocumentsId);
          }

          console.log("payload data", data);
          const resp = await axios
            .put(
              `${process.env.REACT_APP_BASE_URL}edit-car/${props.reportData.kwargs.car_id}`,
              data,
              {
                headers: {
                  Authorization: "Bearer " + accessToken,
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then((response) => {
              setcarId(response.data.id);
              console.log(response, "res");
              setisCarApprove(true);
              return response.data;
            });
          console.log(resp);
        } catch (error) {
          console.log(error)
          if (error.response.status === 401) {
            setMessege("Your session is Expired, please login again");
          }
        }
      }
    }
    setIsLoading(false);
  };
  const handleCarApprove = async () => {
    if (carId === null || carId === undefined) {
      return;
    }
    const accessToken = localStorage.getItem("Key");
    setIsLoading(true);
    try {
      const data = {
        is_approved: true,
      };
      const resp = await axios
        .put(
          `${process.env.REACT_APP_BASE_URL}update-car-approval/${carId}`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + accessToken,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            console.log(response, "ress");
            setIsDisabled(!props.reportData.approval);
          }
          return response.data;
        });

      // update-approve-employee
    } catch (error) {
      console.log(error, "err");
    }
    setIsLoading(false);
  };

  const splitFullName = (fullName) => {
    const nameParts = fullName.trim().split(" ");
    if (nameParts.length === 1) {
      return {
        firstName: fullName,
        lastName: "",
      };
    }
    const lastName = nameParts.pop();
    const firstName = nameParts.join(" ");

    return {
      firstName: firstName,
      lastName: lastName,
    };
  };

  const handleAddEditEmployee = async () => {
    setIsLoading(true);
    if (props.reportData) {
      const accessToken = localStorage.getItem("Key");
      if (props.reportData.created_for.send_type === "add") {
        try {
          let fullname = splitFullName(props.reportData.kwargs.employee_name);
          const udata = {
            phone: props.reportData.kwargs.employee_phone,
            first_name: fullname.firstName,
            last_name: fullname.lastName,
            user_type: "COMPANY_USER",
            email: props.reportData.kwargs.employee_email
          };
          const uresp = await axios
            .post(`${process.env.REACT_APP_BASE_URL}signup/`, udata, {
              headers: {
                "Content-Type": "application/json",
              },
            })
            .then((response) => {
              if (response.data.is_exists == "true") {
                toast("User with this phone number already exists.");
                return;
              }
              let data = new FormData();
              data.append("converted", true);
              data.append("user_id", parseInt(response.data.data.user_id));
              data.append(
                "company_id",
                parseInt(props.reportData.kwargs.company_id)
              );
              data.append(
                "employee_name",
                props.reportData.kwargs.employee_name
              );
              data.append(
                "employee_email",
                props.reportData.kwargs.employee_email
              );
              data.append(
                "employee_phone",
                props.reportData.kwargs.employee_phone
              );
              data.append("employee_type", props.reportData.created_for.type);
              data.append(
                "employee_address",
                props.reportData.kwargs.employee_address
              );
              data.append(
                "driver_licence_number",
                props.reportData.kwargs.driver_licence_number
              );
              for (
                let i = 0;
                i < props.reportData.kwargs.added_documents.length;
                i++
              ) {
                data.append(
                  "added_documents",
                  props.reportData.kwargs.added_documents[i]
                );
              }
              return axios.post(
                `${process.env.REACT_APP_BASE_URL}create-employee/`,
                data,
                {
                  headers: {
                    Authorization: "Bearer " + accessToken,
                    "Content-Type": "multipart/form-data",
                  },
                }
              );
            })
            .then((response) => {
              // console.log(response, 'res')
              setid(response.data.id);
              return response.data;
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.status === 401) {
                  toast("User with this phone number is inactive.")
                  setIsLoading(false);
                  return;
                }
                if (error.response.status === 400 && error.response.data.is_exists == "true") {
                  toast("User with this phone number already exists.")
                  setIsLoading(false);
                  return;
                }
              }
  
            });
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          let data = new FormData();
          data.append("converted", true);
          data.append("user_id", parseInt(props.reportData.kwargs.user_id));
          data.append(
            "company_id",
            parseInt(props.reportData.kwargs.company_id)
          );
          data.append("employee_name", props.reportData.kwargs.employee_name);
          data.append("employee_email", props.reportData.kwargs.employee_email);
          data.append("employee_phone", props.reportData.kwargs.employee_phone);
          data.append("employee_type", "Driver");
          data.append(
            "employee_address",
            props.reportData.kwargs.employee_address
          );
          data.append(
            "driver_licence_number",
            props.reportData.kwargs.driver_licence_number
          );
          if (props.reportData.kwargs.added_documents.length !== 0) {
            for (
              let i = 0;
              i < props.reportData.kwargs.added_documents.length;
              i++
            ) {
              data.append(
                "added_documents",
                props.reportData.kwargs.added_documents[i]
              );
            }
          }
          if (props.deletedDocumentsId !== "") {
            data.append("deleted_documents", props.deletedDocumentsId);
          }
          const resp = await axios
            .put(
              `${process.env.REACT_APP_BASE_URL}update-driver-account/${props.reportData.kwargs.emp_id}`,
              data,
              {
                headers: {
                  Authorization: "Bearer " + accessToken,
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then((response) => {
              console.log(response, "res");
              setid(props.reportData.kwargs.emp_id);

              return response.data;
            });
          console.log(resp);
        } catch (error) {
          console.log(error);
          if (error.response.status === 401) {
            setMessege("Your session is Expired, please login again");
          }
        }
      }
    }
    setIsLoading(false);
  };

  const handleUpdateApprovalEmployee = async () => {
    if (id === null || id === undefined) {
      return;
    }
    const accessToken = localStorage.getItem("Key");
    setIsLoading(true);
    try {
      const data = {
        is_approved: true,
      };
      const resp = await axios
        .put(
          `${process.env.REACT_APP_BASE_URL}update-approve-employee/${id}`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + accessToken,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            setIsDisabled(!props.reportData.approval);
          }
          return response.data;
        });

      // update-approve-employee
    } catch (error) {
      console.log(error, "err");
    }
    setIsLoading(false);
  };

  //    approved one
  const handleApproveDone = async () => {
    if (IsDisabled) {
      const accessToken = localStorage.getItem("Key");
      setIsLoading(true);
      try {
        const data = {
          is_Approved: true,
        };
        const resp = await axios
          .put(
            `${process.env.REACT_APP_BASE_URL}update-report-approved/${props.reportId}`,
            data,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
              },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              console.log(response, "report");
              toast("Successfully Approved");
            }
            return response.data;
          });

        // update-approve-employee
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    }
  };

  // useeffect
  useEffect(() => {
    handleUpdateApprovalEmployee();
  }, [id]);
  useEffect(() => {
    handleApproveDone();
  }, [IsDisabled]);
  useEffect(() => {
    handleCarApprove();
  }, [isCarApprove]);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 20,
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div>
          {props.reportData ? (
            props.reportData.created_for.type !== "car" ? (
              // for employee data
              <div>
                <div style={{ display: "flex" }} className="mb-3">
                  <span>Employee Email</span>:&nbsp;
                  <span  className="text-gray">{props.reportData.kwargs.employee_email}</span>
                </div>
                <div style={{ display: "flex" }} className="mb-3">
                  <span>Employee Name</span>:&nbsp;
                  <span  className="text-gray">
                    {String(
                      props.reportData.kwargs.employee_name
                    ).toUpperCase()}
                  </span>
                </div>
                <div style={{ display: "flex" }} className="mb-3">
                  <span>Employee Phone</span>:&nbsp;
                  <span  className="text-gray">{props.reportData.kwargs.employee_phone}</span>
                </div>
                <div style={{ display: "flex" }} className="mb-3">
                  <span>Employee Address</span>:&nbsp;
                  <span  className="text-gray">{props.reportData.kwargs.employee_address}</span>
                </div>
                <div style={{ display: "flex" }} className="mb-3">
                  <span>Employee </span>:&nbsp;
                  <span  className="text-gray">{props.reportData.created_for.type}</span>
                </div>
                {props.reportData.kwargs.driver_licence_number !== null ? (
                  <div style={{ display: "flex" }} className="mb-3">
                    <span>Licence Number</span>:&nbsp;
                    <span  className="text-gray">{props.reportData.kwargs.driver_licence_number}</span>
                  </div>
                ) : null}
                <div style={{ display: "flex" }} className="mb-3">
                  <span>Employee Registration Documents</span>:&nbsp;
                  {documentURLs.length !== 0 ? (
                    <div  className="text-gray row">
                      {documentURLs.map((url, i) => {
                        return url ? (
                          <div className="col-12" key={i}>
                            <a
                              href={url}
                              className="fa fa-download"
                              target="_blank"
                              style={{ cursor: "pointer" }}
                            >
                              View Document {i + 1}
                            </a>
                          </div>
                        ) : null;
                      })}
                    </div>
                  ) : (
                    <div  className="text-gray">No Documents</div>
                  )}
                </div>
                <div style={{ display: "flex" }} className="mb-3">
                  <span>Delete Employee Registration Documents</span>:&nbsp;
                  {props.reportData.kwargs.deleted_documents.length !== 0 ? (
                    <div className="text-gray row">
                      {props.reportData.kwargs.deleted_documents.map(
                        (url, i) => {
                          return url ? (
                            <div className="col-12" key={i}>
                              <a
                                href={url.document_url}
                                className="fa fa-download"
                                target="_blank"
                                style={{ cursor: "pointer" }}
                              >
                                View Document {i + 1}
                              </a>
                            </div>
                          ) : null;
                        }
                      )}
                    </div>
                  ) : (
                    <div>No Documents To Delete</div>
                  )}
                </div>
                <div>
                  <button
                  type="button"
                    className={
                       !IsDisabled
                        ? "btn btn-gradient-primary btn-icon-split btn-sm mt-3"
                        : "btn btn-gradient-primary btn-icon-split btn-sm disabledCursor mt-3"
                    }
                    disabled={IsDisabled}
                    onClick={() => {
                      handleAddEditEmployee();
                    }}
                  >
                    <FontAwesomeIcon icon={faCheckCircle} />
                    { !IsDisabled ? (
                      <span className="text">Approve</span>
                    ) : (
                      <span className="text">Approved</span>
                    )}
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <div style={{ display: "flex" }} className="mb-3">
                  <span>Car Model</span>:&nbsp;
                  <span  className="text-gray">{props.reportData.kwargs.car_model}</span>
                </div>

                <div style={{ display: "flex" }} className="mb-3">
                 <span>Car Type</span>:&nbsp;
                  <span  className="text-gray">
                    <div>
                      {JSON.parse(props.reportData.kwargs.car_type).map(
                        (elem, i) => {
                          return (
                            <span>
                              {elem}
                              {i !==
                                JSON.parse(props.reportData.kwargs.car_type)
                                  .length -
                                  1 && ", "}
                            </span>
                          );
                        }
                      )}
                    </div>
                  </span>
                </div>
                <div style={{ display: "flex" }} className="mb-3">
                  <span>Car Registration Number</span>:&nbsp;
                  <span className="text-gray">{props.reportData.kwargs.registration_number}</span>
                </div>
                <div style={{ display: "flex" }} className="mb-3">
                  <span>Car Registration Documents</span>:&nbsp;
                  {documentURLs.length !== 0 ? (
                    <div className="row text-gray">
                      {documentURLs.map((url, i) => {
                        return url ? (
                          <div className="col-12" key={i}>
                            <a
                              href={url}
                              className="fa fa-download"
                              target="_blank"
                              style={{ cursor: "pointer" }}
                              aria-disabled={!IsDisabled}
                            >
                              View Document {i + 1}
                            </a>
                          </div>
                        ) : null;
                      })}
                    </div>
                  ) : (
                    <div className="text-gray">No Documents</div>
                  )}
                </div>
                <div style={{ display: "flex" }} className="mb-3">
                  <span>Delete Car Registration Documents</span>:&nbsp;
                  {props.reportData.kwargs.deleted_documents.length !== 0 ? (
                    <div className="text-gray row">
                      {props.reportData.kwargs.deleted_documents.map(
                        (url, i) => {
                          return url ? (
                            <div className="col-12" key={i}>
                              <a
                                href={url.document_url}
                                className="fa fa-download"
                                target="_blank"
                                style={{ cursor: "pointer" }}
                              >
                                View Document {i + 1}
                              </a>
                            </div>
                          ) : null;
                        }
                      )}
                    </div>
                  ) : (
                    <div className="text-gray">No Documents To Delete</div>
                  )}
                </div>
                <div>
                  <button
                  type="button"
                    disabled={IsDisabled}
                    className={
                      !IsDisabled
                        ? "btn btn-gradient-primary btn-icon-split btn-sm mt-3"
                        : "btn btn-gradient-primary btn-icon-split btn-sm disabledCursor mt-3"
                    }
                    onClick={() => handlCarAddEdit()}
                  >
                     <FontAwesomeIcon icon={faCheckCircle} />
                    
                    {!IsDisabled ? (
                      <span className="text ms-3">Approve</span>
                    ) : (
                      <span className="text ms-3">Approved</span>
                    )}
                  </button>
                </div>
              </div>
            )
          ) : (
            <div>No Data Found</div>
          )}
        </div>
      )}
    </>
  );
};

export default CurrentChanges;
