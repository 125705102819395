import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import isTokenExpired from '../../shared/tokenexpiry'
import axios from 'axios'

const ProtectedRoute = () => {
    const authKey = localStorage.getItem('Key')
    const tokenExpired = isTokenExpired(authKey)
    const navigate = useNavigate()

    useEffect(() => {
        !authKey && navigate('/')
    }, [authKey])

    const checkTokenListBlackList = () => {
        axios
            .get(`${process.env.REACT_APP_BASE_URL}check-token-blacklist`, {
                headers: {
                    Authorization: "Bearer " + authKey,
                },
            })
            .then((res) => {
                const status = res.data.status;
                if(status === "logout"){
                    localStorage.removeItem("Key")
                    return navigate('/')
                }

            })
            .catch((err) => {
                console.log(err)
            });
    }

    useEffect(() => {
        if (tokenExpired) {
            localStorage.removeItem("Key")
            return navigate('/')
        }
        checkTokenListBlackList()
    }, [])

    return authKey ? <Outlet /> : navigate('/')
}

export default ProtectedRoute