import React from "react";
import SidebarComp from "../../components/sidebar/sidebar";
import NavbarComp from "../../components/navbar/navbar";
import ReportDetailsMainComp from "../../components/body/details/detailsmaincomp";

const ReportDetailsScreen=()=>{
    return(
        <div className="container-scroller">
            <NavbarComp />
            <div className="container-fluid page-body-wrapper">
                <SidebarComp />
                <div className="main-panel">
                    <div className="content-wrapper">
                        <ReportDetailsMainComp />
                    </div>
                </div>
            </div>
        </div>
        
    )
}

export default ReportDetailsScreen;