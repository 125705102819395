import React from "react";
import SidebarComp from "../../../sidebar/sidebar";
import NavbarComp from "../../../../components/navbar/navbar";
import DriverListCompo from "./DriverListCompo";
const DriverEarningList = () => {
  return (
    <div className="container-scroller">
      <NavbarComp />
      <div className="container-fluid page-body-wrapper">
        <SidebarComp />
        <div className="main-panel">
          <div className="content-wrapper">
            <DriverListCompo></DriverListCompo>
          </div>
        </div>
      </div>
    </div>

  )
}

export default DriverEarningList