import React, { useState, useEffect, useRef } from 'react'
import './profile.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhone, faUserGear } from '@fortawesome/free-solid-svg-icons'
import faceIcon from '../../static/images/faces/face1.jpg'
import { CircularProgress, TextField } from '@mui/material'
import axios from 'axios'
import stockholmDate from '../cards/dateformatConvert'
import PhoneInput from 'react-phone-number-input'
import { toast } from 'react-toastify'
import BorderColorIcon from '@mui/icons-material/BorderColor';


const ProfileContainer = () => {
    const [data, setData] = useState(null)
    const sessionKey = localStorage.getItem("Key");
    const [IsLoading, setIsLoading] = useState(false);
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [address, setAddress] = useState("");
    const userType = localStorage.getItem('A_usertype')
    const [profileImage, setProfileImage] = useState("");

    const handleUserDetails = async () => {
        setIsLoading(true);
        try {
            axios
                .get(`${process.env.REACT_APP_BASE_URL}get-profile/`, {
                    headers: {
                        Authorization: "Bearer " + sessionKey,
                    },
                })
                .then((response) => {
                    setData(response.data.data);
                    setEmail(response.data.data.email)
                    setFirstName(response.data.data.first_name)
                    setLastName(response.data.data.last_name)
                    setAddress(response.data.data.address)
                    setProfileImage(response.data.data.profile_image)
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.log("Error:", error.message);
                });
        } catch (error) {
            console.log("Error", error);
        }
    };

    useEffect(() => {
        handleUserDetails();
    }, []);


    const updateUserData = async () => {
        setIsLoading(true)
        const dObj = {
            'email': email,
            'first_name': firstName,
            'last_name': lastName,
            'address': address
        }
        try {
            axios.put(
                `${process.env.REACT_APP_BASE_URL}get-profile/`, dObj,
                {
                    headers: {
                        'Authorization': 'Bearer ' + sessionKey
                    }
                }
            ).then(
                (response) => {
                    toast("Updated Successfully")
                    setIsLoading(false)
                }
            )

        } catch (error) {
            setIsLoading(false)
            console.log(error)
        }
    }

    
    const fileInputRef = useRef(null);

    // Function to trigger file input click event
    const handleUploadDivClick = () => {
        fileInputRef.current.click();
    };

    // Function to handle file selection
    const hadleImageUpload = (event) => {
        setIsLoading(true)
        const selectedFile = event.target.files[0];
        let data = new FormData();
        data.append("profile_image", selectedFile);
        try {
            axios.put(
                `${process.env.REACT_APP_BASE_URL}update-profile-image/`, data,
                {
                    headers: {
                        'Authorization': 'Bearer ' + sessionKey,
                        "Content-Type": "multipart/form-data"
                    }
                }
            ).then(
                (response) => {
                    toast("Image Updated Successfully")
                    console.log(response.data.profile_image)
                    setProfileImage(response.data.profile_image)
                    localStorage.setItem('A_profile_image', response.data.profile_image)
                    setIsLoading(false)
                }
            )

        } catch (error) {
            setIsLoading(false)
            console.log(error)
        }
    };



    return (
        <div>
            <div class="d-sm-flex align-items-center justify-content-between mb-3">
                <h3 class="page-title">
                    <span class="page-title-icon bg-gradient-primary text-white me-2">
                        <i><FontAwesomeIcon icon={faUserGear} /></i>
                    </span> Profile
                </h3>
            </div>
            {!data ?
                <div className="my-5 py-5 text-center">
                    <CircularProgress />
                </div> :
                <div className="profile-grid">
                    <div className="profile-grid-temp profile-div">
                        <div className="card card-body py-4">
                            <div class="nav-profile-image text-center" style={{ flexDirection: "column" }}>
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                    onChange={(e) => hadleImageUpload(e)}
                                />
                                <div className="nav-profile-image mb-2 text-center" onClick={() => handleUploadDivClick()}>
                                    <img src={profileImage} className='profile-img mb-2' alt="profile" />
                                    {/* <div class="overlay">
                                        
                                            <span className="text-light">
                                                <BorderColorIcon />
                                            </span>
                                      
                                    </div> */}
                                </div>

                            </div>


                            <div className="nav-profile-text text-center d-flex flex-column">
                                <span className="font-weight-bold">{data.first_name} {data.last_name}</span>
                                <span className="text-secondary text-small">
                                    {userType === "ADMIN_USER" ? "Admin" : "Manager"}
                                </span>
                                <span className="text-secondary text-small">
                                    {stockholmDate(data.date_joined)}
                                </span>
                            </div>


                        </div>
                    </div>
                    <div className="profile-grid-temp profile-info">
                        <div className="card card-body">
                            <div class="col-12">
                                <form class="form-sample py-2 px-4">
                                    <div class="row mb-3">
                                        <div class="col-md-6">
                                            <div class=" mb-0 p-1 row">
                                                <TextField value={data.phone}
                                                    onChange={(e) => setPhone(e.target.value)}
                                                    type="text"
                                                    label="Phone"
                                                    disabled={true} />

                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mb-0 p-1 row">
                                                <TextField value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    type="email"
                                                    label="Email" />
                                            </div>
                                        </div>
                                    </div>


                                    <div class="row mb-3">
                                        <div class="col-md-6">
                                            <div class=" mb-0 p-1 row">


                                                <TextField value={firstName}
                                                    onChange={(e) => setFirstName(e.target.value)}
                                                    type="text"
                                                    label="First Name" />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mb-0 p-1 row">
                                                <TextField value={lastName}

                                                    onChange={(e) => setLastName(e.target.value)}
                                                    type="text"
                                                    label="Last Name" />
                                            </div>
                                        </div>
                                    </div>




                                    <div className="row">
                                        <div className="col-12">

                                            <div class=" mb-0 p-1 row">
                                                <TextField value={address}

                                                    onChange={(e) => setAddress(e.target.value)}
                                                    type="text"
                                                    label="Address" />
                                            </div>
                                        </div>

                                    </div>
                                    {userType === "ADMIN_USER" && <>
                                        {IsLoading ?
                                            <div className="d-flex justify-content-center mt-5">
                                                <button type="button" class="btn btn-gradient-primary" disabled><CircularProgress className='text-light' style={{ fontSize: ".2rem", width: "19px", height: "19px" }} /></button>
                                            </div>
                                            :
                                            <div className="d-flex justify-content-center mt-5" onClick={(e) => updateUserData()}>
                                                <button type="button" class="btn btn-gradient-primary">Update</button>
                                            </div>}
                                    </>}



                                </form>
                            </div >
                        </div>
                    </div>
                </div>
            }

        </div>

    )
}

export default ProfileContainer