import React, { useEffect, useState } from "react";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { baseURL } from "../../../config";
import PaginationComponent from "../../cards/PaginationComponent";
import { useParams } from "react-router-dom";
import stockholmDate from "../../cards/dateformatConvert";
import InvoiceButton from "./buttons/invoice";
import RecieptButton from "./buttons/reciept";

const Transaction = () => {
    const [isLoading, setIsLoading] = useState(true);
    const { compId } = useParams();
    const [transactionDetails, setTransactionDetails] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const userType = localStorage.getItem("A_usertype");
    const accessToken = localStorage.getItem("Key");

    const getTransactionDetails = async () => {
        setIsLoading(true);
        if (userType === "ADMIN_USER") {
            axios.get(`${baseURL}company-invoice/${compId}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            }).then((response) => {
                setIsLoading(false);
                setTransactionDetails(response.data)
            }).catch((err) => console.log("Error-->", err))
        }
    };

    useEffect(() => {
        getTransactionDetails()
    }, [])

    const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);
    return (
        <div className="container-fluid mt-4">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="mx-2 h3 mb-0 text-gray-800">Transactions</h1>
            </div>
            <div className="card shadow my-4 mx-2">

                <div className="card-body">
                    <div className="table-responsive">
                        <table
                            className="table table-bordered"
                            id="dataTable"
                            width="100%"
                            cellSpacing="0"
                        >
                            <thead>
                                <tr>
                                    <th>Invoice Id</th>
                                    <th>Invoice Date</th>
                                    <th>Due Date</th>
                                    <th>Amount</th>
                                    <th>Status</th>
                                    <th>Invoice</th>
                                    <th>Reciept</th>
                                </tr>
                            </thead>
                            
                            {isLoading ? (
                                <tbody>
                                    <tr>
                                        <td colSpan="10" className="text-center">
                                            <CircularProgress />
                                        </td>
                                    </tr>
                                </tbody>
                            ) : (
                                <tbody>
                                    {transactionDetails?.data?.length !== 0 ?
                                        <>
                                            {transactionDetails?.data?.map((elem, i) => (
                                                <>{transactionDetails?.data?.length === 1 ?
                                                    elem.generated ?
                                                        (<tr key={i}>
                                                            <td>{elem.id}</td>
                                                            <td>{stockholmDate(elem.timestamp)}</td>
                                                            <td>{stockholmDate(elem.due_date)}</td>
                                                            <td>{elem.amount} SEK</td>
                                                            <td>{elem.status}</td>
                                                            <td>
                                                                <InvoiceButton elem={elem} />
                                                            </td>

                                                            <td>
                                                                <RecieptButton elem={elem} />
                                                            </td>
                                                        </tr>)
                                                        : (<tr>
                                                            <td colSpan={10} className="text-center">No Transaction</td>
                                                        </tr>) : elem.generated ?
                                                        (<tr key={i}>
                                                            <td>{elem.id}</td>
                                                            <td>{stockholmDate(elem.timestamp)}</td>
                                                            <td>{stockholmDate(elem.due_date)}</td>
                                                            <td>{elem.amount} SEK</td>
                                                            <td>{elem.status}</td>
                                                            <td>
                                                                <InvoiceButton elem={elem} />
                                                            </td>

                                                            <td>
                                                                <RecieptButton elem={elem} />
                                                            </td>
                                                        </tr>)
                                                        : (null)}</>

                                            ))}
                                        </> : <tr>
                                            <td colSpan={10} className="text-center">No Transaction</td>
                                        </tr>}
                                </tbody>
                            )
                            }
                        </table>
                        <PaginationComponent
                            currentPage={currentPage}
                            totalPages={transactionDetails?.total_pages}
                            onPageChange={handlePageChange}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Transaction;
