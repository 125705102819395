import React, { useState } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

const options = {
    responsive: true,
    scales: {
      y: {
        ticks: {
          stepSize: 5000,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };
  

const Barchart = (props) => {
    // const data = Object.entries(props.data).map(([month, Earning]) => ({
    //     month,
    //     Earning,
    // }));
    const labels = Object.keys(props.data);

    const data = {
        labels,
        datasets: [
            {
                fill: true,
                label: 'Earning',
                data:  Object.values(props.data),
                borderColor: 'rgb(254, 112, 150)',
                backgroundColor: 'rgba(255, 191, 150, 0.5)',
            },
        ],
    };
    // const [activeDot, setActiveDot] = useState(null);
    // const handleDotClick = (data, index) => {
    //     setActiveDot({ data, index });
    // };
    return (
        <div>
            <Line options={options} data={data} />
        </div>
    )
}

export default Barchart