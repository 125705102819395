import React, { useState, useEffect } from "react";
// import usePlacesAutocomplete,{
//   getGeocode,
//   getLatLng,
//   getDetails,
// } from "use-places-autocomplete";
import usePlacesAutocomplete, { getGeocode, getLatLng, getDetails } from 'use-places-autocomplete';
import { toast } from "react-toastify";
import useOnclickOutside from "react-cool-onclickoutside";
import { InputAdornment, TextField } from "@mui/material";
import "./styles.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

// import ClearIcon from '@mui/icons-material/Clear';
const PlacesAutocomplete = (props) => {
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [formattedAddress, setFormatedAddress] = useState("");
  const [Erorr, setErorr] = useState(props.PEror)
  // const [toggle, settoggle] = useState(false)
  const [placeType, setPlaceType] = useState([]);
  const [IsLoading, setIsLoading] = useState(false)
  const [isready, setIsReady] = useState(false)


  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: 'se' },
    },
    debounce: 300,
  });
  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });


  const handleInput = (e) => {
    if (ready === false & isready === false) {
      toast('Please Refresh The Page')
      setIsReady(true)
    }
    else if (e.target.value.length === 0) {
      setIsReady(false)
    }

    //     if (Erorr) {
    //       if (e.target.value.includes(1)) {
    //         setErorr('')
    //       }
    //     }
    setErorr('')
    setValue(e.target.value);
  };






  const handleSelect = ({ description }) => async () => {
    setIsLoading(true);

    setValue(description, false);

    const selected_data = data.filter((ele) => ele.description === description);

    try {
      const detail = await getDetails({ placeId: selected_data[0].place_id });
      const hasStreetNumber = detail.address_components.some(component => {
        return component.types.includes('postal_code');
      });

      const isRoute = detail.types.includes('route');

      if (isRoute && !hasStreetNumber) {
        // This is a location that should have a street number, but it's missing
        setErorr('street number is required');
        setFormatedAddress("");
        setLocation({
          latitude: null,
          longitude: null,
        });
        const streetName = detail.address_components.find(component => component.types.includes('route'));
        if (streetName) {
          setValue(streetName.long_name, false);
        }
      } else {
        const { lat, lng } = detail.geometry.location;
        setLocation({
          latitude: lat(),
          longitude: lng(),
        });
        setFormatedAddress(description);
        setErorr('');
      }


    } catch (error) {
      console.error("Error fetching place details:", error);
    }

    clearSuggestions();
    setIsLoading(false);
  };



  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <div key={place_id} className="places-list" onClick={handleSelect(suggestion)}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </div>
      );
    });
  useEffect(() => {
    props.passLocation(location);
    props.passFormattedAddress(formattedAddress, Erorr);

  }, [location])
  return (
    <div ref={ref} style={{ paddingTop: "8px" }} className="autocomplete-container ">
      <div style={{ display: props.isDisplayLocation, position: 'relative' }}>
        {/* {ready === false ?
        <p style={{color:'red'}}>Please Refresh The Page</p>
        : */}
        {props.addStop?
          <TextField
            value={value}
            onChange={handleInput}
            label={props.placeholder}
            autoComplete="off"
            fullWidth
            InputProps={{
              endAdornment: <InputAdornment position="start" sx={{cursor:"pointer"}} onClick={()=>props.handleViaLocationInput()}>
                <FontAwesomeIcon className="text-gray" icon={faTrash}/>
              </InputAdornment>,
            }}
          />
          :
          <TextField
            value={value}
            onChange={handleInput}
            label={props.placeholder}
            autoComplete="off"
            fullWidth

          />
        }
        {/* <p sty>Start With Street Number</p> */}
        {/* <div style={{position:'absolute', top:5, right:5}}>
        <ClearIcon onClick={()=>{setValue('')}}/>
        </div> */}
        <p style={{ color: 'red' }}>{Erorr}</p>

      </div>


      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {status === "OK" &&
        <div className="card shadow autocomplete-dropdown m-0 p-0">
          <div className="card-body places-card m-0 p-0">
            {renderSuggestions()}
          </div>
        </div>}

    </div>
  );
};

export default PlacesAutocomplete;