import React from "react";

const DeleteConfirmationModel = ({showModel, onHandleDelete, handleCloseModel}) => {
    return (
        <>{
            showModel.show? <div class="modal fade show" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" style={{ display: "block", paddingRight: "14.9921px", background:"#1f1e1e73" }} aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h6 class="modal-title" id="exampleModalLabel">Confirmation</h6>
                    </div>
                    <div class="modal-body">
                    Are you sure you want to delete?
                    </div>
                    <div class="modal-footer">
                        <button type="button" onClick={() => handleCloseModel()} class="btn btn-secondary">Cancel</button>
                        <button type="button" onClick={() => onHandleDelete(showModel.id)} class="btn btn-danger">Delete</button>
                    </div>
                </div>
            </div>
        </div>:null
        }
           
        </>
    );
};

export default DeleteConfirmationModel;
