import React, { useState, useEffect } from "react";
import axios from "axios";
import { selectUserType } from "../../redux/slicer";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import PaginationComponent from "../PaginationComponent";
import { baseSocketURL } from "../../../config";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faEye } from "@fortawesome/free-solid-svg-icons";
const ReportCards = () => {
  // const userType = useSelector(selectUserType)
  const userType = localStorage.getItem("A_usertype");
  const userId = localStorage.getItem("A_userid");

  const [isLoading, setIsLoading] = useState(true);
  const [reportData, setReportData] = useState({ "results": [] });
  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleGetReportData = async () => {
    setIsLoading(true);
    try {
      const accessToken = localStorage.getItem("Key");
      if (userType === "ADMIN_USER") {
        const resp = await axios
          .get(`${process.env.REACT_APP_BASE_URL}all-report/?paginate=true&page=${currentPage}`, {
            headers: {
              Authorization: "Bearer " + accessToken,
            },
          })
          .then((response) => {
            return response;
          });
        let results = resp.data
        setReportData(results);
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    handleGetReportData();
  }, [currentPage]);
  console.log(reportData);

  useEffect(() => {
    const socket = new WebSocket(`${baseSocketURL}/ws/entity_listener/${userId}`);
    socket.addEventListener('message', (event) => {
      const { message } = JSON.parse(event.data);
      if (message.type === "send_report_message") {
        handleGetReportData()
        toast.info(message.info)
      }
    });
    return () => {
      socket.close();
    };
  }, []);

  return (
    <div class="card shadow mb-4">
      <div class="card-body">
        <div class="table-responsive">
          <table
            class="table table-bordered"
            id="dataTable"
            width="100%"
            cellspacing="0"
          >
            <thead>
              <tr>
                <th>Created By</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Company Name</th>
                <th>Report Type</th>
                <th>Is Approved</th>
                <th>Details</th>
              </tr>
            </thead>


            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan="7" className="text-center">
                    <CircularProgress />
                  </td>
                </tr>
              ) : (
                reportData.results.length !== 0 ?
                  reportData.results
                    .map((el, i) => {
                      return (
                        <tr key={i}>
                          <td>{el.created_by_name}</td>
                          <td>{el.created_by_phone}</td>
                          <td>{el.created_by_email}</td>
                          <td>{el.company_name}</td>
                          <td>
                            {String(el.created_for.type).toLocaleUpperCase()}
                          </td>
                          <td>{el.approval ? "Approved" : "Not Approved"}</td>
                          <td>
                            {el.approval ?
                              <button
                                disabled

                                className="btn btn-gradient-success btn-sm"
                              >
                                <FontAwesomeIcon icon={faCheckCircle} />
                              </button>
                              :
                              <Link
                                to={"/dashboard/report-details" + "/" + el.id}
                                className="btn btn-gradient-primary btn-sm"
                              >
                                <FontAwesomeIcon icon={faEye} />
                              </Link>

                            }

                          </td>
                        </tr>
                      );
                    }) : <tr>
                    <td colSpan="7" className="text-center">
                      No Reports
                    </td>
                  </tr>
              )}
            </tbody>
          </table>
        </div>
        <PaginationComponent
          currentPage={currentPage}
          totalPages={reportData.total_pages}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default ReportCards;
