import React, { useState, useEffect } from "react";
import axios from "axios";
import CreatableSelect from "react-select/creatable";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Spinner_component from "../../components/profile/Spinner";
import { AiOutlineLink } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";
import { CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCab } from "@fortawesome/free-solid-svg-icons";

const EditCarForm = () => {
  const [companyId, setCompanyId] = useState(null);
  const [carModel, setCarModel] = useState(null);
  const [carTypeList, setCarTypeList] = useState([]);
  const [carRegistrationNumber, setCarRegistrationNumber] = useState(null);
  const [carRegistrationFile, setCarRegistrationFile] = useState([]);
  const [showCarList, setshowCarList] = useState(false);
  const [addedDocumentsUrl, setAddedDocumentsUrl] = useState([]);
  const [deletedDocumentsId, setDeletedDocumentsId] = useState("");
  const [instanceLoded, setinstanceLoded] = useState(true);
  const [deletedDocumentsDataList, setdeletedDocumentsDataList] = useState([]);
  const nav = useNavigate();
  const [companyData, setCompanyData] = useState([]);
  const [companyLabel, setCompanyLabel] = useState([
    { id: "n/a", name: "n/a", label: "n/a" },
  ]);

  const [isCompanyData, setIsCompanyData] = useState(false);

  const usertype = localStorage.getItem("A_usertype");
  const ci = localStorage.getItem("A_companyinfo");
  let ccid = null;
  let empid = null;
  if (ci !== "undefined" && ci !== "null") {
    ccid = JSON.parse(ci.replace(/\\/g, "")).company_id;
    empid = JSON.parse(ci.replace(/\\/g, "")).employee_id;
  }
  const { carId } = useParams();

  const [messege, setMessege] = useState("");
  const accessToken = localStorage.getItem("Key");
  const cartypes = [
    { name: "Trixi", label: "Trixi" },
    { name: "Electric", label: "Electric" },
    { name: "Premium", label: "Premium" },
    { name: "Xl", label: "Xl" },
  ];

  const [IsLoading1, setIsLoading1] = useState(false);
  const [IsLoading2, setIsLoading2] = useState(true);
  const handleFileInput = (e) => {
    const selectedFiles = e.target.files;
    setCarRegistrationFile(selectedFiles);
  };

  // To Get Compnay Data
  const handleGetCompanyData = () => {
    try {
      setIsLoading1(true);
      const resp = axios
        .get(`${process.env.REACT_APP_BASE_URL}all-company/`, {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        })
        .then((response) => {
          setCompanyData(response.data);
          const company_list = response.data.map((el, i) => {
            return {
              label: el.name,
              id: el.id,
              name: el.name,
            };
          });
          setCompanyLabel(company_list);
          setIsCompanyData(true);
          setIsLoading1(false);
          return response.data;
        });
    } catch (error) {
      toast("Somthing went wrong not able to fetch user data");
      setIsCompanyData(false);
      console.log(error);
      setIsLoading1(false);
    }
  };

  // Get car instance data
  const handleGetInstanceData = () => {
    try {
      const resp = axios
        .get(`${process.env.REACT_APP_BASE_URL}edit-car/` + carId, {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        })
        .then((response) => {
          console.log("response", response);
          setCompanyId(response.data.company_id);
          setCarModel(response.data.car_model);
          setCarTypeList(response.data.car_type);
          setCarRegistrationNumber(response.data.registration_number);
          setshowCarList(true);
          setAddedDocumentsUrl(response.data.added_document_urls);
          setIsLoading2(false);
          return response.data;
        });
    } catch (error) {
      toast("Somthing went wrong not able to fetch user data");
      console.log(error);
      setIsLoading2(false);
    }
  };

  useEffect(() => {
    if (usertype === "ADMIN_USER") {
      handleGetCompanyData();
    }
  }, []);

  useEffect(() => {
    handleGetInstanceData();
  }, [instanceLoded]);

  const handleEditCar = async (e) => {
    e.preventDefault();
    if (usertype == "ADMIN_USER") {
      if (companyId === null) {
        setMessege("You should select a company");
        return;
      }
    }
    if (carTypeList.length === 0) {
      setMessege("You should select a car type");
      return;
    }

    if (carModel === null) {
      setMessege("You should enter a car model");
      return;
    }
    if (carRegistrationNumber === null) {
      setMessege("You should enter car registeration number");
      return;
    }
    try {
      let data;
      data = new FormData();
      data.append("car_type", JSON.stringify(carTypeList));
      data.append("car_model", carModel);
      data.append("registration_number", carRegistrationNumber);
      if (carRegistrationFile.length !== 0) {
        for (let i = 0; i < carRegistrationFile.length; i++) {
          data.append("added_documents", carRegistrationFile[i]);
        }
      }
      if (usertype === "ADMIN_USER") {
        if (deletedDocumentsId !== "") {
          data.append("deleted_documents", deletedDocumentsId);
        }
        data.append("company_id", companyId);
      } else {
        data.append("deleted_documents", JSON.stringify(deletedDocumentsDataList))
        data.append("type", "car");
        data.append("send_type", "edit");
        data.append("car_id", parseInt(carId));
        data.append("company_id", ccid);
        data.append("created_by", empid);
      }

      if (usertype == "ADMIN_USER") {
        const url = `${process.env.REACT_APP_BASE_URL}edit-car/` + carId;
        const resp = await axios
          .put(url, data, {
            headers: {
              Authorization: "Bearer " + accessToken,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            toast("Car is Updated");
            nav("/dashboard/all-cars");

            return response.data;
          });
      } else {

        const url = `${process.env.REACT_APP_BASE_URL}create-report-firebase/`;
        const resp = await axios
          .post(url, data, {
            headers: {
              Authorization: "Bearer " + accessToken,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            toast("Car updated request is send to admin");
            nav("/dashboard/my-cars");

            return response.data;
          });
      }
    } catch (error) {
      if (error.response.status === 401) {
        toast("Your session is Expired, please login again");
      } else if (error.response.status === 400) {
        toast("Please make sure you are entering valid fields value");
      } else {
        toast("Somthing went wrong");
      }
    }
  };

  const handleCarTypeSelectList = (name) => {
    console.log(name);
    let updatedList = carTypeList;
    if (!updatedList.includes(name)) {
      updatedList.push(name);
    }
    setCarTypeList(updatedList);
    setshowCarList(new Date().getTime());
  };
  const handleCarTypeDeleteSelectList = (e) => {
    let updatedList = carTypeList.filter(
      (carType) => carType !== e.target.innerText
    );
    setCarTypeList(updatedList);
    setshowCarList(new Date().getTime());
  };

  const handleDeleteDocument = (id) => {
    let updatedId;
    if (deletedDocumentsId === "") updatedId = id;
    else updatedId = deletedDocumentsId + "," + id;
    setDeletedDocumentsId(`${updatedId}`);
    let filterdata = addedDocumentsUrl.filter((item) => item.id !== id);
    setAddedDocumentsUrl(filterdata);
    if (usertype === "COMPANY_USER") {
      let removedObject = addedDocumentsUrl.filter((item) => item.id === id);
      let data = deletedDocumentsDataList
      data.push(removedObject[0])
      setdeletedDocumentsDataList(data)
    }
  };
  console.log(deletedDocumentsId);
  return (
    <>
      <div class="d-sm-flex align-items-center justify-content-between my-3">
        <h3 class="page-title">
          <span class="page-title-icon bg-gradient-primary text-white me-2">
            <i><FontAwesomeIcon icon={faCab} /></i>
          </span> Edit Car
        </h3>
        <div className="d-flex justify-content-end align-items-center">
          {usertype === "ADMIN_USER" ?
            <Link className="p-2 rounded bg-gradient-success text-white me-2 shadow-sm" to="/dashboard/all-cars" style={{ cursor: "pointer" }}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </Link>
            :
            <Link className="p-2 rounded bg-gradient-success text-white me-2 shadow-sm" to="/dashboard/my-cars" style={{ cursor: "pointer" }}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </Link>
          }

        </div>
      </div>
      <div class="col-12">
        <div class="card shadow" style={{ padding: "1rem" }}>
          <div class="card-body">
            {(IsLoading1 || IsLoading2) ? (
              <div
                className="mt-3"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: 20,
                }}
              >
                <CircularProgress />
              </div>
            ) :
              <form class="form-sample" onSubmit={(e) => handleEditCar(e)}>

                <div class="row mb-3">
                  <div class="col-md-6">
                    <div class=" mb-0 p-1 row">
                      {usertype === "ADMIN_USER" ? (
                        <FormControl fullWidth>
                          <InputLabel id="emp-type">Select Company</InputLabel>
                          <Select
                            labelId="emp-type"
                            value={companyId}
                            label="Select Company"
                            onChange={(e) => setCompanyId(e.target.value)}
                          >

                            {companyLabel.map((d, i) => {
                              return (
                                <MenuItem key={i} value={d.id}>{d.label}</MenuItem>
                              )
                            })}

                          </Select>
                        </FormControl>

                      ) : null}
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="mb-0 p-1 row">
                      <TextField value={carModel}
                        onChange={(e) => setCarModel(e.target.value)}
                        type="text"
                        label="Car Model" />
                    </div>
                  </div>
                </div>


                <div class="row mb-3">
                  <div class="col-md-6">
                    <div class=" mb-0 p-1 row">
                      <TextField value={carRegistrationNumber}
                        onChange={(e) => setCarRegistrationNumber(e.target.value)}
                        type="text"
                        label="Registration Number" />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-0 p-1 row">

                      <FormControl fullWidth>
                        <InputLabel id="emp-type">Select Company</InputLabel>
                        <Select
                          labelId="emp-type"
                          label="Select Type"
                          onChange={(e) => handleCarTypeSelectList(e.target.value)}
                        >

                          {cartypes.map((d, i) => {
                            return (
                              <MenuItem key={i} value={d.name}>{d.label}</MenuItem>
                            )
                          })}

                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>


                <div class="row mb-3">
                  <div class="col-md-6">
                    <div class=" mb-0 p-1 row">
                      <input
                        type="file"
                        className="form-control"
                        accept=".pdf, image/*"
                        onChange={handleFileInput}
                        multiple
                      />
                      {addedDocumentsUrl.length !== 0 ? (
                        <div className="row mt-2">
                          {addedDocumentsUrl.map((elem, i) => {
                            return (
                              <div className="col-12 mb-1" key={i}>
                                <a
                                  href={elem.document_url}
                                  className="fa fa-download"
                                  target="_blank"
                                  style={{ cursor: "pointer" }}
                                >
                                  <AiOutlineLink /> {elem.filename}
                                </a>{" "}
                                <RxCross2
                                  onClick={() => handleDeleteDocument(elem.id)}
                                  style={{ cursor: "pointer" }}
                                />
                              </div>
                            );
                          })}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-0 p-1">
                      {showCarList
                        ? carTypeList.map((elem, i) => {
                          return (
                            <span
                              key={i}
                              onClick={(e) => handleCarTypeDeleteSelectList(e)}
                              className="btn btn-outline-danger btn-sm me-2 mb-2"
                            >
                              {elem}
                            </span>
                          );
                        })
                        : null}

                    </div>
                  </div>
                </div>

                <div className="d-flex justify-content-center mt-5">
                  <button type="submit" class="btn btn-gradient-primary">Submit</button>
                </div>

              </form>
            }

          </div>
        </div>
      </div >
    </>
    // <div class="card-body">
    //   {IsLoading1 || IsLoading2 ? (
    //     <Spinner_component />
    //   ) : (
    //     <form onSubmit={handleEditCar} className="user">
    //       <p style={{ color: "red", fontSize: 14, fontFamily: "serif" }}>
    //         {messege}
    //       </p>
    //       {usertype === "ADMIN_USER" ? (
    //         <div className="form-group">
    //           <Select
    //             required
    //             options={companyLabel}
    //             onChange={(e) => setCompanyId(e.id)}
    //             placeholder="Select Company"
    //             defaultValue={companyLabel.find(
    //               (option) => option?.id === companyId
    //             )}
    //           ></Select>
    //         </div>
    //       ) : (
    //         <></>
    //       )}

    //       <div className="form-group">
    //         <input
    //           className="form-control"
    //           type="text"
    //           required
    //           maxLength={100}
    //           onChange={(e) => setCarModel(e.target.value)}
    //           style={{ width: "100%", position: "relative" }}
    //           placeholder="Enter Car Model"
    //           defaultValue={carModel}
    //         />
    //       </div>
    //       <div className="form-group">
    //         <input
    //           className="form-control"
    //           type="text"
    //           required
    //           maxLength={100}
    //           onChange={(e) => setCarRegistrationNumber(e.target.value)}
    //           style={{ width: "100%", position: "relative" }}
    //           placeholder="Enter Car Registration Number"
    //           defaultValue={carRegistrationNumber}
    //         />
    //       </div>
    //       <div className="form-group">
    //         {carTypeList.length != 0
    //           ? carTypeList.map((elem, i) => {
    //             return (
    //               <span
    //                 key={i}
    //                 onClick={(e) => handleCarTypeDeleteSelectList(e)}
    //                 className="btn btn-outline-danger btn-sm mr-2 mb-2"
    //               >
    //                 {elem}
    //               </span>
    //             );
    //           })
    //           : null}
    //         <Select
    //           options={cartypes}
    //           onChange={(e) => handleCarTypeSelectList(e.name)}
    //           placeholder="Select Car Type"
    //         />
    //       </div>
    //       <div className="form-group">
    //         <input
    //           type="file"
    //           accept=".pdf, image/*"
    //           onChange={handleFileInput}
    //           multiple
    //         />

    //         
    //       </div>

    //       <button type="submit" className="btn btn-primary btn-user btn-block">
    //         EDIT CAR
    //       </button>
    //     </form>
    //   )}
    // </div>
  );
};

export default EditCarForm;
