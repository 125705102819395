import React from "react";
import AllDataCommonComp from "../common/common";
import AvailableridesCard from "../../../cards/rides/availableRides";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
// import { selectUserType } from "../../../redux/slicer";

// import { useSelector } from "react-redux";
const AvailableRidesMainComp = () => {
  // const userType = useSelector(selectUserType)
  const userType = localStorage.getItem("A_usertype");

  console.log(userType, "user");

  return (
    <div class="container-fluid mt-4">
        <AllDataCommonComp
        headerIcon={faCalendar}
        allemployee="All Available Rides"
        ></AllDataCommonComp>
        <AvailableridesCard></AvailableridesCard>
    </div>
  );
};

export default AvailableRidesMainComp;
