import React from "react";
import AllDataCommonComp from "../common/common";
import ReportCards from "../../../cards/report/reportcard";
import { faFileCircleCheck } from "@fortawesome/free-solid-svg-icons";

const AllReportMainComp=()=>{
    return(
        <>
            <AllDataCommonComp addUrl={false} headerIcon={faFileCircleCheck} allemployee="All Reports" downloadCall={false} ></AllDataCommonComp>
            <ReportCards></ReportCards>
        </>
        
    )
}

export default AllReportMainComp;