import React from "react";
import EditCompanyForm from "../../../forms/editcompany";

const EditCompanyMidComp = () => {
  return (
    <EditCompanyForm></EditCompanyForm>
  );
};

export default EditCompanyMidComp;
