import React, { useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import "./sidebar.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faCab, faCalendar, faCircleCheck, faFileCircleCheck, faFileInvoice, faHome, faMoneyBills, faUser, faUserGear, faUserTie, faUsers, faUsersLine } from "@fortawesome/free-solid-svg-icons";
import { WebSocketContext } from "../../../app/App"

const NotificationSidebar = () => {
    // const userType = useSelector(selectUserType);
    const { sideBarActive } = useContext(WebSocketContext);

    const nav = useNavigate();

    const handleAccess = () => {
        if (document.getElementById("access-id").classList.contains("collapsed")) {
            document.getElementById("access-id").classList.remove("collapsed");
            document.getElementById("access-id").ariaExpanded = true;
            document.getElementById("collapseTwo").classList.add("show");
        } else {
            document.getElementById("access-id").classList.add("collapsed");
            document.getElementById("access-id").ariaExpanded = false;
            document.getElementById("collapseTwo").classList.remove("show");
        }
    };

    const handleUtility = () => {
        if (document.getElementById("utility-id").classList.contains("collapsed")) {
            document.getElementById("utility-id").classList.remove("collapsed");
            document.getElementById("utility-id").ariaExpanded = true;
            document.getElementById("collapseUtilities").classList.add("show");

        } else {
            document.getElementById("utility-id").classList.add("collapsed");
            document.getElementById("utility-id").ariaExpanded = false;
            document.getElementById("collapseUtilities").classList.remove("show");
        }
    }
    return (
        <nav className={`sidebar sidebar-offcanvas sidebar-bg-color ${sideBarActive ? 'active' : ''}`} id="sidebar">
            <ul className="nav mb-3">
                <li className="nav-item nav-profile">
                    <Link className="nav-link nav-align" href="#" to="/dashboard">
                        <span className="menu-title">Home</span>

                    </Link>
                </li>
            </ul>
            <ul className="nav">
                <li className="nav-item nav-profile">
                    <b>Notifications</b>
                </li>
                <li className="nav-item">
                    <Link className="nav-link nav-align" href="#" to="/dashboard">
                        <span className="menu-title">Billing and Payments</span>

                    </Link>
                </li>
                <li class="nav-item">
                    <a class="nav-link nav-align nav-drop" data-bs-toggle="collapse" href="#ui-basic" aria-expanded="false" aria-controls="ui-basic">
                        <span class="menu-title">Rides</span>
                        <i class="menu-arrow"></i>

                    </a>
                    <div class="collapse" id="ui-basic">
                        <ul class="nav flex-column sub-menu">
                            <li className="nav-item">
                                <a className="nav-link nav-align" href="#" onClick={() => nav("/dashboard/all-rides")}>
                                    <span className="menu-title">Schedule Rides</span>

                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link nav-align" href="#" onClick={() => nav("/dashboard/ongoing-rides")}>
                                    <span className="menu-title">Online Rides</span>

                                </a>
                            </li>
                        </ul>
                    </div>
                </li>

                <li className="nav-item nav-profile">
                    <b>Messages</b>
                </li>
                <li class="nav-item">
                    <a class="nav-link nav-align nav-drop" data-bs-toggle="collapse" href="#ui-basic1" aria-expanded="false" aria-controls="ui-basic1">
                        <span class="menu-title">Customer</span>
                        <i class="menu-arrow"></i>

                    </a>
                    <div class="collapse" id="ui-basic1">
                        <ul class="nav flex-column sub-menu">
                            <li className="nav-item">
                                <a className="nav-link nav-align" href="#" onClick={() => nav("/dashboard/all-rides")}>
                                    <span className="menu-title">Schedule Rides</span>

                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link nav-align" href="#" onClick={() => nav("/dashboard/ongoing-rides")}>
                                    <span className="menu-title">Online Rides</span>

                                </a>
                            </li>
                        </ul>
                    </div>
                </li>
            
                <li class="nav-item">
                    <a class="nav-link nav-align nav-drop" data-bs-toggle="collapse" href="#ui-basic2" aria-expanded="false" aria-controls="ui-basic2">
                        <span class="menu-title">Manager</span>
                        <i class="menu-arrow"></i>

                    </a>
                    <div class="collapse" id="ui-basic2">
                        <ul class="nav flex-column sub-menu">
                            <li className="nav-item">
                                <a className="nav-link nav-align" href="#" onClick={() => nav("/dashboard/all-rides")}>
                                    <span className="menu-title">Schedule Rides</span>

                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link nav-align" href="#" onClick={() => nav("/dashboard/ongoing-rides")}>
                                    <span className="menu-title">Online Rides</span>

                                </a>
                            </li>
                        </ul>
                    </div>
                </li>

                <li class="nav-item">
                    <a class="nav-link nav-align nav-drop" data-bs-toggle="collapse" href="#ui-basic3" aria-expanded="false" aria-controls="ui-basic3">
                        <span class="menu-title">Driver</span>
                        <i class="menu-arrow"></i>

                    </a>
                    <div class="collapse" id="ui-basic3">
                        <ul class="nav flex-column sub-menu">
                            <li className="nav-item">
                                <a className="nav-link nav-align" href="#" onClick={() => nav("/dashboard/all-rides")}>
                                    <span className="menu-title">Schedule Rides</span>

                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link nav-align" href="#" onClick={() => nav("/dashboard/ongoing-rides")}>
                                    <span className="menu-title">Online Rides</span>

                                </a>
                            </li>
                        </ul>
                    </div>
                </li>





            </ul>
        </nav>
    )
}

export default NotificationSidebar;