import React from "react";
import SidebarComp from "../../components/sidebar/sidebar";
import NavbarComp from "../../components/navbar/navbar";
import EditCarMainComp from "../../components/body/editcomponent/editcar/editcarcomp";

const EditCarScreen=()=>{
    return(
        <div className="container-scroller">
            <NavbarComp />
            <div className="container-fluid page-body-wrapper">
                <SidebarComp />
                <div className="main-panel">
                    <div className="content-wrapper">
                        <EditCarMainComp />
                    </div>
                </div>
            </div>
        </div>
        
    )
}

export default EditCarScreen;