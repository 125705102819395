import React, { useEffect, useState } from 'react';
import { GoogleMap, Marker, LoadScript, InfoWindow } from '@react-google-maps/api';
import axios from 'axios';
import carIcon from "../../assets/Cars/taxi.png";
import SidebarComp from '../sidebar/sidebar';
import NavbarComp from '../navbar/navbar';
import Spinner_component from '../profile/Spinner';
import { googleMapApiKey, baseSocketURL } from '../../config';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import io from "socket.io-client"

const DriverLocationComponent = () => {
  const socket = io.connect(`${process.env.REACT_APP_BASE_URL}socket`);
  const initialCenterLocation = { lat: 59.3293, lng: 18.0686 };
  const [centerLocation, setCenterLocation] = useState(initialCenterLocation);
  const [driverLocations, setDriverLocations] = useState([]);
  const [driverDataLoaded, setDriverDataLoaded] = useState(false)
  const [isLoading, setIsLoading] = useState(true);
  const userId = localStorage.getItem("A_userid");
  const [selectedMarker, setSelectedMarker] = useState(null);
  const userType = localStorage.getItem("A_usertype");
  const ci = localStorage.getItem("A_companyinfo");
  let ccid = null;
  if (ci !== "undefined" && ci !== "null") {
    ccid = JSON.parse(ci.replace(/\\/g, "")).company_id;
  }

  const handleMarkerClick = (marker, index) => {
    setSelectedMarker({ marker, index });
  };

  const fetchDriverData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}get-drivers-tracking/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("Key")}`,
        },
      });
      console.log(response.data)
      setDriverLocations(response.data);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchDriverData();
  }, [driverDataLoaded]);

  useEffect(() => {
    const handleConnect = () => {
      if(userType === "ADMIN_USER"){
        socket.emit("identify", 'admin', null);
      }else{
        socket.emit("identify", 'company', ccid);
      }
    };

    const handleDisconnect = () => {
      console.log("Disconnected from socket");
    };
    //change this
    socket.on("connect", handleConnect);
    socket.on("disconnect", handleDisconnect);
    return () => {
      socket.off("connect", handleConnect);
      socket.off("disconnect", handleDisconnect);
    };
  }, [userId]);

  useEffect(() => {
    const handleSocketMessage = (data) => {
      setDriverLocations((prevLocations) => {
        return prevLocations.map((location) => {
            const driverId = location.id.toString();
            console.log(data, driverId, ccid)
            if (userType === 'ADMIN_USER' && data[driverId]) {
                return {
                    ...location,
                    user_id__latitude: data[driverId].latitude,
                    user_id__longitude: data[driverId].longitude,
                };
            }
            else if(userType !== "ADMIN_USER" && data[driverId] && data[driverId].companyId === ccid){
              return {
                ...location,
                user_id__latitude: data[driverId].latitude,
                user_id__longitude: data[driverId].longitude,
            };
            }
            return location;
        });
    });
    };
    if(userType === "ADMIN_USER"){
      socket.on("adminDriverLocations", handleSocketMessage);
      }else{
      socket.on("companyDriverLocations", handleSocketMessage);
    }

    return () => {
      if(userType === "ADMIN_USER"){
        socket.off("adminDriverLocations", handleSocketMessage);
        }else{
          socket.off("companyDriverLocations", handleSocketMessage);
      }
      
    };
  }, []);

  
  const renderMap = () => {
    if (typeof window.google === 'undefined') return null;
    return <GoogleMap
      mapContainerStyle={{ width: '100%', height: '80%' }}
      center={centerLocation}
      zoom={10}
    >
      {driverLocations.map((driver, index) => (
        <React.Fragment key={index}>
          <Marker
            position={{
              lat: parseFloat(driver.latitude || driver.user_id__latitude),
              lng: parseFloat(driver.longitude || driver.user_id__longitude),
            }}
            onClick={() => handleMarkerClick(driver, index)}
            icon={{
              url: carIcon,
              scaledSize: new window.google.maps.Size(50, 45),
            }}
          />
          {selectedMarker?.index === index && (
            <InfoWindow
              position={{
                lat: parseFloat(driver.latitude || driver.user_id__latitude),
                lng: parseFloat(driver.longitude || driver.user_id__longitude),
              }}
              onCloseClick={() => setSelectedMarker(null)}
            >
              <div>
                <p>Driver Name: {driver.driver_name || driver.employee_name}</p>
              </div>
            </InfoWindow>
          )}
        </React.Fragment>
      ))}
    </GoogleMap>
  }

  return (

    <div className="container-scroller">
      <NavbarComp />
      <div className="container-fluid page-body-wrapper">
        <SidebarComp />
        <div className="main-panel">
          <div className="content-wrapper">
            <div class="d-sm-flex align-items-center justify-content-between mb-3">
              <h3 class="page-title">
                <span class="page-title-icon bg-gradient-primary text-white me-2">
                  <i><FontAwesomeIcon icon={faUser} /></i>
                </span> Driver's Location
              </h3>

            </div>
            <LoadScript googleMapsApiKey={googleMapApiKey}>
              {isLoading ? (
                <Spinner_component message="Fetching your details ..." />
              ) : (
                renderMap()
              )}
            </LoadScript>
          </div>
        </div>
      </div>
    </div>


  );
};

export default DriverLocationComponent;
