import React, { useEffect, useState } from "react";
import AllDataCommonComp from "../common/common";
import CommonRide from "../common/CommonRide";
import axios from "axios";
import { Button, ButtonGroup, TextField } from "@mui/material";
import "flatpickr/dist/themes/material_blue.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import PaginationComponent from "../../../cards/PaginationComponent";
import Select from "react-select";
import { faBuilding, faEnvelope, faLocationDot, faPhone, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CompnyEarningList = () => {
  const nav = useNavigate();

  const [showCar, setShowCar] = useState(false);
  const [isCarDataLoaded, setisCarDataLoaded] = useState(false);
  const [isManagerDataLoaded, setIsManagerDataLoaded] = useState(false);
  const [showDriver, setShowDriver] = useState(true);
  const [showManager, setShowManager] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [car_currentPage, setCarCurrentPage] = useState(1);
  const [manager_currentPage, setManagerCurrentPage] = useState(1);
  const [companyEarning, setcompanyEarning] = useState([]);
  const [year, setYear] = useState("None");
  const [driverData, setDriverData] = useState({
    drivers: {
      results: [],
    },
  });
  const [carData, setCarData] = useState({
    cars: {
      results: [],
    },
  });
  const [managerData, setManagerData] = useState({
    employee: {
      results: [],
    },
  });
  const [companyDetails, setcompanyDetails] = useState({
    total_ride: 0,
    total_earning: 0,
    company_name: "",
    vat_no: "",
    bank_account_no: '',
    bankgiro_no: '',
    bic_or_swift_code: '',
    iban: '',
  });
  const [isCompanyDetails, setIsCompanyDetails] = useState(false);
  const [isCompanyEarning, setIsCompanyEarning] = useState(false);
  const [IsLoading, setIsLoading] = useState(false);
  const [IsLoading1, setIsLoading1] = useState(false);
  const { companyId } = useParams();
  const userType = localStorage.getItem("A_usertype");
  const startYear = 2023; // Specify the starting year
  const currentYear = new Date().getFullYear(); // Get the current year

  const yearList = Array.from(
    { length: currentYear - startYear + 1 },
    (_, index) => ({
      label: String(startYear + index),
      id: index + 1,
      name: String(startYear + index),
    })
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleCarPageChange = (pageNumber) => {
    setCarCurrentPage(pageNumber);
  };
  const handleManagerPageChange = (pageNumber) => {
    setManagerCurrentPage(pageNumber);
  };
  const handleCompanyMonthlyEarning = async () => {
    setIsLoading(true);
    try {
      let param = "";
      param = `?company_id=${companyId}`;
      if (year !== "None") {
        param = param + `&year=${year}`;
      }
      const accessToken = localStorage.getItem("Key");
      if (userType === "ADMIN_USER") {
        const resp = await axios
          .get(`${process.env.REACT_APP_BASE_URL}revenue-vs-time/${param}`, {
            headers: {
              Authorization: "Bearer " + accessToken,
            },
          })
          .then((response) => {
            console.log(response.data);
            setcompanyEarning(response.data);
          });
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };
  const handleCompanyDetails = async () => {
    setIsLoading(true);
    try {
      const accessToken = localStorage.getItem("Key");
      if (userType === "ADMIN_USER") {
        const resp = await axios
          .get(
            `${process.env.REACT_APP_BASE_URL}company-all-drivers-count/${companyId}`,
            {
              headers: {
                Authorization: "Bearer " + accessToken,
              },
            }
          )
          .then((response) => {
            console.log(response.data);
            setcompanyDetails(response.data);
          });
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };
  const handleDriverData = async () => {
    setIsLoading1(true);
    try {
      const accessToken = localStorage.getItem("Key");
      console.log("acce");
      if (userType === "ADMIN_USER") {
        const resp = await axios
          .get(
            `${process.env.REACT_APP_BASE_URL}company-all-drivers/${companyId}?paginate=true&page=${currentPage}`,
            {
              headers: {
                Authorization: "Bearer " + accessToken,
              },
            }
          )
          .then((response) => {
            console.log(response.data);
            setDriverData(response.data);
            setIsLoading1(false);
          });
      }
    } catch (error) {
      console.log(error);
      setIsLoading1(false);
    }
  };
  const handleCarData = async () => {
    setIsLoading1(true);
    try {
      const accessToken = localStorage.getItem("Key");
      console.log("acce");
      if (userType === "ADMIN_USER") {
        const resp = await axios
          .get(
            `${process.env.REACT_APP_BASE_URL}company-all-cars/${companyId}?paginate=true&page=${car_currentPage}`,
            {
              headers: {
                Authorization: "Bearer " + accessToken,
              },
            }
          )
          .then((response) => {
            console.log(response.data);
            setCarData(response.data);
            setIsLoading1(false);
          });
      }
    } catch (error) {
      console.log(error);
      setIsLoading1(false);
    }
  };

  const handleManagerData = async () => {
    setIsLoading1(true);
    try {
      const accessToken = localStorage.getItem("Key");
      console.log("acce");
      if (userType === "ADMIN_USER") {
        const resp = await axios
          .get(
            `${process.env.REACT_APP_BASE_URL}company-all-employee/${companyId}?paginate=true&page=${manager_currentPage}`,
            {
              headers: {
                Authorization: "Bearer " + accessToken,
              },
            }
          )
          .then((response) => {
            console.log(response.data);
            setManagerData(response.data);
            setIsLoading1(false);
          });
      }
    } catch (error) {
      console.log(error);
      setIsLoading1(false);
    }
  };

  const handleShowCar = () => {
    setShowDriver(false);
    setShowCar(true);
    setisCarDataLoaded(true);
    setShowManager(false);
  };
  const handleShowDriver = () => {
    setShowCar(false);
    setShowDriver(true);
    setShowManager(false);
  };
  const handleShowManager = () => {
    setShowCar(false);
    setShowDriver(false);
    setShowManager(true);
    setIsManagerDataLoaded(true);
  };

  useEffect(() => {
    handleDriverData();
  }, [currentPage]);
  useEffect(() => {
    if (showCar) {
      handleCarData();
    }
  }, [car_currentPage, isCarDataLoaded]);
  useEffect(() => {
    if (showManager) {
      handleManagerData();
    }
  }, [manager_currentPage, isManagerDataLoaded]);

  useEffect(() => {
    handleCompanyDetails();
  }, [isCompanyDetails]);

  useEffect(() => {
    handleCompanyMonthlyEarning();
  }, [isCompanyEarning, year]);

  console.log(companyEarning)

  return (
    <>
      <div class="d-sm-flex align-items-center justify-content-between mb-3">
        <h3 class="page-title">
          <span class="page-title-icon bg-gradient-primary text-white me-2">
            <i><FontAwesomeIcon icon={faBuilding} /></i>
          </span> Company Earning
        </h3>
      </div>

      {IsLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 20,
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <CommonRide
            type="compny"
            company={companyDetails.company_name}
            rides={companyDetails.total_ride}
            earning={companyDetails.total_earning}
            vatNo={companyDetails.vat_no}
            bankaccountnumber={companyDetails.bank_account_no}
            bankgiro={companyDetails.bankgiro_no}
            iban={companyDetails.iban}
            bicSwiftCode={companyDetails.bic_or_swift_code}
          />
        </>)
      }


      {/* <div className="card shadow mt-3 mb-5">
        <div className="card-header">
          <div className="d-flex justify-content-between">
            <h6 class="m-0 py-3 font-weight-bold text-primary">
              Company Monthly Earning
            </h6>

            <div className="form-group w-25 mx-3">
              <Select
                required
                options={
                  yearList.length > 0
                    ? yearList
                    : [{ id: "n/a", name: "n/a", label: "n/a" }]
                }
                onChange={(e) => setYear(e.name)}
                placeholder="Select Year"
              ></Select>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            {Object.entries(companyEarning).map(([month, Earning]) => {
              return (
                <div className="col md-1 custom-card-border">
                  <div className="h6 text-center">
                    <b>
                      {Earning} <br />
                      SEK
                    </b>
                  </div>
                  <div className="text-center">{month}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div> */}


      <div className="d-flex justify-content-start my-3">
        <button onClick={handleShowDriver} className="btn btn-gradient-primary me-3" disabled={showDriver}>
          Driver
        </button>
        <button onClick={handleShowCar} className="btn btn-gradient-success me-3" disabled={showCar}>
          Cars
        </button>
        <button onClick={handleShowManager} className="btn btn-gradient-danger" disabled={showManager}>
          Managers
        </button>
      </div>
      {showCar ? (
        <>


          {IsLoading1 ? (
            <div className="text-center my-5 py-5">
              <CircularProgress />
            </div>
          ) :
            <>



              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {companyDetails.total_ride != 0 ? (
                  carData.cars.results.map((el, i) => {
                    return (
                      <div style={{ flex: "0 0 50%", padding: "7px" }} key={i}>
                        <div className="card shadow mb-0 grid-margin stretch-card">
                          <div className="card-body">
                            <h6><span className="text-dark font-weight-bold mr-1">{el.car_model} </span> ({el.registration_number})</h6>
                            <div className="my-2">
                              <p className="text-dark mb-1">
                                {el.car_type.map((elem, i) => {
                                  return (
                                    <span>
                                      {elem}
                                      {i !== el.car_type.length - 1 && ", "}
                                    </span>
                                  );
                                })}
                              </p>


                              <p className="mb-1">Total Earning: <span className="text-success">{el.earning || 0} S.E.K</span></p>
                            </div>


                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="card w-100 card-body text-center py-5 shadow-sm">
                    No Data
                  </div>
                )}
              </div>

            </>

          }

          <div className="my-3">
            <PaginationComponent
              currentPage={car_currentPage}
              totalPages={carData.cars.total_pages}
              onPageChange={handleCarPageChange}
            />
          </div>
        </>
      ) : (
        ""
      )}
      {showDriver ? (
        <>
          {IsLoading1 ? (
            <div className="text-center my-5 py-5">
              <CircularProgress />
            </div>
          ) :
            <>



              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {companyDetails.total_ride != 0 ? (
                  driverData.drivers.results.map((el, i) => {
                    return (

                      <div style={{ flex: "0 0 50%", padding: "7px" }} key={i}>
                        <div className="card shadow mb-0 grid-margin stretch-card">
                          <div className="card-body">
                            <h6><span className="text-dark font-weight-bold mr-1">{String(el.driver_name).toUpperCase()} </span></h6>
                            <div className="my-2">

                              <p className="mb-1">Total Earning: <span className="text-success">{el.earning || 0} S.E.K</span></p>
                              <p className="mb-1">Ride Completed: {el.ride_completed || 0}</p>
                              {!el.earning?
                              <button disabled={true} className="btn btn-gradient-primary btn-sm my-2">Earning</button>
                              :
                              <Link to={ `/dashboard/all-driver-earning/${el.id}/${companyId}`} className="btn btn-gradient-primary btn-sm my-2">Earning</Link>
                              }
                            </div>


                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="card w-100 card-body text-center py-5 shadow-sm">
                    No Data
                  </div>
                )}
              </div>

            </>

          }


          <div className="my-3">

            <PaginationComponent
              currentPage={currentPage}
              totalPages={driverData.drivers.total_pages}
              onPageChange={handlePageChange}
            />
          </div>
        </>
      ) : (
        ""
      )}

      {showManager ? (
        <>


          {IsLoading1 ? (
            <div className="text-center my-5 py-5">
              <CircularProgress />
            </div>
          ) :
            <>



              <div>
                {companyDetails.total_ride != 0 ? (
                  managerData.employee.results.map((el, i) => {
                    return (
                      <div className="card shadow mb-3 grid-margin stretch-card" key={i}>
                        <div className="card-body">
                          <h6><span className="text-dark font-weight-bold mr-1">{String(el.employee_name).toLocaleUpperCase()} </span></h6>
                          <div className="row">
                            <div className="col-md-6">
                              <p className="text-dark mb-1"><FontAwesomeIcon icon={faEnvelope} className="me-2 text-gray" />{el.employee_email}</p>
                              <p className="text-dark mb-1"><FontAwesomeIcon icon={faLocationDot} className="me-2 text-gray" />{el.employee_address}</p>
                              <p className="text-dark mb-0"><FontAwesomeIcon icon={faPhone} className="me-2 text-gray" />{el.employee_phone}</p>
                            </div>
                            <div className="col-md-4">

                              {el.added_document_urls.length !== 0 ? (
                                <div className="row">
                                  {el.added_document_urls.map((elem, i) => {
                                    return (
                                      <div className="col-12">
                                        <a
                                          href={elem.document_url}
                                          className="fa fa-download"
                                          target="_blank"
                                          style={{ cursor: "pointer" }}
                                        >
                                          View Document {i + 1}
                                        </a>
                                      </div>
                                    );
                                  })}
                                </div>
                              ) : (
                                "No Documents"
                              )}
                            </div>


                          </div>


                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="card w-100 card-body text-center py-5 shadow-sm">
                    No Data
                  </div>
                )}
              </div>

            </>

          }



          <div className="my-3">
            <PaginationComponent
              currentPage={manager_currentPage}
              totalPages={managerData.employee.total_pages}
              onPageChange={handleManagerPageChange}
            />
          </div>
        </>
      ) : (
        ""
      )}


    </>

  );
};

export default CompnyEarningList;
