import React from "react";
import EditDriverMidComp from "./editdrivercomp";

const EditDriverMainComp=()=>{
    return(
        <div class="container-fluid">
            <EditDriverMidComp></EditDriverMidComp>
        </div>
    )
}

export default EditDriverMainComp;