import React, { useState, useEffect } from "react";
import DriverRidesCards from "../../../cards/rides/driverridescard";
import axios from "axios";
import { toast } from "react-toastify";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { utcToZonedTime, format } from 'date-fns-tz';
import moment from "moment-timezone";

const DriverRidesMainComp = () => {
  const dateFormat = (date) => {
    const formattedDate = date.format("YYYY-MM-DD HH:mm:ss");
    return formattedDate;
  };


  const userType = localStorage.getItem("A_usertype");
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const accessToken = localStorage.getItem("Key");
  const [driverList, setDriverList] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [companyList, setCompanyList] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [appliedState, setAppliedState] = useState(null);
  const [paramData, setParamData] = useState("");

  const handleGetDriverListData = async (company_id) => {
    try {
      if (userType === "ADMIN_USER" || userType === "COMPANY_USER") {
        let driver_dropdown_url = `${process.env.REACT_APP_BASE_URL}driver-list-dropdown/`

        if (company_id !== null) {
          driver_dropdown_url += `?company_id=${company_id}`
        }

        const resp = await axios
          .get(driver_dropdown_url, {
            headers: {
              Authorization: "Bearer " + accessToken,
            },
          })
          .then((response) => {
            return response;
          });
        let results = resp.data
        console.log(results)
        setDriverList(results)
      }
    } catch (error) {
      toast(error.response.data.detail)
      console.log(error);
    }
  };


  const handleGetCompanyListData = async () => {
    try {
      if (userType === "ADMIN_USER") {
        const resp = await axios
          .get(`${process.env.REACT_APP_BASE_URL}company-list-dropdown/`, {
            headers: {
              Authorization: "Bearer " + accessToken,
            },
          })
          .then((response) => {
            return response;
          });
        let results = resp.data
        console.log(results)
        setCompanyList(results)
      }
    } catch (error) {
      toast(error.response.data.detail)
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetCompanyListData();
    handleGetDriverListData(null);
  }, [])


  const handleDriverDropDownData = (company_id) => {
    setSelectedCompany(company_id);
    setSelectedDriver(null);
    handleGetDriverListData(company_id);
  }

  const handleApplyButton = ()=>{
    setAppliedState(new Date())
    let parameters = ""
    if(selectedEndDate) parameters+=`ride_end_date=${dateFormat(selectedEndDate)}&`;
    if(selectedStartDate) parameters+=`ride_start_date=${dateFormat(selectedStartDate)}&`;
    if(selectedDriver) parameters+=`driver_assigned=${selectedDriver}&`;
    if(selectedCompany) parameters+=`company_id=${selectedCompany}&`;
    setParamData(parameters)
  }
  const handleClearFilters = ()=>{
    setAppliedState(null)
    setParamData("")
    setSelectedCompany(null)
    setSelectedDriver(null)
    setSelectedEndDate(null)
    setSelectedStartDate(null)
  }





  return (
    <div class="container-fluid mt-4">
      {userType === "COMPANY_USER" || userType === "ADMIN_USER" ? (
        <>
          <div class="d-sm-flex align-items-center justify-content-between mb-3">
            <h3 class="page-title">
              <span class="page-title-icon bg-gradient-primary text-white me-2">
                <i><FontAwesomeIcon icon={faUser} /></i>
              </span> Driver Rides
            </h3>

          </div>
          <div className="my-3 card shadow">
            <div className="card-body">
              <div className="row">
                {userType === "ADMIN_USER" ?
                  <>
                    <div className="col-md-3" style={{ marginTop: "6px" }}>

                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={[
                            'DateTimePicker',
                          ]}
                        >

                          <DemoItem>
                            <DateTimePicker slotProps={{ textField: { size: 'small' } }} label="Ride Start Date" onChange={setSelectedStartDate}
                              value={selectedStartDate} />
                          </DemoItem>

                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                    <div className="col-md-3" style={{ marginTop: "6px" }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={[
                            'DateTimePicker',
                          ]}
                        >

                          <DemoItem>
                            <DateTimePicker slotProps={{ textField: { size: 'small' } }} sx={{ marginTop: "6px" }} label="Ride End Date" onChange={setSelectedEndDate}
                              value={selectedEndDate} />
                          </DemoItem>

                        </DemoContainer>
                      </LocalizationProvider>

                    </div>

                    <div className="col-md-3">
                      <FormControl fullWidth className="mt-2">
                        <InputLabel id="car-type">Select Company</InputLabel>
                        <Select
                          size="small"
                          sx={{ marginTop: "6px" }}
                          labelId="car-type"
                          value={selectedCompany}
                          label="Select Company"
                          onChange={(e) => handleDriverDropDownData(e.target.value)}
                        >
                          <MenuItem value={null}>All Company</MenuItem>

                          {companyList.map((d, i) => {
                            return (
                              <MenuItem key={i} value={d.value}>{d.label}</MenuItem>
                            )
                          })}

                        </Select>
                      </FormControl>

                    </div>

                    <div className="col-md-3">
                      <FormControl fullWidth className="mt-2">
                        <InputLabel id="car-type">Select Driver</InputLabel>
                        <Select
                          sx={{ marginTop: "6px" }}
                          size="small"
                          labelId="car-type"
                          value={selectedDriver}
                          label="Select Driver"
                          onChange={(e) => setSelectedDriver(e.target.value)}
                        >
                          <MenuItem value={null}>None</MenuItem>

                          {driverList.map((d, i) => {
                            return (
                              <MenuItem key={i} value={d.value}>{d.label}</MenuItem>
                            )
                          })}

                        </Select>
                      </FormControl>
                    </div>
                  </>
                  :
                  <>

                    <div className="col-md-4" style={{ marginTop: "6px" }}>

                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={[
                            'DateTimePicker',
                          ]}
                        >

                          <DemoItem>
                            <DateTimePicker slotProps={{ textField: { size: 'small' } }} label="Ride Start Date" onChange={setSelectedStartDate}
                              value={selectedStartDate} />
                          </DemoItem>

                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                    <div className="col-md-4" style={{ marginTop: "6px" }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={[
                            'DateTimePicker',
                          ]}
                        >

                          <DemoItem>
                            <DateTimePicker slotProps={{ textField: { size: 'small' } }} sx={{ marginTop: "6px" }} label="Ride End Date" onChange={setSelectedEndDate}
                              value={selectedEndDate} />
                          </DemoItem>

                        </DemoContainer>
                      </LocalizationProvider>

                    </div>
                    <div className="col-md-4">
                      <FormControl fullWidth className="mt-2">
                        <InputLabel id="car-type">Select Driver</InputLabel>
                        <Select
                          sx={{ marginTop: "6px" }}
                          size="small"
                          labelId="car-type"
                          value={selectedDriver}
                          label="Select Driver"
                          onChange={(e) => setSelectedDriver(e.target.value)}
                        >
                          <MenuItem value={null}>None</MenuItem>

                          {driverList.map((d, i) => {
                            return (
                              <MenuItem key={i} value={d.value}>{d.label}</MenuItem>
                            )
                          })}

                        </Select>
                      </FormControl>
                    </div>

                  </>

                }


              </div>

              <div className="d-flex mt-4 justify-content-center">
                <button
                  type="submit"
                  className="btn btn-gradient-primary btn-sm me-2"
                  onClick={()=>handleApplyButton()}
                disabled={false}
                >
                  Apply
                </button>
                <button
                  type="button"
                  className="btn btn-gradient-light btn-sm ml-4"
                onClick={()=>handleClearFilters()}
                // disabled={!Object.values(filterData).some((value) => value !== '') || isLoading}
                >
                  Clear Filters
                </button>
              </div>
            </div>
          </div>

          <DriverRidesCards appliedState={appliedState} paramData={paramData} ></DriverRidesCards>
        </>
      ) : <div></div>}


    </div>
  );
};

export default DriverRidesMainComp;