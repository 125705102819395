import React, { useEffect, useState } from 'react'
import './profile.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhone, faUserGear } from '@fortawesome/free-solid-svg-icons'
import faceIcon from '../../static/images/faces/face1.jpg'
import { CircularProgress, TextField } from '@mui/material'
import axios from 'axios'
import stockholmDate from '../cards/dateformatConvert'
import PhoneInput from 'react-phone-number-input'
import { toast } from 'react-toastify'
import MapInfoModal from '../map/MapInfoModal'
import { formatStops } from '../../shared/utils'

const UserProfileContainer = ({ userId }) => {
  const [data, setData] = useState(null)
  const sessionKey = localStorage.getItem("Key");
  const [IsLoading, setIsLoading] = useState(false);
  const [mapOpen, setMapOpen] = useState(false)
  const [rideInfo, setMapRideInfo] = useState({
    origin: null,
    destination: null,
    via: null
  })

  const handleUserDetails = async () => {
    setIsLoading(true);
    try {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}get-profile/${userId}`, {
          headers: {
            Authorization: "Bearer " + sessionKey,
          },
        })
        .then((response) => {
          setData(response.data.data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log("Error:", error.message);
        });
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    handleUserDetails();
  }, []);

  const handleMapOpen = (ride) => {
    setMapOpen(true)
    setMapRideInfo({ origin: ride.picup_location_name, destination: ride.drop_location_name, via:formatStops(ride.stops) })
  }


  return (

    <>
     <MapInfoModal isOpen={mapOpen} onClose={() => setMapOpen(false)} title="Map" origin={rideInfo.origin} destination={rideInfo.destination} via={rideInfo.via} />

      <div>
        <div class="d-sm-flex align-items-center justify-content-between mb-3">
          <h3 class="page-title">
            <span class="page-title-icon bg-gradient-primary text-white me-2">
              <i><FontAwesomeIcon icon={faUserGear} /></i>
            </span> Profile
          </h3>
        </div>
        {!data ?
          <div className="my-5 py-5 text-center">
            <CircularProgress />
          </div> :
          <div className="profile-grid">
            <div className="profile-grid-temp profile-div">
              <div className="card card-body shadow py-4">
                <div className="nav-profile-image text-center">
                  <img src={faceIcon} className='profile-img mb-2' alt="profile" />
                </div>
                <div className="nav-profile-text text-center d-flex flex-column">
                  <span className="font-weight-bold mb-1">{String(data.user.first_name).toUpperCase()} {String(data.user.last_name).toUpperCase()}</span>
                  <span className="text-secondary text-small">
                    User
                  </span>
                  <span className="text-secondary text-small">
                    {stockholmDate(data.user.date_joined)}
                  </span>
                  <span className="text-dark  mt-2 text-small">
                    <FontAwesomeIcon icon={faEnvelope} className='me-1' />{data.user.email}
                  </span>
                  <span className="text-dark text-small">
                    <FontAwesomeIcon icon={faPhone} className='me-1' />{data.user.phone}
                  </span>
                  <span className="text-secondary mt-2 text-small">
                    {data.user.address}
                  </span>
                </div>


              </div>
            </div>
            <div className="profile-grid-temp profile-info">
              <div className="">

                {
                  data.rides.map((ride, i) => {
                    return (
                      <div className="card shadow mb-3 grid-margin stretch-card" key={i}>
                        <div className="card-body">
                          <h5 className="mb-3"><strong>Ride {ride.id}</strong></h5>
                          <div className="row">
                            <div className="col-md-4">

                              <p><strong>From</strong><br /> <span className="text-dark">{ride.picup_location_name}</span></p>
                              <p><strong>To</strong><br />  <span className="text-dark">{ride.drop_location_name}</span></p>
                              <p><strong>Stops</strong><br /> <span className="text-dark">{ride.stops &&
                                ride.stops.length !== 0  ? (
                                <span>{formatStops(ride.stops)}</span>
                              ) : (
                                <span>No Stop</span>
                              )}</span></p>
                              <p><strong>Booking Date & Time</strong><br />  <span className="text-dark">{stockholmDate(ride.ride_date)}</span></p>
                              <p><strong>Ride Date & Time</strong><br /> <span className="text-dark">{stockholmDate(ride.created_date)}</span></p>
                            </div>
                            <div className="col-md-4">
                              <p><strong>Rider Name</strong><br />  <span className="text-dark">{ride.ridername}</span></p>
                              <p><strong>Rider Phone</strong><br /> <span className="text-dark">{ride.riderphone}</span></p>
                              <p><strong>Fare</strong><br /> <span className="text-dark">{ride.fare} S.E.K</span></p>
                              <p><strong>Distance</strong><br />  <span className="text-dark">{ride.distance} KM</span></p>
                              <p><strong>Company Name</strong><br />  <span className="text-dark">{ride.company_name
                                ? ride.company_name
                                : "Company Not Assigned"}</span></p>

                            </div>
                            <div className="col-md-4">
                              <p><strong>Car Type</strong><br />  <span className="text-dark">{ride.car_type}</span></p>
                              <p><strong>Car Model</strong><br /> <span className="text-dark">{ride.car_model ? ride.car_model : "Car Not Assigned"}</span></p>
                              <p><strong>Car Registration Number</strong><br /> <span className="text-dark">{ride.car_registration_number
                                ? ride.car_registration_number
                                : "Car Not Assigned"}</span></p>
                              <p><strong>Driver Name</strong><br /> <span className="text-dark">{ride.driver_name
                                ? ride.driver_name
                                : "Driver Not Assigned"}</span></p>
                              <p><strong>Driver Number</strong><br /> <span className="text-dark">  {ride.driver_phone
                                ? ride.driver_phone
                                : "Driver Not Assigned"}</span></p>
                            </div>
                          </div>
                          <div className="mt-3">



                            <button
                              className="btn btn-gradient-info btn-sm mx-2 my-1"
                              onClick={() => handleMapOpen(ride)}
                            >
                              Route
                            </button>

                            <span
                              className="btn btn-gradient-primary btn-sm mx-2 my-1"
                              style={{ cursor: "none" }}
                            >
                              {ride.status}
                            </span>



                          </div>
                        </div>
                      </div>
                    )
                  })
                }

              </div>
            </div>
          </div>
        }

      </div>
    </>
  )
}

export default UserProfileContainer