import React, { useEffect, useState } from "react";
import LineChartComp from "../../../charts/linechart";
import axios from "axios";
import PieChartComp from "../../../charts/piechart";
import CircularProgress from "@mui/material/CircularProgress";
// import Select from "react-select";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Barchart from "../../../charts/barchart";
import './dashboard.css'


const DashboardMidComp = () => {
  const userType = localStorage.getItem("A_usertype");
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [companyData, setCompanyData] = useState([]);
  const [year, setYear] = useState("None");
  const [disPYear, setdisPYear] = useState(String(new Date().getFullYear()));
  const [companyId, setCompanyId] = useState("All Data");
  const today = dayjs();
  const twelveMonthsAgo = today.subtract(12, 'month');
  const [endDate, setEndDate] = React.useState(today);
  const [startDate, setStartDate] = React.useState(twelveMonthsAgo);

  const [minEndDate, setMinEndDate] = React.useState(startDate.add(1, 'month'));


  const ci = localStorage.getItem("A_companyinfo");
  let ccid = null;
  if (ci !== "undefined" && ci !== "null") {
    ccid = JSON.parse(ci.replace(/\\/g, "")).company_id;
  }

  const startYear = 2023; // Specify the starting year
  const currentYear = new Date().getFullYear(); // Get the current year

  const yearList = Array.from(
    { length: currentYear - startYear + 1 },
    (_, index) => ({
      label: String(startYear + index),
      id: index + 1,
      name: String(startYear + index),
    })
  );

  const accessToken = localStorage.getItem("Key");

  const handleStartDate = (e) => {
    setStartDate(e)
    const nextMonth = e.add(1, 'month');
    setMinEndDate(nextMonth);
  }
  const handleEndDate = (e) => {
    setEndDate(e)
  }


  // To Get Compnay Data
  const handleGetCompanyData = async () => {
    setIsLoading(true);
    try {
      const resp = await axios
        .get(`${process.env.REACT_APP_BASE_URL}all-company/`, {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        })
        .then((response) => {
          setCompanyData(response.data);
          setIsLoading(false);
          return response.data;
        });
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };
  const getChartData = async () => {
    setIsLoading(true);
    try {
      const startDateObj = new Date(startDate)
      const startDateStr = `${startDateObj.getMonth() + 1}-${startDateObj.getFullYear()}`
      const endDateObj = new Date(endDate)
      const endDateStr = `${endDateObj.getMonth() + 1}-${endDateObj.getFullYear()}`

      let param = "";
      if (userType === "ADMIN_USER") {
        if (companyId !== "All Data") {
          param = `?company_id=${companyId}&start_date=${startDateStr}&end_date=${endDateStr}`;
        } else {
          param = `?start_date=${startDateStr}&end_date=${endDateStr}`;
        }
      } else {
        param = `?company_id=${ccid}&start_date=${startDateStr}&end_date=${endDateStr}`
      }

      const url = `${process.env.REACT_APP_BASE_URL}revenue-vs-time/${param}`;
      const resp = await axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        })
        .then((response) => {
          setData(response.data);
          setIsLoading(false);
          return response.data;
        });
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getChartData();
  }, [companyId, startDate, endDate]);

  useEffect(() => {
    if (userType === "ADMIN_USER") {
      handleGetCompanyData();
    }
  }, []);

  const notifications = JSON.parse(sessionStorage.getItem('notifications', []))

  return (
    <div class="row">
      <div class="col-md-9 grid-margin stretch-card">
        <div class="card">
          <div className="card-title">
            <h4 className="m-3 p-3 mb-0 pb-0"> Rides Earning Report &nbsp;
              {`(${new Date(startDate).getMonth() + 1}-${new Date(startDate).getFullYear()} to ${new Date(endDate).getMonth() + 1}-${new Date(endDate).getFullYear()})`}</h4>
          </div>
          <div class="card-body">
            <div className="select-data mb-3">
              <div className="row">
                <div className="form-group col-md-4 my-1">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer sx={{ paddingTop: "0" }} components={['DatePicker']}>

                      <DatePicker
                        slotProps={{ textField: { size: 'small' } }}
                        sx={{ paddingTop: 0 }}
                        views={['month', 'year']}
                        value={startDate}
                        onChange={(e) => handleStartDate(e)}
                      />

                    </DemoContainer>
                  </LocalizationProvider>
                </div>

                <div className="form-group col-md-4 my-1">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer sx={{ paddingTop: "0" }} components={['DatePicker']}>
                      <DatePicker
                        slotProps={{ textField: { size: 'small' } }}
                        sx={{ paddingTop: 0 }}
                        views={['month', 'year']}
                        value={endDate}
                        minDate={minEndDate}
                        onChange={(e) => handleEndDate(e)}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
                {userType === "ADMIN_USER" ? (
                  <div className="col-md-4 my-1">
                    <FormControl fullWidth>
                      <InputLabel id="demo-select-label">Company</InputLabel>
                      <Select
                        labelId="demo-select-label"
                        size="small"
                        id="demo-select-small"
                        value={companyId}
                        label="Company"
                        onChange={(e) => setCompanyId(e.target.value)}
                      >
                        <MenuItem value={"All Data"}>All Data</MenuItem>
                        {companyData.map((el, index) => {
                          return <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                        })}

                      </Select>
                    </FormControl>
                  </div>
                ) : null}
              </div>
            </div>
            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: 20,
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              // <LineChartComp data={data}></LineChartComp>
              <Barchart data={data}></Barchart>
            )}

          </div>
        </div>
      </div>
      <div class="col-md-3 grid-margin stretch-card">
        <div class="card">
          <div className="header" style={{ margin: "1rem 1rem" }}>
            <h4 className="mb-0 text-center">Notifications</h4>
          </div>
          <div class="card-body m-0 px-3 py-0">
            {/* <h4 class="card-title"> Revenue - {disPYear}</h4> */}
            {/* <PieChartComp data={data}></PieChartComp> */}

            {notifications && notifications.length !== 0 ?
              <>
                {notifications.slice(0, 4).map((d, index) => {
                  return <>
                    <div id="traffic-chart-legend" class="rounded-legend legend-vertical legend-bottom-left pb-2" key={index}>{d.info}</div>
                    <div className="dropdown-divider"></div>
                  </>
                })}
              </>
              :
              <div id="traffic-chart-legend" class="rounded-legend legend-vertical legend-bottom-left py-3 text-center">No Notifications</div>}

          </div>
        </div>
      </div>

    </div>
  );
};

export default DashboardMidComp;
