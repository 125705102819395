import React from "react";

const PreviousData = (props) => {
  console.log(props, "props");

  return (
    <div>
      {props.carOrEmployeeData !== null ? (
        props.reportData.created_for.type === "car" ? (
          <div>
            <div style={{ display: "flex" }} className="mb-3">
              <span>Car Model</span>:&nbsp;
              <span className="text-gray">{props.carOrEmployeeData.car_model}</span>
            </div>

            <div style={{ display: "flex" }} className="mb-3">
              <span >Car Type</span>:&nbsp;
              <span className="text-gray">
                <div>
                  {props.carOrEmployeeData.car_type.map(
                    (elem, i) => {
                      return (
                        <span>
                          {elem}
                          {i !==
                            props.carOrEmployeeData.car_type.length -
                              1 && ", "}
                        </span>
                      );
                    }
                  )}
                </div>
              </span>
            </div>
            <div style={{ display: "flex" }} className="mb-3">
              <span>Car Registration Number</span>:&nbsp;
              <span className="text-gray">{props.carOrEmployeeData.registration_number}</span>
            </div>
            <div style={{ display: "flex" }} className="mb-3">
              <span>Car Registration Documents</span>:&nbsp;
              {props.carOrEmployeeData.added_document_urls.length !== 0 ? (
                <div className="text-gray row">
                  {props.carOrEmployeeData.added_document_urls.map((url, i) => {
                    return url ? (
                      <div className="col-12" key={i}>
                        <a
                          href={url.document_url}
                          className="fa fa-download"
                          target="_blank"
                          style={{ cursor: "pointer" }}
                        >
                          View Document {i + 1}
                        </a>
                      </div>
                    ) : null;
                  })}
                </div>
              ) : (
                <div className="text-gray">No Documents</div>
              )}
            </div>
          </div>
        ) : (
            <div>
            <div style={{ display: "flex" }} className="mb-3">
              <span>Employee Email</span>:&nbsp;
              <span className="text-gray">{props.carOrEmployeeData.employee_email}</span>
            </div>
            <div style={{ display: "flex" }} className="mb-3">
              <span>Employee Name</span>:&nbsp;
              <span className="text-gray">
                {String(
                  props.carOrEmployeeData.employee_name
                ).toUpperCase()}
              </span>
            </div>
            <div style={{ display: "flex" }} className="mb-3">
              <span>Employee Phone</span>:&nbsp;
              <span className="text-gray">{props.carOrEmployeeData.employee_phone}</span>
            </div>
            <div style={{ display: "flex" }} className="mb-3">
              <span>Employee Address</span>:&nbsp;
              <span className="text-gray">{props.carOrEmployeeData.employee_address}</span>
            </div>
            <div style={{ display: "flex" }} className="mb-3">
              <span>Employee Type</span>:&nbsp;
              <span className="text-gray">{props.reportData.created_for.type}</span>
            </div>
            {props.carOrEmployeeData.driver_licence_number !== null?
              <div style={{ display: "flex" }} className="mb-3">
                <span>Licence Number</span>:&nbsp;
                <span className="text-gray">
                 {props.carOrEmployeeData.driver_licence_number}
                </span>
              </div>
              :null}
            <div style={{ display: "flex" }} className="mb-3">
              <span>Employee Registration Documents</span>:&nbsp;
              {props.carOrEmployeeData.added_document_urls.length !== 0 ? (
                <div className="text-gray row">
                  {props.carOrEmployeeData.added_document_urls.map((url, i) => {
                    return url ? (
                      <div className="col-12" key={i}>
                        <a
                          href={url.document_url}
                          className="fa fa-download"
                          target="_blank"
                          style={{ cursor: "pointer" }}
                        >
                          View Document {i + 1}
                        </a>
                      </div>
                    ) : null;
                  })}
                </div>
              ) : (
                <div className="text-gray">No Documents</div>
              )}
            </div>
            
          </div>
        )
      ) : (
        <div>No Data</div>
      )}
    </div>
  );
};

export default PreviousData;
