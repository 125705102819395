import React from "react";
import AllDataCommonComp from "../common/common";
import CompanyCards from "../../../cards/company/companycard";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";

const AllCompanyMainComp=()=>{
    return(
        <>
            <AllDataCommonComp allemployee="All Company" addUrl="/dashboard/add-company"  headerIcon={faBuilding}  downloadCall="company"></AllDataCommonComp>
            <CompanyCards></CompanyCards>
        </>
        
    )
}

export default AllCompanyMainComp;