import React, { createContext, useEffect, useState } from "react";
// import "../static/css/sb-admin-2.css";
// import "../static/css/sb-admin-2.min.css";
import AppRoutes from "./App.routes";
import { baseSocketURL } from "../config";
import Forbidden from "../components/notFound/Forbidden";
import { useLocation } from "react-router-dom";

export const WebSocketContext = createContext(null);

function App() {
  const [notificationSocket, setnotificationSocket] = useState(null);
  const [sideBarActive, setSideBarActive] = useState(false);
  const userId = localStorage.getItem("A_userid");
  const location = useLocation();
  useEffect(() => {
    const unlisten = () => {
      setSideBarActive(false);
    };
    return unlisten;
  }, [location]);
  
const usertype = localStorage.getItem('A_usertype')

  useEffect(() => {
    if (userId && usertype !== "END_USER") {
      // Initialize the WebSocket connection when the component mounts
      const ws = new WebSocket(`${baseSocketURL}/ws/entity_listener/${userId}`);

      ws.onopen = () => {
        console.log("Notification WebSocket connected");
        setnotificationSocket(ws);
      };

      // Cleanup logic
      return () => {
        if (ws) {
          ws.close();
          console.log("Notification WebSocket disconnected");
        }
      };
    }
  }, [userId]);
  const contextValue = {notificationSocket, setSideBarActive, sideBarActive}
  return (
    <>
    {usertype !== "END_USER"? <WebSocketContext.Provider value={contextValue}>
        <AppRoutes />
      </WebSocketContext.Provider>: <Forbidden />}
      
    </>
  );
}

export default App;
