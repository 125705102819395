import React from "react";
import AllDataCommonComp from "../common/common";
import RidesCards from "../../../cards/rides/ridescard";
import MyridesCard from "../../../cards/rides/myrides";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
// import { selectUserType } from "../../../redux/slicer";

// import { useSelector } from "react-redux";
const AllRidesMainComp = () => {
  // const userType = useSelector(selectUserType)
  const userType = localStorage.getItem("A_usertype");

  return (
    <>
      {userType === "COMPANY_USER" ? (
        <>
          <AllDataCommonComp
            allemployee="My Rides"
            downloadCall="myride"
            addUrl="/dashboard/add-ride"
            headerIcon={faCalendar}
          ></AllDataCommonComp>
          <MyridesCard></MyridesCard>
        </>
      ) : (
        <>
          <AllDataCommonComp
            allemployee="All Rides"
            downloadCall="ride"
            addUrl="/dashboard/add-ride"
            headerIcon={faCalendar}
          ></AllDataCommonComp>
          <RidesCards></RidesCards>
        </>
      )}
    </>
  );
};

export default AllRidesMainComp;
