import React, { useState, useEffect } from "react";
import AllDataCommonComp from "../common/common";
import CommonRide from "../common/CommonRide";
import { Button, ButtonGroup, TextField } from "@mui/material";
import Flatpickr from "react-flatpickr";
import { useParams } from "react-router-dom";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import PaginationComponent from "../../../cards/PaginationComponent";
import stockholmDate from "../../../cards/dateformatConvert";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatStops } from "../../../../shared/utils";

const DriverListCompo = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isFilter, setisFilter] = useState(false);
  const [selectedDates, setSelectedDates] = useState([]);
  const [filterData, setFilterData] = useState({
    driver_data: { results: [] },
  });
  const [driversData, setDriversData] = useState({
    driver_data: { results: [] },
  });

  const [driverDetails, setDriverDetails] = useState({
    total_ride: 0,
    earning: 0,
    driver_name: "",
    company_name: "",
  });
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const { driverId, companyId } = useParams();
  const userType = localStorage.getItem("A_usertype");
  const [IsLoading, setIsLoading] = useState(false);
  const [isDriverDetails, setisDriverDetails] = useState(false);

  const [activeDateFilter, setActiveDateFilter] = useState("today");
  const handleDateFilterClick = (filterValue) => {
    setActiveDateFilter(filterValue);
  };
  const handleFilterSubmit = () => {
    setIsLoading(true);
    const startDate = new Date(selectedDates[0]);
    const endDate = new Date(selectedDates[1]);
    setisFilter(true);
    handleDriverEarning().then(() => {
      // console.log(startDate>endDate)
      const filteredResults = driversData.driver_data.results.filter((item) => {
        const itemDate = new Date(item.datetime);
        // console.log(itemDate>=startDate ,itemDate <= endDate, itemDate, startDate ,endDate)
        return itemDate >= startDate && itemDate <= endDate;
      });
      setFilterData((prev) => ({
        driver_data: { ...prev.driver_data, results: filteredResults },
      }));
    });

    setIsLoading(false);
  };
  const handleDateChange = (dates) => {
    setSelectedDates(dates);
  };

  const handleDriverDetails = async () => {
    setIsLoading(true);
    try {
      const accessToken = localStorage.getItem("Key");
      const resp = await axios
        .get(
          `${process.env.REACT_APP_BASE_URL}company-drivers-data-count/${driverId}/${companyId}`,
          {
            headers: {
              Authorization: "Bearer " + accessToken,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          setDriverDetails(response.data);
        });
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };
  const handleDriverEarning = async () => {
    setIsLoading(true);
    try {
      const accessToken = localStorage.getItem("Key");
      console.log("acce");
      if (isFilter) {
        const resp = await axios
          .get(
            `${process.env.REACT_APP_BASE_URL}company-drivers-data/${driverId}/${companyId}`,
            {
              headers: {
                Authorization: "Bearer " + accessToken,
              },
            }
          )
          .then((response) => {
            console.log(response.data, "filter");
            setFilterData((prev) => ({
              driver_data: {
                ...prev.driver_data,
                results: response.data.driver_data,
              },
            }));
          });
      } else {
        const resp = await axios
          .get(
            `${process.env.REACT_APP_BASE_URL}company-drivers-data/${driverId}/${companyId}?paginate=true&page=${currentPage}`,
            {
              headers: {
                Authorization: "Bearer " + accessToken,
              },
            }
          )
          .then((response) => {
            console.log(response.data);
            setDriversData(response.data);
            setFilterData(response.data);
          });
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    handleDriverEarning();
  }, [currentPage]);
  useEffect(() => {
    handleDriverDetails();
  }, [isDriverDetails]);

  return (
    <>
      <div class="d-sm-flex align-items-center justify-content-between mb-3">
        <h3 class="page-title">
          <span class="page-title-icon bg-gradient-primary text-white me-2">
            <i><FontAwesomeIcon icon={faBuilding} /></i>
          </span> Driver Earning
        </h3>
      </div>

      <CommonRide
        company={driverDetails.company_name}
        rides={driverDetails.total_ride}
        earning={driverDetails.earning}
        driver={driverDetails.driver_name}
      />


      <div className="my-3">
        <div className="d-flex justify-content-start ">
          <Flatpickr
          className="form-control me-3"
            data-enable-time
            style={{ width: "250px", background: "#fff" }}
            value={selectedDates}
            onChange={handleDateChange}
            placeholder="Select date range"
            options={{
              dateFormat: "Y-m-d",
              mode: "range",
            }}
          />
          <button className="btn btn-sm btn-gradient-primary" onClick={handleFilterSubmit}>Filter</button>
        </div>
      </div>
      {IsLoading ?
        <div className="text-center my-5 py-5">
          <CircularProgress />
        </div>
        :

        <>
          {
            filterData.driver_data.results.length != 0 ? (
              filterData.driver_data.results.map((ride, i) => {
                return (
                  < div className="card shadow mb-3 grid-margin stretch-card" key={i}>
                  <div className="card-body">
                    <h5 className="mb-3"><strong>Ride {ride.id}</strong></h5>
                    <div className="row">
                      <div className="col-md-4">

                        <p><strong>From</strong><br /> <span className="text-dark">{ride.picup_location_name}</span></p>
                        <p><strong>To</strong><br />  <span className="text-dark">{ride.drop_location_name}</span></p>
                        <p><strong>Stops</strong><br /> <span className="text-dark">{ride.stops &&
                          ride.stops.length !== 0 ? (
                          <span>{formatStops(ride.stops)}</span>
                        ) : (
                          <span>No Stop</span>
                        )}</span></p>
                        <p><strong>Ride Date & Time</strong><br /> <span className="text-dark">{stockholmDate(ride.created_date)}</span></p>
                      </div>
                      <div className="col-md-4">
                        <p><strong>Rider Name</strong><br />  <span className="text-dark">{ride.ridername}</span></p>
                        <p><strong>Rider Phone</strong><br /> <span className="text-dark">{ride.riderphone}</span></p>
                        <p><strong>Earning</strong><br /> <span className="text-dark">{ride.fare} S.E.K</span></p>
                        <p><strong>Distance</strong><br />  <span className="text-dark">{ride.distance} KM</span></p>
                       
                      </div>
                      <div className="col-md-4">
                        <p><strong>Car Type</strong><br />  <span className="text-dark">{ride.car_type}</span></p>
                        <p><strong>Car Model</strong><br /> <span className="text-dark">{ride.car_assigned__car_model}</span></p>
                        <p><strong>Car Registration</strong><br /> <span className="text-dark">{ride.car_assigned__registration_number}</span></p>
                        
                      </div>

                    </div>

                  </div>
                </div >
                )
              })) : <div className="card card-body w-100 py-5 text-center">
                No Data
              </div>
          }
        </>

      }


      <div className="my-3">
        <PaginationComponent
          currentPage={currentPage}
          totalPages={driversData.driver_data.total_pages}
          onPageChange={handlePageChange}
        />
      </div>

    </>

  );
};

export default DriverListCompo;
