import React, { useState, useEffect } from "react";
import axios from "axios";
import { selectCompanyInfo } from "../redux/slicer";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import { Link, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "../../static/css/phonestyle.css";
import { faArrowLeft, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";


const AddEmployeeForm = () => {
  const nav = useNavigate();
  const [companyId, setCompanyId] = useState(null);
  const [employeeName, setEmployeeName] = useState("");
  const [employeeEmail, setEmployeeEmail] = useState("");
  const [employeePhone, setEmployeePhone] = useState("");
  const [employeeAddress, setEmployeeAddress] = useState("");
  const [employeeDocumentsFiles, setEmployeeDocumentsFiles] = useState([]);
  const [employeeType, setEmployeeType] = useState(null);
  const [isCompanyData, setIsCompanyData] = useState(false);
  const [driverL, setDriverL] = useState(null);
  const usertype = localStorage.getItem("A_usertype");
  const ci = localStorage.getItem("A_companyinfo");
  let ccid = null;
  let empid = null;
  if (ci !== "undefined" && ci !== "null") {
    ccid = JSON.parse(ci.replace(/\\/g, "")).company_id;
    empid = JSON.parse(ci.replace(/\\/g, "")).employee_id;
  }
  console.log("cid", ccid);
  const [companyLabel, setCompanyLabel] = useState([
    { id: "n/a", name: "n/a", label: "n/a" },
  ]);
  const [messege, setMessege] = useState("");
  const accessToken = localStorage.getItem("Key");
  const [companyData, setCompanyData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [toggleBtn, settoggleBtn] = useState(false);
  const employeeTypeOptions = [
    // { value: "Staff", label: "Staff" },
    { value: "Manager", label: "Manager" },
    { value: "Driver", label: "Driver" },
    // { value: "Owner", label: "Owner" },
  ];
  const handleFileInput = (e) => {
    const selectedFiles = e.target.files;
    setEmployeeDocumentsFiles(selectedFiles);
  };

  // To Get User Data
  const splitFullName = (fullName) => {
    const nameParts = fullName.trim().split(" ");
    if (nameParts.length === 1) {
      return {
        firstName: fullName,
        lastName: "",
      };
    }
    const lastName = nameParts.pop();
    const firstName = nameParts.join(" ");

    return {
      firstName: firstName,
      lastName: lastName,
    };
  };

  // To Get Compnay Data
  const handleGetCompanyData = async () => {
    setIsLoading(true);
    try {
      const resp = await axios
        .get(`${process.env.REACT_APP_BASE_URL}all-company/`, {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        })
        .then((response) => {
          setCompanyData(response.data);
          setIsCompanyData(true);
          setIsLoading(false);
          return response.data;
        });
    } catch (error) {
      setMessege("Somthing went wrong not able to fetch user data");
      setIsCompanyData(false);
      console.log(error);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    if (usertype === "ADMIN_USER") {
      handleGetCompanyData();
    }
  }, []);

  useEffect(() => {
    if (companyData.length > 0) {
      const company_list = companyData.map((el, i) => {
        return {
          label: el.name,
          id: el.id,
          name: el.name,
        };
      });
      setCompanyLabel(company_list);
    }
  }, [isCompanyData]);

  // To create Employee Account
  const handleAddEmployee = async (e) => {
    e.preventDefault();
    setMessege("");
    if (usertype === "ADMIN_USER") {
      if (companyId === null) {
        toast("Company Must not be Blank");
        return;
      }
    }

    if (employeeName.length < 4) {
      toast("Employee name must be 4 characters long");
      return;
    }
    if (employeePhone === "") {
      if (employeePhone.length < 13 || !employeePhone.startsWith("+")) {
        toast("Phone number is not valid or must start with country code");
        return;
      }
    }
    if (employeeAddress === "") {
      toast("Address Must not be Blank");
      return;
    }
    if (employeeDocumentsFiles.length === 0) {
      toast("Please upload file");
      return;
    }
    if (employeeType === null) {
      toast("Employee Type Must not be Blank");
      return;
    }
    if (employeeType === "Driver" && driverL == null) {
      toast("Driver Licence Must not be Blank");
      return;
    }
    try {
      setIsLoading(true);
      let data = new FormData();
      data.append("employee_name", employeeName);
      data.append("employee_email", employeeEmail);
      data.append("employee_phone", employeePhone);
      data.append("employee_type", employeeType);
      data.append("employee_address", employeeAddress);
      data.append("driver_licence_number", driverL);
      for (let i = 0; i < employeeDocumentsFiles.length; i++) {
        data.append("added_documents", employeeDocumentsFiles[i]);
      }
      if (usertype === "ADMIN_USER") {
        let fullname = splitFullName(employeeName);
        const udata = {
          phone: employeePhone,
          first_name: fullname.firstName,
          last_name: fullname.lastName,
          user_type: "COMPANY_USER",
          email: employeeEmail,
        };
        const uresp = await axios
          .post(`${process.env.REACT_APP_BASE_URL}signup/`, udata, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            data.append("user_id", parseInt(response.data.data.user_id));
            data.append("company_id", parseInt(companyId));
            return axios.post(
              `${process.env.REACT_APP_BASE_URL}create-employee/`,
              data,
              {
                headers: {
                  Authorization: "Bearer " + accessToken,
                  "Content-Type": "multipart/form-data",
                },
              }
            );
          })
          .then((response) => {
            setIsLoading(false);
            toast("Employee is Created");
            return response.data;
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                toast("User with this phone number is inactive.")
                setIsLoading(false);
                return;
              }
              if (error.response.status === 400 && error.response.data.is_exists == "true") {
                toast("User with this phone number already exists.")
                setIsLoading(false);
                return;
              }
            }

          })
      } else {
        const udata = {
          phone: employeePhone
        }
        const uresp = await axios
          .post(`${process.env.REACT_APP_BASE_URL}get-user/`, udata, {
            headers: {
              "Content-Type": "application/json",
            },
          }).then((res)=>{
            console.log(!res.data.is_exists)
            if(res.data.is_exists){
              toast("User with this phone number is inactive.")
              return null;
            }
            data.append("company_id", ccid);
            data.append("created_by", empid);
            data.append("type", employeeType);
            data.append("send_type", "add");
            return axios
              .post(`${process.env.REACT_APP_BASE_URL}create-report-firebase/`, data, {
                headers: {
                  Authorization: "Bearer " + accessToken,
                  "Content-Type": "multipart/form-data",
                },
              })
          })
          .then((response) => {
            if(response){
              setIsLoading(false);
              toast("REQUEST SENT TO ADMIN");
              return response.data;
            }else{
              setIsLoading(false);
              return;
            }
            
          });
      }

      setEmployeePhone("");
      nav("/dashboard/add-employee");
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  const updateInput = () => {
    if (employeeType === "Driver") {
      settoggleBtn(true);
    } else {
      settoggleBtn(false);
    }
  };
  useEffect(() => {
    updateInput();
  }, [employeeType]);

  return (
    <>
      <div class="d-sm-flex align-items-center justify-content-between my-3">
        <h3 class="page-title">
          <span class="page-title-icon bg-gradient-primary text-white me-2">
            <i><FontAwesomeIcon icon={faUser} /></i>
          </span> ADD EMPLOYEE
        </h3>
        <div className="d-flex justify-content-end align-items-center">
          <Link className="p-2 rounded bg-gradient-success text-white me-2 shadow-sm" to="/dashboard/all-drivers" style={{ cursor: "pointer" }}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </Link>
        </div>
      </div>
      <div class="col-12">
        <div class="card shadow" style={{ padding: "1rem" }}>
          <div class="card-body">
            {isLoading ? (
              <div
                className="mt-3"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: 20,
                }}
              >
                <CircularProgress />
              </div>
            ) :
              <form class="form-sample" onSubmit={(e) => handleAddEmployee(e)}>
                <div class="row mb-3">
                  <div class="col-md-6">
                    <div class=" mb-0 p-1 row">
                      <TextField value={employeeName}
                        onChange={(e) => setEmployeeName(e.target.value)}
                        type="text"
                        label="Employee Name" />

                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-0 p-1 row">
                      <TextField value={employeeEmail}

                        onChange={(e) => setEmployeeEmail(e.target.value)}
                        type="email"
                        label="Employee Email" />
                    </div>
                  </div>
                </div>


                <div class="row mb-3">
                  <div class="col-md-6">
                    <div class=" mb-0 p-1 row">
                      <TextField value={employeeAddress}

                        onChange={(e) => setEmployeeAddress(e.target.value)}
                        type="text"
                        label="Employee Address" />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-0 p-1 row">
                      <PhoneInput
                        className="form-control"
                        style={{ height: "3.5rem" }}
                        defaultCountry="SE"
                        value={employeePhone}
                        onChange={setEmployeePhone}
                        limitMaxLength={true}
                        placeholder={"Employee Number"}
                      />
                    </div>
                  </div>
                </div>


                <div class="row mb-3">
                  <div class="col-md-6">
                    <div class=" mb-0 p-1 row">

                      <FormControl fullWidth>
                        <InputLabel id="emp-type">Employee Type</InputLabel>
                        <Select
                          labelId="emp-type"
                          value={employeeType}
                          label="Employee Type"
                          onChange={(e) => setEmployeeType(e.target.value)}
                        >

                          {employeeTypeOptions.map((d, i) => {
                            return (
                              <MenuItem key={i} value={d.value}>{d.label}</MenuItem>
                            )
                          })}

                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-0 p-1 row">

                      {toggleBtn === true &&
                        <TextField value={driverL}
                          onChange={(e) => setDriverL(e.target.value)}
                          type="text"
                          label="Driver License" />
                      }
                    </div>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-md-6">
                    <div class=" mb-0 p-1 row">

                      <input
                        type="file"
                        className="form-control"
                        onChange={handleFileInput}
                        multiple
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    {
                      usertype === "ADMIN_USER" && <div class="mb-0 p-1 row">

                        <FormControl fullWidth>
                          <InputLabel id="cmp-type">Select Company</InputLabel>
                          <Select
                            labelId="cmp-type"
                            value={companyId}
                            label="Select Compnay"
                            onChange={(e) => setCompanyId(e.target.value)}
                          >

                            {companyLabel.map((d, i) => {
                              return (
                                <MenuItem key={i} value={d.id}>{d.label}</MenuItem>
                              )
                            })}

                          </Select>
                        </FormControl>


                      </div>
                    }
                  </div>
                </div>






                <div className="d-flex justify-content-center mt-5">
                  <button type="submit" class="btn btn-gradient-primary">Submit</button>
                </div>

              </form>
            }

          </div>
        </div>
      </div >
    </>

  );
};

export default AddEmployeeForm;
