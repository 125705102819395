import React from "react";
import SidebarComp from "../../components/sidebar/sidebar";
import NavbarComp from "../../components/navbar/navbar";
import EditCompanyMainComp from "../../components/body/editcomponent/editcompany/editcompanycomp";

const EditCompanyScreen=()=>{
    return(
        <div className="container-scroller">
            <NavbarComp />
            <div className="container-fluid page-body-wrapper">
                <SidebarComp />
                <div className="main-panel">
                    <div className="content-wrapper">
                        <EditCompanyMainComp />
                    </div>
                </div>
            </div>
        </div>
        
    )
}

export default EditCompanyScreen;