import React from "react";
import AllDataCommonComp from "../common/common";
import CarCards from "../../../cards/car/carcards";
import { faCab } from "@fortawesome/free-solid-svg-icons";

const AllCarMainComp=()=>{
    return(
        <>
            <AllDataCommonComp addUrl="/dashboard/add-car"  headerIcon={faCab} allemployee="All Cars"  downloadCall="cars"></AllDataCommonComp>
            <CarCards></CarCards>
        </>
        
    )
}

export default AllCarMainComp;