export const queryParams = (data) => {
    if (data.start_date) {
        const startDate = new Date(data.start_date);
        data.start_date = startDate.toISOString().slice(0, 19).replace('T', ' ');
    }
    if (data.end_date) {
        const endDate = new Date(data.end_date);
        data.end_date = endDate.toISOString().slice(0, 19).replace('T', ' ');
    }
    if (data.booking_date) {
        const bookingDate = new Date(data.booking_date);
        data.booking_date = bookingDate.toISOString().slice(0, 19).replace('T', ' ');
    }

    return Object.entries(data)
        .filter(([key, value]) => value !== "")
        .map(([key, value]) => {
            return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        }).join("&");
};

export const calculateRidePrice = (carType, numberOfKm, numberOfMinutes, isChild, isPet, isLuggage) => {
    let pricePerKm = 0;
    let pricePerMinute = 0;

    switch (carType) {
        case "Trixi":
            pricePerKm = 12;
            pricePerMinute = 7;
            break;
        case "Electric":
            pricePerKm = 15;
            pricePerMinute = 10;
            break;
        case "Premium":
            pricePerKm = 20;
            pricePerMinute = 15;
            break;
        case "XL":
            pricePerKm = 24;
            pricePerMinute = 14;
            break;
        default:
            console.log("Invalid Car Type: ", carType)
    }

    const totalKmPrice = numberOfKm * pricePerKm;
    const totalMinutesPrice = numberOfMinutes * pricePerMinute;

    let fairCharges = totalKmPrice + totalMinutesPrice;
    if (isChild) fairCharges += 100;
    if (isPet) fairCharges += 50;
    if (isLuggage) fairCharges += 50;

    return fairCharges.toFixed(2);
};


export const formatStops = (stops) =>{
    const stopsNameArray = stops.map((elem, index)=>{
        return elem.location_name
    })
    return stopsNameArray.join("-")
}