import React from 'react'
import NavbarComp from '../../components/navbar/navbar'
import SidebarComp from '../../components/sidebar/sidebar'
import Notification from '../../components/cards/notifications/notification'
import NotificationSidebar from '../../components/cards/notifications/notificationSidebar'

const NotificationScreen = () => {
  return (
    <div className="container-scroller">
    <NavbarComp />
    <div className="container-fluid page-body-wrapper">
        <NotificationSidebar />
        <div className="main-panel">
            <div className="content-wrapper">
                <Notification />
            </div>
        </div>
    </div>
</div>
  )
}

export default NotificationScreen