import React from 'react'
import SidebarComp from "../../../sidebar/sidebar";
import NavbarComp from "../../../../components/navbar/navbar";
import CompnyEarningList from './CompanyListCompo';
const CompanyEarning = () => {
  return (

    <div className="container-scroller">
    <NavbarComp />
    <div className="container-fluid page-body-wrapper">
        <SidebarComp />
        <div className="main-panel">
            <div className="content-wrapper">
            <CompnyEarningList></CompnyEarningList>
            </div>
        </div>
    </div>
</div>

  )
}

export default CompanyEarning