import React from "react"
import stockholmDate from "../../cards/dateformatConvert"
import './invoice.css'
import { formatStops } from "../../../shared/utils"

const InvoiceTemplate = ({ data }) => {
    return (
        <div className="card container m-0 p-0">
            <div className="card-body m-0 p-0">

                <div className="row mb-3">
                    <div className="col md-12">
                        <div className="d-flex justify-content-between align-items-center">
                            <h1 class="text-primary" style={{ fontWeight: 800, fontSize: "3rem" }}>Trixi Taxi</h1>
                            <h2><strong>INVOICE</strong></h2>
                        </div>
                    </div>
                </div>

                <div className="mb-5 address-info">
                    <div>
                        <div><strong>From:</strong></div>
                        <div style={{ width: "75%" }}>
                            <p className="m-0" ><strong>{data.company.name}</strong>,</p>
                            <p className="m-0">{data.company.address},</p>
                            <p className="m-0">Phone: {data.company.phone_number},</p>
                            <p className="m-0">Email: {data.company.organization_email}</p>
                        </div>
                    </div>
                    <div>
                        <div><strong>To:</strong></div>
                        <div style={{ width: "75%" }}>
                            <p className="m-0"><strong>Multitech</strong>,</p>
                            <p className="m-0">Stockholm, Sweden</p>
                            <p className="m-0">Phone:+4678712638767,</p>
                            <p className="m-0">Email: company@multitech.com</p>
                        </div>
                    </div>
                </div>

                <div className='company-info mb-5'>
                    <div className="company-info-temp">
                        <h6><strong>Invoice Number</strong></h6>
                        <h6>INV{data.invoice.id}</h6>
                    </div>

                    <div className="company-info-temp">
                        <h6><strong>Company Name</strong></h6>
                        <h6>Multitech</h6>
                    </div>
                    <div className="company-info-temp">
                        <h6><strong>Invoice Date</strong></h6>
                        <h6>{stockholmDate(data.invoice.timestamp)}</h6>
                    </div>
                    <div className="company-info-temp">
                        <h6><strong>Due Date</strong></h6>
                        <h6>{stockholmDate(data.invoice.due_date)}</h6>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <table style={{ pageBreakInside: 'avoid', tableLayout: "auto" }}>
                            <thead>
                                <tr>
                                    <th scope="col" style={{ width: "10%" }}>Id</th>
                                    <th scope="col" style={{ width: "40%" }}>Ride</th>
                                    <th scope="col" style={{ width: "15%" }}>Date</th>
                                    <th scope="col" style={{ width: "10%" }}>Fare</th>
                                    <th scope="col" style={{ width: "10%" }}>Earning Amount(7%)</th>
                                    <th scope="col" style={{ width: "10%" }}>Payable Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.rides.map((el, i) => {
                                    return (
                                        <tr key={i} style={{ pageBreakInside: 'avoid', height: "100px" }} >
                                            <th scope="row">{el.id}</th>
                                            <td>From <strong>{el.picup_location_name}</strong> to <strong>{el.drop_location_name}</strong>
                                                {el.stops.length !== 0 ? <span>&nbsp via <strong>{formatStops(el.stops)}</strong></span> : null}
                                            </td>
                                            <td>{stockholmDate(el.ride_date)}</td>
                                            <td>SEK {el.fare}</td>
                                            <td>SEK {(parseFloat(el.fare) * 0.07).toFixed(2)}</td>
                                            <td>SEK {parseFloat(el.fare) - (parseFloat(el.fare) * 0.07).toFixed(2)}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>

                    </div>
                </div>
                <hr className="mb-4 mt-0" />

                <div className="row my-5">
                    <div className="col-md-12">
                        <div className="d-flex justify-content-end">
                            <div className="row w-75">
                                <div className="col-md-8 text-end font-weight-bold">Total Fare:</div>
                                <div className="col-md-4 font-weight-bold">SEK {data.invoice.amount}</div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end">
                            <div className="row w-75">
                                <div className="col-md-8 text-end font-weight-bold">Total Amount Earned:</div>
                                <div className="col-md-4 font-weight-bold">SEK {data.invoice.earning_amount}</div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end">
                            <div className="row w-75">
                                <div className="col-md-8 text-end font-weight-bold">Total Payable Amount:</div>
                                <div className="col-md-4 font-weight-bold text-success">SEK {data.invoice.amount_to_pay}</div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row pt-3" style={{ pageBreakBefore: 'always' }}>
                    <div className="col-md-4">
                        <h6 className="text-start font-weight-bold my-3">Company Details</h6>
                        <div>
                            <p className="m-0">{data.company.name},</p>
                            <p className="m-0">{data.company.address},</p>
                            <p className="m-0">Organization Number: {data.company.organization_number},</p>
                            <p className="m-0">VAT Number: {data.company.vat_no}</p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <h6 className="text-start font-weight-bold my-3">Contact Details</h6>
                        <div>
                            <p className="m-0">Phone: {data.company.phone_number},</p>
                            <p className="m-0">Email: {data.company.organization_email}</p>
                            <p className="m-0">Website: {data.company.website}</p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <h6 className="text-start font-weight-bold my-3">Bank Details</h6>
                        <div>

                            <p className="m-0">Bank Account Number: {data.company.bankaccount},</p>
                            <p className="m-0">Giro Number: {data.company.bankgironumber}</p>
                            <p className="m-0">BIC/Swift Code: {data.company.bic_or_swift_code}</p>
                            <p className="m-0">IBAN: {data.company.iban}</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default InvoiceTemplate