import React, { useState, useEffect } from "react";
import axios from "axios";
import { selectUserType } from "../../redux/slicer";
import { useSelector } from "react-redux";
import { AiFillDelete } from "react-icons/ai";
import CircularProgress from "@mui/material/CircularProgress";
import PaginationComponent from "../../cards/PaginationComponent";
import { toast } from "react-toastify";
import FilterForm from "../../filter/FilterForm";
import { queryParams } from "../../../shared/utils";
import DeleteConfirmationModel from "../deleteConfirmationModel";
import { Link } from "react-router-dom";
import { DataGrid, GridColDef } from '@mui/x-data-grid';

const UserCards = ({handleSSelectedIds}) => {
  // const userType = useSelector(selectUserType)
  const userType = localStorage.getItem("A_usertype");
  const showFilter = useSelector((state) => state.showFilter.showFilter);
  const [userseData, setUserData] = useState({ "results": [] });
  const [update, setupdate] = useState(false);
  const accessToken = localStorage.getItem("Key");
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isFilterLoading, setIsFilterLoading] = useState(false);
  const [showModel, setShowModel] = useState({
    "show": false,
    "id": null
  });
  const [selectedIds, setSelectedIds] = useState([]);

  const handleShowModel = (id)=>{
    setShowModel({
      "show": true,
    "id": id
    })
  }
  const handleCloseModel = ()=>{
    setShowModel({
      "show": false,
    "id": null
    })
  }


  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleGetEmployeeData = async () => {
    setIsLoading(true);
    try {
      if (userType === "ADMIN_USER") {
        const resp = await axios
          .get(`${process.env.REACT_APP_BASE_URL}all-users/?paginate=true&page=${currentPage}`, {
            headers: {
              Authorization: "Bearer " + accessToken,
            },
          })
          .then((response) => {
            return response;
          });
        let results = resp.data
        setUserData(results);
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    handleGetEmployeeData();
  }, [update, currentPage]);
  
  
  const handleDeleteUser = async (id) => {
    handleCloseModel()
    setIsLoading(true);
    try {
      console.log("acce");
      const resp = await axios
        .delete(
          `${process.env.REACT_APP_BASE_URL}retrive-or-delete-user/${id}`,
          {
            headers: {
              Authorization: "Bearer " + accessToken,
            },
          }
        )
        .then((response) => {
          console.log(response, "response");
          toast("Deleted successfully!");
          setupdate(!update);
          return response.data.data;
        });
      console.log(resp);
    } catch (error) {
      console.log(error);
      // setMessege("Your session is expired, please Login again")
    }
    setIsLoading(false);
  };

  const ridesFields = [
    { name: 'name', label: 'Name' },
    { name: 'email', label: 'Email' },
    { name: 'mobile_number', label: 'Mobile Number' },
  ];

  const handleRidesFilter = (data) => {
    const params = queryParams(data)
    setIsFilterLoading(true)
    axios.get(`${process.env.REACT_APP_BASE_URL}filter-all-users/?${params}`, {
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    }).then((res) => {
      res.data.msg ? toast.error(res.data.msg) : setUserData(res.data)
      setIsFilterLoading(false)
    }).catch((err) => {
      setIsFilterLoading(false)
      toast.error(err);
    })
  };

  const handleClearFilters = () => handleGetEmployeeData();

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    if (checked) {
      setSelectedIds((prevSelectedIds) => [...prevSelectedIds, name]);
    } else {
      setSelectedIds((prevSelectedIds) => prevSelectedIds.filter((id) => id !== name));
    }
  };

  useEffect(()=>{
    handleSSelectedIds(selectedIds)
  }, [selectedIds])

  const handleSelectAllCheckboxChange = (event) => {
    const { checked } = event.target;
    if (checked) {
      setSelectedIds(userseData.results.map((user) => user.id.toString()));
    } else {
      setSelectedIds([]);
    }
  };

  return (
    <>
     <DeleteConfirmationModel showModel={showModel} onHandleDelete={handleDeleteUser} handleCloseModel={handleCloseModel}></DeleteConfirmationModel>
     {showFilter&&
     <FilterForm
     fields={ridesFields}
     onFilter={handleRidesFilter}
     onClearFilters={handleClearFilters}
     isLoading={isFilterLoading}
   />}
      <div class="card shadow mb-4">
        <div class="card-body">
          <div class="table-responsive">
            <table
              class="table table-bordered"
              id="dataTable"
              width="100%"
              cellspacing="0"
            >
              <thead>
                <tr>
                  <th className="text-center">
                  <input
                      type="checkbox"
                      disabled={isLoading}
                      className="form-check-input text-primary"
                      checked={selectedIds.length === userseData.results.length}
                      onChange={handleSelectAllCheckboxChange}
                    />
                  </th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Phone</th>
                  <th>Email</th>
                  <th>Profile</th>
                  <th>Delete</th>
                </tr>
              </thead>
              {isLoading ? (
                <tbody>
                  <tr>
                    <td colSpan="6" className="text-center">
                      <CircularProgress />
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {userseData.results
                    .map((el, i) => {
                      return (
                        <tr key={i}>
                          <td className="text-center">
                        <input
                          type="checkbox"
                          className="form-check-input text-primary"
                          checked={selectedIds.includes(el.id.toString())}
                          onChange={handleCheckboxChange}
                          name={el.id.toString()}
                        />
                      </td>
                          <td>{el.first_name}</td>
                          <td>{el.last_name}</td>
                          <td>{el.phone}</td>
                          <td>{el.email}</td>
                          <td>
                            <Link to={`/dashboard/profile/${el.id}`} className="btn-sm btn btn-gradient-primary">
                              View
                            </Link>
                          </td>
                          <td>
                            <div
                              style={{
                                alignContent: "center",
                                width: "100%",
                                height: "100%",
                                position: "relative",
                                cursor: "pointer",
                              }}
                            >
                              <AiFillDelete className="text-gray"
                                onClick={() => handleShowModel(el.id)}
                              ></AiFillDelete>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              )}
            </table>
          </div>
        </div>
        <PaginationComponent
          currentPage={currentPage}
          totalPages={userseData.total_pages}
          onPageChange={handlePageChange}
        />
      </div>
    </>
  );
};

export default UserCards;
