import React from 'react'
import { Routes, Route } from "react-router-dom";

import Login from "../screens/authscreen/login";
import LandingScreen from "../screens/landingscreen/landingscreen";
import AddCompanyScreen from "../screens/addcompany/addcompany";
import AddCarScreen from "../screens/addcar/addcar";
import AddEmployeeScreen from "../screens/addemployee/addemployee"
import AddRideScreen from "../screens/addrides/addrides";
import AllEmployeeScreen from "../screens/allemployee/allemployee";
import AllCompanyeeScreen from "../screens/allcompany/allcompany";
import AllUsersScreen from "../screens/allusers/allusers";
import AddUserScreen from "../screens/adduser/adduser";
import AllCarScreen from "../screens/allcar/allcar";
import AllRideScreen from "../screens/allrides/allrides";
import AvailableRideScreen from "../screens/allrides/availableride";
import DriverRides from "../screens/DriverRides/driverrides";
import AllDriverScreen from "../screens/alldriver/alldriver";
import AllReportScreen from "../screens/allreport/allreport";
import EditDriverScreen from "../screens/editdriverscreen/editdriver";
import EditCarScreen from "../screens/editcarscreen/editcar";
import ReportDetailsScreen from "../screens/reportdetails/reportdetails";
import MyRides from "../screens/ComapanyUserScreen/MyRides/MyRides";
import MyDriver from "../screens/ComapanyUserScreen/MyDriverScreen/MyDriver";
import MyCarScreen from "../screens/ComapanyUserScreen/MyCarScreen/MyCarScreen";
import MyEmployeeSc from "../screens/ComapanyUserScreen/myEmployeeScreen/myEmplyeeScreeen";
import AddCompanyCarScreen from "../screens/ComapanyUserScreen/addcarscreen/addCarScreen";
import AssignDriverCar from "../screens/assigndriver/AssignDriverCar";
import DriverEarningList from "../components/body/alldatacomponent/Earning/DriverEarningList";
import CompanyEarning from "../components/body/alldatacomponent/Earning/CompnyEarning";
import Profile from "../components/profile/Profile";
import EditCompanyScreen from "../screens/editCompanyScreen/editcompanyscreen";
import EditEmployeeScreen from "../screens/editEmployeeScreen/editemployeeScreen";
import DriverLocationComponent from '../components/map/DriverLocationComponents';
import ProtectedRoute from '../components/protectedRoute/ProtectedRoute';
import NotFound from '../components/notFound/NotFound';
import BillingScreen from '../screens/Billing/BillingScreen';
import TransactionScreen from '../screens/Billing/TransactionScreen';
import UserProfile from '../components/profile/userProfile';
import OngoingRideScreen from '../screens/allrides/ongoingRideScreen';
import NotificationScreen from '../screens/notification/notificationScreen';

const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/dashboard" element={<ProtectedRoute />}>
                <Route index element={<LandingScreen />} />
                <Route path="add-company" element={<AddCompanyScreen />} />
                <Route path="add-car" element={<AddCarScreen />} />
                <Route path="add-employee" element={<AddEmployeeScreen />} />
                <Route path="add-ride" element={<AddRideScreen />} />
                <Route path="all-employee" element={<AllEmployeeScreen />} />
                <Route path="all-company" element={<AllCompanyeeScreen />} />
                <Route path="all-users" element={<AllUsersScreen />} />
                <Route path="add-user" element={<AddUserScreen />} />
                <Route path="all-cars" element={<AllCarScreen />} />
                <Route path="all-rides" element={<AllRideScreen />} />
                <Route path="all-drivers" element={<AllDriverScreen />} />
                <Route path="edit-driver/:driverId" element={<EditDriverScreen />} />
                <Route path="edit-employee/:employeeId" element={<EditEmployeeScreen />} />
                <Route path="edit-car/:carId" element={<EditCarScreen />} />
                <Route path="edit-company/:companyId" element={<EditCompanyScreen />} />
                <Route path="all-report" element={<AllReportScreen />} />
                <Route path="driver-rides/" element={<DriverRides />} />
                <Route path="report-details/:reportId" element={<ReportDetailsScreen />} />
                <Route path="my-rides" element={<MyRides />} />
                <Route path="ongoing-rides" element={<OngoingRideScreen />} />
                <Route path="all-available-rides" element={<AvailableRideScreen />} />
                <Route path="my-cars" element={<MyCarScreen />} />
                <Route path="my-employee" element={<MyEmployeeSc />} />
                <Route path="assigndrivercar" element={<AssignDriverCar />} />
                <Route path="my-driver" element={<MyDriver />} />
                <Route path="add-cars" element={<AddCompanyCarScreen />} />
                <Route path="all-driver-earning/:driverId/:companyId" element={<DriverEarningList />} />
                <Route path="all-company-earning/:companyId" element={<CompanyEarning />} />
                <Route path="profile" element={<Profile />} />
                <Route path="profile/:userId" element={<UserProfile />} />
                <Route path="driver-locations" element={<DriverLocationComponent />} />
                <Route path="billing" element={<BillingScreen />} />
                <Route path="transaction/:compId" element={<TransactionScreen />} />
                <Route path="notifications/" element={<NotificationScreen />} />
                
            </Route>
            <Route path='*' element={<NotFound />} />
        </Routes>
    )
}

export default AppRoutes