import React from 'react'
import CircleImg from '../../../../static/images/dashboard/circle.svg'

const CommonRide = (props) => {
    return (
        <>

            {props.type == "compny" ?

                <div class="row">
                    <div class="col-md-3 stretch-card grid-margin">
                        <div class="card bg-gradient-light card-img-holder text-gray shadow">
                            <div class="card-body custom-padding">
                                <img src={CircleImg} class="card-img-absolute" alt="circle-image" />
                                <h4 class="font-weight-normal mb-3 text-dark">
                                    Company Bank Number
                                </h4>
                                <h4 class="mb-5">{props.bankaccountnumber} </h4>

                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 stretch-card grid-margin">
                        <div class="card bg-gradient-light card-img-holder text-gray shadow">
                            <div class="card-body custom-padding">
                                <img src={CircleImg} class="card-img-absolute" alt="circle-image" />
                                <h4 class="font-weight-normal mb-3 text-dark">
                                    Bankgiro Number
                                </h4>
                                <h4 class="mb-5">
                                    {props.bankgiro}</h4>

                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 stretch-card grid-margin">
                        <div class="card bg-gradient-light card-img-holder text-gray shadow">
                            <div class="card-body custom-padding">
                                <img src={CircleImg} class="card-img-absolute" alt="circle-image" />
                                <h4 class="font-weight-normal mb-3 text-dark">
                                    IBAN
                                </h4>
                                <h4 class="mb-5"> {props.iban}</h4>

                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 stretch-card grid-margin">
                        <div class="card bg-gradient-light card-img-holder text-gray shadow">
                            <div class="card-body custom-padding">
                                <img src={CircleImg} class="card-img-absolute" alt="circle-image" />
                                <h4 class="font-weight-normal mb-3 text-dark">
                                    BIC/SWIFT Code
                                </h4>
                                <h4 class="mb-5"> {props.bicSwiftCode}</h4>

                            </div>
                        </div>
                    </div>

                </div> :
                null

            }


            <div class="row">
                <div class="col-md-3 stretch-card grid-margin">
                    <div class="card bg-gradient-light card-img-holder text-gray shadow">
                        <div class="card-body custom-padding">
                            <img src={CircleImg} class="card-img-absolute" alt="circle-image" />
                            <h4 class="font-weight-normal mb-3 text-dark">Total Rides</h4>
                            <h4 class=""> {props.rides}</h4>

                        </div>
                    </div>
                </div>
                <div class="col-md-3 stretch-card grid-margin">
                    <div class="card bg-gradient-light card-img-holder text-gray shadow">
                        <div class="card-body custom-padding">
                            <img src={CircleImg} class="card-img-absolute" alt="circle-image" />
                            <h4 class="font-weight-normal mb-3 text-dark"> Total Earning
                            </h4>
                            <h4 class="mb-5"> {props.earning ? props.earning : 0} S.E.K</h4>

                        </div>
                    </div>
                </div>


                {props.type !== 'compny' ?
                    <div class="col-md-3 stretch-card grid-margin">
                        <div class="card bg-gradient-light card-img-holder text-gray shadow">
                            <div class="card-body custom-padding">
                                <img src={CircleImg} class="card-img-absolute" alt="circle-image" />
                                <h4 class="font-weight-normal mb-3 text-dark">Driver Name
                                </h4>
                                <h4 class="mb-5">{props.driver}</h4>

                            </div>
                        </div>
                    </div> : null
                }
                {props.type == "compny" ?
                    <>

                        <div class="col-md-3 stretch-card grid-margin">
                            <div class="card bg-gradient-light card-img-holder text-gray shadow">
                                <div class="card-body custom-padding">
                                    <img src={CircleImg} class="card-img-absolute" alt="circle-image" />
                                    <h4 class="font-weight-normal mb-3 text-dark"> Company VAT Number
                                    </h4>
                                    <h4 class="mb-5"> {props.vatNo}</h4>

                                </div>
                            </div>
                        </div>

                    </> : null
                }

                <div class="col-md-3 stretch-card grid-margin">
                    <div class="card bg-gradient-light card-img-holder text-gray shadow">
                        <div class="card-body custom-padding">
                            <img src={CircleImg} class="card-img-absolute" alt="circle-image" />
                            <h4 class="font-weight-normal mb-3 text-dark">Company Name
                            </h4>
                            <h4 class="mb-5"> {props.company}</h4>

                        </div>
                    </div>
                </div>
            </div>





        </>
    )
}

export default CommonRide