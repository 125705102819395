import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { faArrowLeft, faCalendar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AssignDriverCarComp = (props) => {
  console.log(props)
  const [DriverData, setDriverData] = useState();
  const [CarData, setCarData] = useState();
  const [CompData, setCompData] = useState();
  const [selectedCar, setselectedCar] = useState();
  const [SelectedDriver, setSelectedDriver] = useState(null);
  const [SelectedComp, setSelectedComp] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading1, setIsLoading1] = useState(true);
  const [isAssignC, setisAssignC] = useState(false);
  const [isAssignD, setisAssignD] = useState(false);
  const [isCompanyLoaded, setisCompanyLoaded] = useState(false);
  const [assignRideText, setassignRideText] = useState({
    "text": "Assign Ride",
    "class": "btn btn-gradient-primary"
  });
  // const [id, setid] = useState('')
  const [fmckey, setfmckey] = useState("");
  const [fcmtoggle, setfcmtoggle] = useState(false);
  const [update, setupdate] = useState(false);
  const [Messege, setMessege] = useState();
  const id = localStorage.getItem("A_userid");
  const userType = localStorage.getItem("A_usertype");
  const sessionKey = localStorage.getItem("Key");
  const ci = localStorage.getItem("A_companyinfo");

  let ccid = null;
  if (ci !== "undefined" && ci !== "null") {
    ccid = JSON.parse(ci.replace(/\\/g, "")).company_id;
  }
  // Notification
  const GetFcmTokenApiCall = async () => {
    console.log("getdata");
    const sessionKey = localStorage.getItem("Key");
    const BodyData = {
      driver_id: SelectedDriver,
      ride_id: props.id.id,
    };
    console.log(BodyData, 'sadfdasf')
    const options = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${sessionKey}`,
      },
    };
    try {
      setassignRideText({
        "text": "Sending Notification ...",
        "class": "btn btn-gradient-primary disabled"
      });
      // for initial data creation
      const resp = await axios
        .post(
          `${process.env.REACT_APP_BASE_URL}generate-notofocation/`,
          BodyData,
          options
        )
        .then((response) => {
          console.log(response, "desponse");
          if (response.status === 200 || response.status === 201) {
            console.log(response.data["fcm_key"], "fcm_key.");
            setfmckey(response.data["fcm_key"]);
            setfcmtoggle(!fcmtoggle);
          }
          return response.data;
        });
    } catch (error) {
      setassignRideText({
        "text": "Assign Ride",
        "class": "btn btn-gradient-primary"
      });
      console.log(error, "erorr");
      return error
    }
  };
  console.log(fmckey, "update");
  useEffect(() => {
    if (fmckey) {
      PushNotificationToDriver();
    }
  }, [fcmtoggle]);

  const PushNotificationToDriver = async () => {
    setassignRideText({
      "text": "Sending Notification ...",
      "class": "btn btn-gradient-primary disabled"
    });
    const BodyData = {
      to: fmckey,
      notification: {
        title: "Ride Assign SucessFully",
        body: "You've just assigned a new ride. Please take a moment to review.",
        mutable_content: true,
        sound: "Tri-tone",
      },
    };
    const options = {
      headers: {
        "Content-type": "application/json",
        Authorization: `key=AAAA3q-zQjM:APA91bHKcZMCVzGsqY4CK4nbRVPLDdpMVss9TiridD2g2BkfDE-3i1i4FWXg4RPiSZKTT1UXXK_o3VFDUOJ3AJ-l2etzedkfgco-NhQPKiMXgatylizv77lc3RnR1_8lWTG57g7iYkW_`,
      },
    };
    try {
      // for initial data creation
      const resp = await axios
        .post(`https://fcm.googleapis.com/fcm/send`, BodyData, options)
        .then((response) => {
          console.log(response, "fcmresponse");
          toast("Notification Sent");
          setassignRideText({
            "text": "Assign Ride",
            "class": "btn btn-gradient-primary"
          });
          if (response.status === 200 || response.status === 201) {
          }
          return response.data;
        });
    } catch (error) {
      setassignRideText({
        "text": "Assign Ride",
        "class": "btn btn-gradient-primary"
      });
      console.log(error, "html");
    }
  };
  // get DriverList
  const getDriverList = async () => {
    console.log("loading drivers......")
    setIsLoading(true);
    const endpoint =
      userType === "ADMIN_USER"
        ? `ride-company-drivers/${SelectedComp}?car_type=${props.id.type}&ride_id=${props.id.id}`
        : `ride-company-drivers/${ccid}?car_type=${props.id.type}&ride_id=${props.id.id}`;

    const sessionKey = localStorage.getItem("Key");
    try {
      console.log("acce");
      const resp = await axios
        .get(`${process.env.REACT_APP_BASE_URL}${endpoint}`, {
          headers: {
            Authorization: "Bearer " + sessionKey,
          },
        })
        .then((response) => {
          const arr = [];
          const data = response.data;
          data.map((user) => {
            arr.push({ value: user.id, label: user.name });
          });
          setDriverData(arr);
          return response.data.data;
        });
    } catch (error) {
      console.log(error);
      setMessege("Your session is expired, please Login again");
    }
    setIsLoading(false);
  };

  // get CarList

  const getCompanyList = async () => {
    console.log("loading company......")
    setIsLoading(true);
    try {
      const accessToken = localStorage.getItem("Key");
      const resp = await axios
        .get(`${process.env.REACT_APP_BASE_URL}all-company/`, {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        })
        .then((response) => {
          const arr = [];
          response?.data?.map((user) => {
            arr.push({ value: user.id, label: user.name });
          });
          setCompData(arr);
          return response.data;
        });
      console.log(resp);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const getCarList = async () => {
    console.log("loading cars......")
    setIsLoading1(true);
    const endpoint =
      userType === "ADMIN_USER"
        ? "company-cars/" +
        SelectedComp +
        "/" +
        SelectedDriver +
        "?cartype=" +
        props.id.type
        : "company-cars/" +
        ccid +
        "/" +
        SelectedDriver +
        "?cartype=" +
        props.id.type;
    try {
      const accessToken = localStorage.getItem("Key");
      const resp = await axios
        .get(`${process.env.REACT_APP_BASE_URL}${endpoint}`, {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        })
        .then((response) => {
          console.log(response, "erwr");
          const arr = [];
          const data = response.data;
          data.map((user) => {
            arr.push({
              value: user.id, label: `${user.car_model} - (${user.car_type.map((elem, i) => {
                return (
                  ` ${elem}`
                );
              })} )`
            });
          });
          setCarData(arr);

          return response.data;
        });
    } catch (error) {
      console.log(error);
    }
    setIsLoading1(false);
  };

  console.log(CarData, "cardata");
  console.log(DriverData, "driver");
  console.log(CompData, "comp");

  const handleAssignDriverCarField = () => {
    setisAssignC(true);
  };

  // useeffect
  useEffect(() => {
    if (userType === "ADMIN_USER") {
      getCompanyList();
    }
  }, [isCompanyLoaded]);

  useEffect(() => {
    if (userType !== "ADMIN_USER" || isAssignC) {
      getDriverList();
    }
  }, [isAssignC, SelectedComp]);

  useEffect(() => {
    if (isAssignD) {
      getCarList();
    }
  }, [isAssignD, SelectedDriver]);

  const handleSelectDriver = (value) => {
    setSelectedDriver(value);
    setisAssignD(true)
  }
  const handleCompanySelect = (value) => {
    setSelectedComp(value);
    setisAssignC(false)
    setisAssignD(false)
  }

  // assign Driver adn car
  const AssignDriverAndCar = async () => {
    if (isAssignC || isAssignD) {
      setassignRideText({
        "text": "Assigning Ride ...",
        "class": "btn btn-gradient-primary disabled"
      });
      const assignData = {
        driver_assigned: SelectedDriver,
        car_assigned: selectedCar,
        company_id: userType === "ADMIN_USER" ? SelectedComp : ccid,
      };
      console.log(assignData);
      const options = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${sessionKey}`,
        },
      };
      try {
        // for initial data creation
        const resp = await axios
          .put(
            `${process.env.REACT_APP_BASE_URL}assign-driver-car-rides/${props.id.id}`,
            assignData,
            options
          )
          .then((response) => {
            console.log(response, "response");
            console.log("helll");
            if (response.status === 200 || response.status === 201) {
              setupdate ? setupdate(!update) : console.log(response.data);
              toast("Ride assigned successfully!");
            }
            return response.data
          });
        let rep = GetFcmTokenApiCall();
      } catch (error) {
        setassignRideText({
          "text": "Assign Ride",
          "class": "btn btn-gradient-primary"
        });
        console.log(error, "erorr");
        if (error.response.status === 401) {
          toast("Session is Expired! Please Login Again");
        } else {
          toast("Somthing went wrong, please try later");
        }
      }
    } else {
      setassignRideText({
        "text": "Assigning Company ...",
        "class": "btn btn-gradient-primary disabled"
      });
      const assignData = {
        company_id: SelectedComp,
      };
      const options = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${sessionKey}`,
        },
      };
      try {
        // for initial data creation
        const resp = await axios
          .put(
            `${process.env.REACT_APP_BASE_URL}update-rides-company/${props.id.id}`,
            assignData,
            options
          )
          .then((response) => {
            console.log(response, "response");
            toast("assign The Ride to company");
            setassignRideText({
              "text": "Assign Ride",
              "class": "btn btn-gradient-primary"
            });
            return response.data;
          });
      } catch (error) {
        setassignRideText({
          "text": "Assign Ride",
          "class": "btn btn-gradient-primary"
        });
        console.log(error);
        toast("Somthing went wrong, please try later");
      }
    }
  };
  
  return (
    <>
    <div class="d-sm-flex align-items-center justify-content-between my-3">
        <h3 class="page-title">
          <span class="page-title-icon bg-gradient-primary text-white me-2">
            <i><FontAwesomeIcon icon={faCalendar} /></i>
          </span> {String("Assign Driver And Car").toUpperCase()}
        </h3>
        <div className="d-flex justify-content-end align-items-center">
          {userType === "ADMIN_USER" ?
            <Link className="p-2 rounded bg-gradient-success text-white me-2 shadow-sm" to="/dashboard/all-rides" style={{ cursor: "pointer" }}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </Link>
            :
            <Link className="p-2 rounded bg-gradient-success text-white me-2 shadow-sm" to="/dashboard/my-rides" style={{ cursor: "pointer" }}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </Link>
          }
        </div>
      </div>

      <div className="card">
        <div className="card-body">
        <div className="user">
            {userType === "ADMIN_USER" ? (
              <>
                <div className="form-group">
                  <Select
                    options={CompData}
                    onChange={(e) => handleCompanySelect(e.value)}
                    placeholder="Select Company Type"
                  ></Select>
                </div>
                <div className="my-3">
                  <button
                    className="btn-gradient-success btn"
                    onClick={handleAssignDriverCarField}
                  >
                    Assign Driver And Car
                  </button>
                </div>
              </>
            ) : (
              <></>
            )}

            {isAssignC || userType !== "ADMIN_USER" ? (
              <>
                {isLoading ? (
                  <div
                    className="mt-3"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: 20,
                    }}
                  >
                    <CircularProgress />
                  </div>
                ) : (
                  <div className="form-group">
                    <Select
                      options={DriverData}
                      onChange={(e) => handleSelectDriver(e.value)}
                      placeholder="Select Driver "
                    ></Select>
                  </div>
                )}
              </>
            ) : (
              null
            )}
            {isAssignD ? (
              <>
                {isLoading ? (
                  <div
                    className="mt-3"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: 20,
                    }}
                  >
                    <CircularProgress />
                  </div>
                ) : (
                  <div className="form-group">
                    <Select
                      options={CarData}
                      onChange={(e) => setselectedCar(e.value)}
                      placeholder="Select Car Type"
                    ></Select>
                  </div>
                )}
              </>
            ) : (
              null
            )}

            <a
              className={`${assignRideText.class} btn-user btn-block`}
              onClick={AssignDriverAndCar}
            >
              {assignRideText.text}
            </a>
          </div>
        </div>
      </div>

    </>


  );
};

export default AssignDriverCarComp;
