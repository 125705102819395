import React from "react";
import DasboarTopComp from "../dashboardtop/dashboardtop";
import DashboardTopMidComp from "../dashboardtopmid/dashboardtopmid";
import DashboardMidComp from "../dashboardmid/dashboardmid";

const DasboardMainComp = () => {
    return (
        <>
            <div class="page-header">
                <h3 class="page-title">
                    <span class="page-title-icon bg-gradient-primary text-white me-2">
                        <i class="mdi mdi-home"></i>
                    </span> Dashboard
                </h3>
            </div>
            <DashboardTopMidComp/>
            <DashboardMidComp />
        </>
    )
}

export default DasboardMainComp;