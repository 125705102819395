import React, { useState, useEffect } from "react";
import axios from "axios";
import { AiFillDelete } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";
import PaginationComponent from "../PaginationComponent";
import { toast } from "react-toastify";
import stockholmDate from "../dateformatConvert"
import MapInfoModal from "../../map/MapInfoModal";
import moment from "moment-timezone";
import { formatStops } from "../../../shared/utils";


const DriverRidesCards = ({ appliedState, paramData }) => {
  const userType = localStorage.getItem("A_usertype");
  const nav = useNavigate();
  const [rideseData, setRidesData] = useState({ "results": [] });
  const accessToken = localStorage.getItem("Key");
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [mapOpen, setMapOpen] = useState(false)
  const [rideInfo, setMapRideInfo] = useState({
    origin: null,
    destination: null,
    via: null
  })



  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleGetRidesData = async () => {
    setIsLoading(true);
    try {
      let url = `${process.env.REACT_APP_BASE_URL}admin-driver-rides/`
      url += `?paginate=true&page=${currentPage}&`
      url += paramData

      if (userType === "ADMIN_USER" || userType === "COMPANY_USER") {
        const resp = await axios
          .get(url, {
            headers: {
              Authorization: "Bearer " + accessToken,
            },
          })
          .then((response) => {
            return response;
          });
        let results = resp.data
        console.log(results)
        setRidesData(results)
        setIsLoading(false);
      }
    } catch (error) {
      toast(error.response.data.detail)
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetRidesData();
  }, [currentPage, appliedState]);

  const handleMapOpen = (ride) => {
    setMapOpen(true)
    setMapRideInfo({ origin: ride.picup_location_name, destination: ride.drop_location_name, via: formatStops(ride.stops) })
  }

  return (
    <>
      <MapInfoModal isOpen={mapOpen} onClose={() => setMapOpen(false)} title="Map" origin={rideInfo.origin} destination={rideInfo.destination} via={rideInfo.via} />
      {isLoading ?
        <div className="d-flex justify-content-center align-items-center my-5 py-5">
          <CircularProgress />
        </div>
        :

        <>{
          rideseData.results.map((ride, i) => {
            return (
              <div className="card shadow mb-3 grid-margin stretch-card" key={i}>
                <div className="card-body">
                  <h5 className="mb-3"><strong>Ride {ride.id}</strong></h5>
                  <div className="row">
                    <div className="col-md-4">

                      <p><strong>From</strong><br /> <span className="text-dark">{ride.picup_location_name}</span></p>
                      <p><strong>To</strong><br />  <span className="text-dark">{ride.drop_location_name}</span></p>
                      <p><strong>Stops</strong><br /> <span className="text-dark">{ride.stops &&
                        ride.stops.length !== 0 ? (
                        <>{formatStops(ride.stops)}</>
                      ) : (
                        <>No Stop</>
                      )}</span></p>
                      <p><strong>Booking Date & Time</strong><br />  <span className="text-dark">{stockholmDate(ride.ride_date)}</span></p>
                      <p><strong>Ride Date & Time</strong><br /> <span className="text-dark">{stockholmDate(ride.created_date)}</span></p>
                    </div>
                    <div className="col-md-4">
                      <p><strong>Rider Name</strong><br />  <span className="text-dark">{ride.ridername}</span></p>
                      <p><strong>Rider Phone</strong><br /> <span className="text-dark">{ride.riderphone}</span></p>
                      <p><strong>Fare</strong><br /> <span className="text-dark">{ride.fare} S.E.K</span></p>
                      <p><strong>Distance</strong><br />  <span className="text-dark">{ride.distance} KM</span></p>
                      <p><strong>Company Name</strong><br />  <span className="text-dark">{ride.company_name
                        ? ride.company_name
                        : "Company Not Assigned"}</span></p>

                    </div>
                    <div className="col-md-4">
                      <p><strong>Car Type</strong><br />  <span className="text-dark">{ride.car_type}</span></p>
                      <p><strong>Car Model</strong><br /> <span className="text-dark">{ride.car_model ? ride.car_model : "Car Not Assigned"}</span></p>
                      <p><strong>Car Registration Number</strong><br /> <span className="text-dark">{ride.car_registration_number
                        ? ride.car_registration_number
                        : "Car Not Assigned"}</span></p>
                      <p><strong>Driver Name</strong><br /> <span className="text-dark">{ride.driver_name
                        ? ride.driver_name
                        : "Driver Not Assigned"}</span></p>
                      <p><strong>Driver Number</strong><br /> <span className="text-dark">  {ride.driver_phone
                        ? ride.driver_phone
                        : "Driver Not Assigned"}</span></p>
                    </div>
                  </div>
                  <div className="mt-3">
                    <button
                      className="btn btn-gradient-info btn-sm mx-2 my-1"
                      onClick={() => handleMapOpen(ride)}
                    >
                      Route
                    </button>

                    <span
                      className="btn btn-gradient-primary btn-sm mx-2 my-1"
                      style={{ cursor: "none" }}
                    >
                      {ride.status}
                    </span>

                  </div>
                </div>
              </div>
            )
          })
        }</>
      }

      <div className="my-3">

        <PaginationComponent
          currentPage={currentPage}
          totalPages={rideseData.total_pages}
          onPageChange={handlePageChange}
        />
      </div>
    </>
  );
};

export default DriverRidesCards;
