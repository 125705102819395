import { createSlice } from "@reduxjs/toolkit";


const initialState={
    showFilter:false
}

const FilterSlicer=createSlice({
    name:"showFilter",
    initialState:initialState,
    reducers:{
        setUpdateShowFilter:(state,action)=>{
            /* eslint eqeqeq: "off", curly: "error" */
            state.showFilter=!state.showFilter
        }
    }
})


export const { setUpdateShowFilter } = FilterSlicer.actions;

// Authentication data
// export const selectIsLoggedIn=(state)=>state.taxiappdata.authdata.isLoggedIn;


export default FilterSlicer.reducer;