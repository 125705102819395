import { configureStore } from "@reduxjs/toolkit";
import TaxiAppSlicer from "./slicer";
import FilterSlicer from "./filterState";


export const Store=configureStore({
    reducer:{
        taxiappdata:TaxiAppSlicer,
        showFilter: FilterSlicer
    }
    
})