import React from "react";
import AddEmployeeMidComp from "../addcompanyemployeemidcomp/addcompanyemployeemidcomp";

const AddEmployeeMainComp = () => {
    return (
        <AddEmployeeMidComp></AddEmployeeMidComp>

    )
}

export default AddEmployeeMainComp;