import React, { useState } from "react";
import AllDataCommonComp from "../common/common";
import UserCards from "../../../cards/users/userscard";
import { faUsers } from "@fortawesome/free-solid-svg-icons";

const AllUsersMainComp=()=>{
    const [selectedIds, setselectedIds] = useState([])
    const handleSSelectedIds = (ids)=>{
        setselectedIds(ids)
    }
    return(
        <div class="container-fluid mt-4">
            <AllDataCommonComp addUrl="/dashboard/add-user"  headerIcon={faUsers} allemployee="All Users"  downloadCall="users" selectedIds={selectedIds}></AllDataCommonComp>
            <UserCards handleSSelectedIds={handleSSelectedIds}></UserCards>
        </div>
        
    )
}

export default AllUsersMainComp;