import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { selectUserType, selectCompanyInfo } from "../redux/slicer";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { AiOutlineLink } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";
import { faArrowLeft, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";

const EditDriverForm = () => {
  const [companyId, setCompanyId] = useState(null);
  const [employeeName, setEmployeeName] = useState("");
  const [employeeEmail, setEmployeeEmail] = useState("");
  const [employeePhone, setEmployeePhone] = useState(null);
  const [licenceNumber, setLicenceNumber] = useState("");
  const [userId, setUserId] = useState(null);
  const [messege, setMessege] = useState("");
  const [companyData, setCompanyData] = useState([]);
  const [isCompanyData, setIsCompanyData] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [companyLabel, setCompanyLabel] = useState([]);
  const [employeeAddress, setEmployeeAddress] = useState("");
  const [employeeDocumentsFiles, setEmployeeDocumentsFiles] = useState([]);
  const [addedDocumentsUrl, setAddedDocumentsUrl] = useState([]);
  const [deletedDocumentsId, setDeletedDocumentsId] = useState("");
  const [deletedDocumentsDataList, setdeletedDocumentsDataList] = useState([]);

  const nav = useNavigate();
  // const userType = useSelector(selectUserType);
  const userType = localStorage.getItem("A_usertype");
  const ci = localStorage.getItem("A_companyinfo");
  let ccid = null;
  let empid = null;
  if (ci !== "undefined" && ci !== "null") {
    ccid = JSON.parse(ci.replace(/\\/g, "")).company_id;
    empid = JSON.parse(ci.replace(/\\/g, "")).employee_id;
  }
  console.log("cid", ccid);
  const handleFileInput = (e) => {
    const selectedFiles = e.target.files;
    setEmployeeDocumentsFiles(selectedFiles);
  };


  const { driverId } = useParams();
  const accessToken = localStorage.getItem("Key");

  // Get car instance data
  const handleGetInstanceData = () => {
    setIsloading(true);
    try {
      const resp = axios
        .get(
          `${process.env.REACT_APP_BASE_URL}update-driver-account/` + driverId,
          {
            headers: {
              Authorization: "Bearer " + accessToken,
            },
          }
        )
        .then((response) => {
          console.log("response", response);
          setCompanyId(response.data.company_id);
          setEmployeeName(response.data.employee_name);
          setEmployeeEmail(response.data.employee_email);
          setEmployeePhone(response.data.employee_phone);
          setEmployeeAddress(response.data.employee_address);
          setLicenceNumber(response.data.driver_licence_number);
          setAddedDocumentsUrl(response.data.added_document_urls);
          setUserId(response.data.user_id);
          setIsloading(false);
          return response.data;
        });
    } catch (error) {
      setIsloading(false);
      toast("Somthing went wrong not able to fetch user data");
      console.log(error);
    }
  };


  const handleUpdateDriverData = async (e) => {
    e.preventDefault();
    setMessege("");
    if (userType === "ADMIN_USER") {
      if (companyId === null) {
        setMessege("Company Must not be Blank");
        return;
      }
    }
    if (employeeName.length < 4) {
      setMessege("Employee name must be 4 characters long");
      return;
    }
    if (employeePhone === "") {
      if (employeePhone.length < 13 || !employeePhone.startsWith("+")) {
        setMessege("Phone number is not valid or must start with country code");
        return;
      }
    }
    if (employeeAddress === "") {
      setMessege("Address Must not be Blank");
      return;
    }
    if (licenceNumber === "" || licenceNumber === null) {
      setMessege("Driver Licence Must not be Blank");
      return;
    }
    setIsloading(true);
    try {
      let data = new FormData();
      data.append("user_id", userId);
      data.append("employee_name", employeeName);
      data.append("employee_email", employeeEmail);
      data.append("employee_phone", employeePhone);
      data.append("employee_type", "Driver");
      data.append("employee_address", employeeAddress);
      data.append("driver_licence_number", licenceNumber);
      if (employeeDocumentsFiles.length !== 0) {
        for (let i = 0; i < employeeDocumentsFiles.length; i++) {
          data.append("added_documents", employeeDocumentsFiles[i]);
        }
      }
      if (deletedDocumentsId !== "") {
        data.append("deleted_documents", deletedDocumentsId);
      }
      // console.log(licenceData, "data to edit");
      if (userType == "ADMIN_USER") {
        data.append("company_id", parseInt(companyId));
        const url =
          `${process.env.REACT_APP_BASE_URL}` +
          "update-driver-account" +
          "/" +
          driverId;
        const resp = await axios
          .put(url, data, {
            headers: {
              Authorization: "Bearer " + accessToken,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            return response.data.data;
          });
        toast("Successfully updated");
        nav("/dashboard/all-drivers");
        setIsloading(false);

      } else {
        data.append("deleted_documents", JSON.stringify(deletedDocumentsDataList))
        data.append("type", "Driver");
        data.append("send_type", "edit");
        data.append("emp_id", parseInt(driverId));
        data.append("company_id", ccid);
        data.append("created_by", empid);
        const url = `${process.env.REACT_APP_BASE_URL}create-report-firebase/`;
        const resp = await axios
          .post(url, data, {
            headers: {
              Authorization: "Bearer " + accessToken,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            toast("Employee updated request is send to admin");
            nav("/dashboard/my-driver");
            return response.data;
          });
      }
      // console.log(resp);
    } catch (error) {
      console.log(error);
      setIsloading(false);
      toast("Your Session is Expired, Please Login Again");
    }
  };

  const handleGetCompanyData = async () => {
    try {
      if (userType === "ADMIN_USER") {
        const resp = await axios
          .get(`${process.env.REACT_APP_BASE_URL}all-company/`, {
            headers: {
              Authorization: "Bearer " + accessToken,
            },
          })
          .then((response) => {
            setCompanyData(response.data);
            setIsCompanyData(true);
            return response.data;
          });
      }
    } catch (error) {
      console.log(error);
      setMessege("Somthing went wrong not able to fetch user data");
      setIsCompanyData(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (userType === "ADMIN_USER") {
      handleGetCompanyData();
    }
    handleGetInstanceData();
  }, []);

  useEffect(() => {
    if (companyData.length > 0) {
      const company_list = companyData.map((el, i) => {
        return {
          label: el.name,
          id: el.id,
          name: el.name,
        };
      });
      setCompanyLabel(company_list);
    }
  }, [isCompanyData]);

  const handleDeleteDocument = (id) => {
    let updatedId;
    if (deletedDocumentsId === "") updatedId = id;
    else updatedId = deletedDocumentsId + "," + id;
    setDeletedDocumentsId(`${updatedId}`);
    let filterdata = addedDocumentsUrl.filter((item) => item.id !== id);
    setAddedDocumentsUrl(filterdata);
    if (userType === "COMPANY_USER") {
      let removedObject = addedDocumentsUrl.filter((item) => item.id === id);
      let data = deletedDocumentsDataList
      data.push(removedObject[0])
      setdeletedDocumentsDataList(data)
    }
  };


  return (
    <>
      <div class="d-sm-flex align-items-center justify-content-between my-3">
        <h3 class="page-title">
          <span class="page-title-icon bg-gradient-primary text-white me-2">
            <i><FontAwesomeIcon icon={faUser} /></i>
          </span> EDIT DRIVER
        </h3>
        <div className="d-flex justify-content-end align-items-center">
          <Link className="p-2 rounded bg-gradient-success text-white me-2 shadow-sm" to="/dashboard/all-drivers" style={{ cursor: "pointer" }}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </Link>
        </div>
      </div>
      <div class="col-12">
        <div class="card shadow" style={{ padding: "1rem" }}>
          <div class="card-body">
            {isLoading ? (
              <div
                className="mt-3"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: 20,
                }}
              >
                <CircularProgress />
              </div>
            ) :
              <form class="form-sample" onSubmit={(e) => handleUpdateDriverData(e)}>
                <div class="row mb-3">
                  <div class="col-md-6">
                    <div class=" mb-0 p-1 row">
                      <TextField value={employeeName}
                        onChange={(e) => setEmployeeName(e.target.value)}
                        type="text"
                        label="Employee Name" />

                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-0 p-1 row">
                      <TextField value={employeeEmail}

                        onChange={(e) => setEmployeeEmail(e.target.value)}
                        type="email"
                        label="Employee Email" />
                    </div>
                  </div>
                </div>


                <div class="row mb-3">
                  <div class="col-md-6">
                    <div class=" mb-0 p-1 row">
                      <TextField value={employeeAddress}

                        onChange={(e) => setEmployeeAddress(e.target.value)}
                        type="text"
                        label="Employee Address" />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-0 p-1 row">
                      <PhoneInput
                        className="form-control"
                        style={{ height: "3.5rem" }}
                        defaultCountry="SE"
                        value={employeePhone}
                        onChange={setEmployeePhone}
                        limitMaxLength={true}
                        placeholder={"Employee Number"}
                      />
                    </div>
                  </div>
                </div>


                <div class="row mb-3">
                  <div class="col-md-6">
                    <div class=" mb-0 p-1 row">

                      <input
                        type="file"
                        className="form-control"
                        onChange={handleFileInput}
                        multiple
                      />

                      {addedDocumentsUrl.length !== 0 ? (
                        <div className="row mt-2">
                          {addedDocumentsUrl.map((elem, i) => {
                            return (
                              <div className="col-12 mb-1" key={i}>
                                <a
                                  href={elem.document_url}
                                  className="fa fa-download"
                                  target="_blank"
                                  style={{ cursor: "pointer" }}
                                >
                                  <AiOutlineLink /> {elem.filename}
                                </a>{" "}
                                <RxCross2
                                  onClick={() => handleDeleteDocument(elem.id)}
                                  style={{ cursor: "pointer" }}
                                />
                              </div>
                            );
                          })}
                        </div>
                      ) : null}

                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-0 p-1 row">
                      <TextField value={licenceNumber}
                        onChange={(e) => setLicenceNumber(e.target.value)}
                        type="text"
                        label="Driver License" />

                    </div>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-md-6">
                    <div class=" mb-0 p-1 row">



                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-0 p-1 row">
                      {userType === "ADMIN_USER" && companyLabel.length !== 0 &&
                        <FormControl fullWidth>
                          <InputLabel id="cmp-type">Select Company</InputLabel>
                          <Select
                            labelId="cmp-type"
                            value={companyId}
                            label="Select Company"
                            onChange={(e) => setCompanyId(e.target.value)}
                          >

                            {companyLabel.map((d, i) => {
                              return (
                                <MenuItem key={i} value={d.id}>{d.label}</MenuItem>
                              )
                            })}
                          </Select>
                        </FormControl>
                      }






                    </div>
                  </div>
                </div>






                <div className="d-flex justify-content-center mt-5">
                  <button type="submit" class="btn btn-gradient-primary">Submit</button>
                </div>

              </form>
            }

          </div>
        </div>
      </div >
    </>

  );
};

export default EditDriverForm;
