import React from "react";
import SidebarComp from "../../components/sidebar/sidebar";
import NavbarComp from "../../components/navbar/navbar";
import AllEmployeesMainComp from "../../components/body/alldatacomponent/employee/allemployee";

const AllEmployeeScreen=()=>{
    return(
        <div className="container-scroller">
        <NavbarComp />
        <div className="container-fluid page-body-wrapper">
            <SidebarComp />
            <div className="main-panel">
                <div className="content-wrapper">
                    <AllEmployeesMainComp />
                </div>
            </div>
        </div>
    </div>
    )
}

export default AllEmployeeScreen;