import React from "react";
import DashboardTopMidComp from "../../main/dashboardtopmid/dashboardtopmid";
import DasboarTopComp from "../../main/dashboardtop/dashboardtop";
import EditCarMidComp from "./editcarrmidcomp";

const EditCarMainComp=()=>{
    return(
        <div class="container-fluid">
            <EditCarMidComp></EditCarMidComp>
        </div>
    )
}

export default EditCarMainComp;