import jwtDecode from 'jwt-decode';

function isTokenExpired(token) {
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000; // Convert to seconds
      return decodedToken.exp < currentTime;
    } catch (error) {
      return true; // Error decoding token or invalid token
    }
  }

export default isTokenExpired;
