import axios from 'axios';
import React, { useState } from 'react'
import { toast } from 'react-toastify';
import InvoiceTemplate from "../invoiceTemplate";
import ReactDOMServer from 'react-dom/server';
import html2pdf from "html2pdf.js"

const InvoiceButton = ({elem}) => {
    const [invoiceisLoading, setinvoiceisLoading] = useState(false);
    const handleInvoiceData = async (invoiceId) => {
        setinvoiceisLoading(true)
        try {
            const accessToken = localStorage.getItem("Key");
            const resp = await axios
                .get(`${process.env.REACT_APP_BASE_URL}generate-invoice/${invoiceId}`, {
                    headers: {
                        Authorization: "Bearer " + accessToken,
                    },
                })
                .then((response) => {
                    downloadInvoice(response.data.data, invoiceId)
                    setinvoiceisLoading(false)
                });
        } catch (error) {
            toast("Invoice Generation Failed, Please try again later")
            setinvoiceisLoading(false)
            console.log(error);
        }
    };


    const downloadInvoice = (data, invoiceId) => {
        setinvoiceisLoading(true)
        if (data) {
            console.log(data)
            const element = ReactDOMServer.renderToString(<InvoiceTemplate data={data} />);
            const opt = {
                margin: 10,
                filename: `INV${invoiceId}.pdf`,
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
            };

            html2pdf().from(element).set(opt).save();
        }
        setinvoiceisLoading(false)
    }

    return (
        <>
            {invoiceisLoading ?
                <button disabled className="btn btn-gradient-primary btn-sm">Downloading...</button> :
                <button onClick={() => handleInvoiceData(elem.id)} className="btn btn-gradient-primary btn-sm">Invoice</button>}
        </>
    )
}

export default InvoiceButton