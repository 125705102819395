import React from "react";
import AllDataCommonComp from "../alldatacomponent/common/common";
import ReportDetailsCards from "../../cards/reportdetails/reportdetailscard";

const ReportDetailsMainComp=()=>{
    return(
        <div class="container-fluid">

            <ReportDetailsCards/>
        </div>
        
    )
}

export default ReportDetailsMainComp;