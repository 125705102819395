import MapComponent from "./MapComponent";

const MapInfoModal = ({ isOpen, onClose, title, origin, destination, via }) => {
    if (!(isOpen && onClose && origin && destination)) return null
    return (
        <div className={`modal ${isOpen ? "d-block" : "d-none"}`} style={{
            position: "fixed",
            zIndex: 1050,
            backgroundColor: "rgba(0, 0, 0, 0.5)"
        }}>
            <div className="modal-dialog" style={{ maxWidth: "800px" }}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{title}</h5>
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => onClose(false)}
                        >
                            X
                        </button>
                    </div>
                    <div className="modal-body">
                        <MapComponent
                            origin={origin}
                            destination={destination}
                            waypoints={via.split('-')[0] !== ''?via.split('-'): [] }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MapInfoModal;