import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import { toast } from 'react-toastify';
import { faArrowLeft, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { TextField } from "@mui/material";
import PhoneInput from "react-phone-number-input";

const AddUserForm = () => {
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [userType, setUserType] = useState("END_USER");
    const [message, setMessage] = useState("");


    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    const validateForm = () => {
        if (phone.length < 10) {
            setMessage("Phone number should contain 10 characters");
            return false;
        }
        if (!firstName.trim()) {
            setMessage("First Name Must Not be Blank");
            return false;
        }
        if (!lastName.trim()) {
            setMessage("Last Name Must Not be Blank");
            return false;
        }
        if (!emailPattern.test(email)) {
            setMessage("Enter a valid email address");
            return false;
        }
        return true;
    };

    const handleAddUser = async () => {
        if (!validateForm()) {
            return;
        }

        try {
            const data = {
                "phone": phone,
                "first_name": firstName,
                "last_name": lastName,
                "user_type": userType,
                "email": email
            };

            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}signup/`,
                data,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }
            ).then((res)=>{
                toast("User is Created");
                setFirstName('');
                setLastName('');
                setPhone('');
                setEmail('');
            })
            .catch((error) => {
                if (error.response) {
                  if (error.response.status === 401) {
                    toast("User with this phone number is inactive.")
                    return;
                  }
                  if (error.response.status === 400 && error.response.data.is_exists == "true") {
                    toast("User with this phone number already exists.")
                    return;
                  }
                }
    
              })

        } catch (error) {
            if (error.response && error.response.status === 401) {
                toast("Your session is expired, please login again");
            }
        }
    };

    useEffect(() => {
        if (phone && !phone.startsWith("+") && phone.length > 4) {
            toast("Phone number should contain country code, e.g., +46...");
        } else {
            setMessage("");
        }
    }, [phone]);

    return (
        <>
            <div class="d-sm-flex align-items-center justify-content-between my-3">
                <h3 class="page-title">
                    <span class="page-title-icon bg-gradient-primary text-white me-2">
                        <i><FontAwesomeIcon icon={faUsers} /></i>
                    </span> Add User
                </h3>
                <div className="d-flex justify-content-end align-items-center">
                    <Link className="p-2 rounded bg-gradient-success text-white me-2 shadow-sm" to="/dashboard/all-users" style={{ cursor: "pointer" }}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </Link>
                </div>
            </div>

            <div class="col-12">
                <div class="card shadow" style={{ padding: "1rem" }}>
                    <div class="card-body">
                        <form class="form-sample">
                            <div class="row mb-3">
                                <div class="col-md-6">
                                    <div class=" mb-0 p-1 row">
                                        <TextField value={firstName}
                                            onChange={(e) => setFirstName(e.target.value)}
                                            type="text"
                                            label="First Name" />

                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-0 p-1 row">
                                        <TextField value={lastName}

                                            onChange={(e) => setLastName(e.target.value)}
                                            type="text"
                                            label="Last Name" />
                                    </div>
                                </div>
                            </div>


                            <div class="row mb-3">
                                <div class="col-md-6">
                                    <div class=" mb-0 p-1 row">
                                        <TextField value={email}

                                            onChange={(e) => setEmail(e.target.value)}
                                            type="email"
                                            label="Email" />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-0 p-1 row">
                                        <PhoneInput
                                            className="form-control"
                                            style={{ height: "3.5rem" }}
                                            defaultCountry="SE"
                                            value={phone}
                                            onChange={setPhone}
                                            limitMaxLength={true}
                                            placeholder={"Mobile Number"}
                                        />
                                    </div>
                                </div>
                            </div>








                            <div className="d-flex justify-content-center mt-5">
                                <button type="button" onClick={handleAddUser} class="btn btn-gradient-primary">Submit</button>
                            </div>

                        </form>


                    </div>
                </div>
            </div >
        </>
        
    );
}

export default AddUserForm;
