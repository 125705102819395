import React, { useState, useEffect } from "react";
import axios from "axios";
import { selectUserType } from "../../redux/slicer";
import { useSelector } from "react-redux";
import { AiFillDelete } from "react-icons/ai";
import CircularProgress from "@mui/material/CircularProgress";
import { TbArrowsSort } from "react-icons/tb";
import PaginationComponent from "../PaginationComponent";
import { toast } from "react-toastify";
import stockholmDate from "../dateformatConvert";
import { useNavigate } from "react-router-dom";
import FilterForm from "../../filter/FilterForm";
import { formatStops, queryParams } from "../../../shared/utils";
import MapInfoModal from "../../map/MapInfoModal";


const AvailableridesCard = () => {
  const [rideseData, setRidesData] = useState({ results: [] });
  const [IsLoading, setIsLoading] = useState(false);
  const [IsAssign, setIsAssign] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const ci = localStorage.getItem("A_companyinfo");
  const [orderBy, setOrderBy] = useState('-ride_date');
  const [isFilterLoading, setIsFilterLoading] = useState(false);
  const [mapOpen, setMapOpen] = useState(false)
  const [rideInfo, setMapRideInfo] = useState({
    origin: null,
    destination: null,
    via: null
  })


  const accessToken = localStorage.getItem("Key");

  let ccid = null;
  if (ci !== "undefined" && ci !== "null") {
    ccid = JSON.parse(ci.replace(/\\/g, "")).company_id;
  }
  const nav = useNavigate();
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleGetRidesData = async () => {
    try {
      setIsLoading(true);
      const accessToken = localStorage.getItem("Key");
      const resp = await axios
        .get(
          `${process.env.REACT_APP_BASE_URL}all-available-rides/?paginate=true&page=${currentPage}&order_by=${orderBy}`,
          {
            headers: {
              Authorization: "Bearer " + accessToken,
            },
          }
        )
        .then((response) => {
          let results = response.data;
          setRidesData(results);
          return response.data;
        });

      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const handleCompanyAssign = async (id) => {
    try {
      setIsLoading(true);
      const data = {
        company_id: ccid
      }
      const accessToken = localStorage.getItem("Key");
      const resp = await axios
        .put(
          `${process.env.REACT_APP_BASE_URL}update-rides-company/${id}`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + accessToken,
            },
          }
        )
        .then((response) => {
          toast("successfully self assign ride")
          setIsAssign(true)
          return response.data;
        });

      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSortRideDate = () => {
    if (orderBy === "-ride_date") {
      setOrderBy('ride_date')
    } else {
      setOrderBy('-ride_date')
    }
  }
  const handleSortBookDate = () => {
    if (orderBy === "-created_date") {
      setOrderBy('created_date')
    } else {
      setOrderBy('-created_date')
    }
  }



  useEffect(() => {
    handleGetRidesData();
  }, [currentPage, IsAssign, orderBy]);

  console.log(rideseData, "ridedata");

  const ridesFields = [
    { name: 'name', label: 'Name' },
    { name: 'mobile_number', label: 'Mobile Number', type: 'number' },
    { name: 'start_date', label: 'Start Date', type: 'date' },
    { name: 'end_date', label: 'End Date', type: 'date' },
    { name: 'pickuplocation', label: 'Pickup Location' },
    { name: 'droplocation', label: 'Drop Location' },
  ];

  const handleRidesFilter = (data) => {
    const params = queryParams(data)
    setIsFilterLoading(true)
    axios.get(`${process.env.REACT_APP_BASE_URL}filter-available-rides/?${params}&paginate=true`, {
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    }).then((res) => {
      res.data.msg ? toast.error(res.data.msg) : setRidesData(res.data)
      setIsFilterLoading(false)
    }).catch((err) => {
      setIsFilterLoading(false)
      toast.error(err);
    })
  };

  const handleClearFilters = () => handleGetRidesData();
  const showFilter = useSelector((state) => state.showFilter.showFilter);
  const handleMapOpen = (ride) => {
    setMapOpen(true)
    setMapRideInfo({ origin: ride.pickup_location, destination: ride.drop_location, via: formatStops(ride.stops) })
  }


  return (
    <>
      <MapInfoModal isOpen={mapOpen} onClose={() => setMapOpen(false)} title="Map" origin={rideInfo.origin} destination={rideInfo.destination} via={rideInfo.via} />

      {showFilter && <FilterForm
        fields={ridesFields}
        onFilter={handleRidesFilter}
        onClearFilters={handleClearFilters}
        isLoading={isFilterLoading}
      />}


      {IsLoading ?
        <div className="d-flex justify-content-center align-items-center my-5 py-5">
          <CircularProgress />
        </div>
        :

        <>{
          rideseData.results.map((ride, i) => {
            return (
              <div className="card shadow mb-3 grid-margin stretch-card" key={i}>
                <div className="card-body">
                  <h5 className="mb-3"><strong>Ride {ride.id}</strong></h5>
                  <div className="row">
                    <div className="col-md-4">

                      <p><strong>From</strong><br /> <span className="text-dark">{ride.pickup_location}</span></p>
                      <p><strong>To</strong><br />  <span className="text-dark">{ride.drop_location}</span></p>
                      <p><strong>Stops</strong><br /> <span className="text-dark">{ride.stops &&
                        ride.stops.length !== 0 ? (
                        <span>{formatStops(ride.stops)}</span>
                      ) : (
                        <span>No Stop</span>
                      )}</span></p>
                    </div>
                    <div className="col-md-4">
                      <p><strong>Booking Date & Time</strong><br />  <span className="text-dark">{stockholmDate(ride.datetime)}</span></p>
                      <p><strong>Ride Date & Time</strong><br /> <span className="text-dark">{stockholmDate(ride.created_date)}</span></p>
                      <p><strong>Rider Name</strong><br />  <span className="text-dark">{ride.created_for_name}</span></p>
                      <p><strong>Rider Phone</strong><br /> <span className="text-dark">{ride.created_for_phone}</span></p>
                      

                    </div>
                    <div className="col-md-4">
                      <p><strong>Fare</strong><br /> <span className="text-dark">{ride.fare} S.E.K</span></p>
                      <p><strong>Distance</strong><br />  <span className="text-dark">{ride.distance} KM</span></p>
                      <p><strong>Car Type</strong><br />  <span className="text-dark">{ride.car_type}</span></p>
                    </div>
                  </div>
                  <div className="mt-3">

                    <button
                      className="btn btn-gradient-info btn-sm mx-2 my-1"
                      onClick={() => handleMapOpen(ride)}
                    >
                      Route
                    </button>
                    <button className="btn btn-gradient-success  btn-sm mx-2 my-1" onClick={() => handleCompanyAssign(ride.id)}>Self Assign</button>

                    {ride.driver_assigned ? (
                      <button
                        className="btn btn-gradient-info  btn-sm mx-2 my-1"
                        onClick={() =>
                          nav("/dashboard/assigndrivercar", {
                            state: {
                              id: {
                                id: ride.id,
                                type: ride.car_type,
                              },
                            },
                          })
                        }
                      >
                        ReAssign
                      </button>

                    ) : (

                      <button
                        className="btn btn-gradient-primary  btn-sm mx-2 my-1"
                        onClick={() =>
                          nav("/dashboard/assigndrivercar", {
                            state: {
                              id: {
                                id: ride.id,
                                type: ride.car_type,
                              },
                            },
                          })
                        }
                      >
                        Assign Driver
                      </button>
                    )}



                  </div>
                </div>
              </div>
            )
          })
        }</>
      }

      <div className="my-3">
        <PaginationComponent
          currentPage={currentPage}
          totalPages={rideseData.total_pages}
          onPageChange={handlePageChange}
        />
      </div>
    </>
  );
};

export default AvailableridesCard;
