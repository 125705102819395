import React from "react"
import stockholmDate from "../../cards/dateformatConvert"
import { formatStops } from "../../../shared/utils"


const RecieptTemplate = ({ data }) => {
    return (
        <div className="card container m-0 p-0">
            <div className="card-body m-0 p-0">

                <div className="row mb-3">
                    <div className="col md-12">
                        <div className="d-flex justify-content-between align-items-center">
                            <h1 class="text-secondary" style={{ fontWeight: 800, fontSize: "3rem" }}>Trixi Taxi</h1>
                            <h2><strong>Reciept</strong></h2>
                        </div>
                    </div>
                </div>

                <div className="mb-5 address-info">
                    <div>
                        <div><strong>From:</strong></div>
                        <div style={{ width: "75%" }}>
                            <p className="m-0" ><strong>{data.company.name}</strong>,</p>
                            <p className="m-0">{data.company.address},</p>
                            <p className="m-0">Phone: {data.company.phone_number},</p>
                            <p className="m-0">Email: {data.company.organization_email}</p>
                        </div>
                    </div>
                    <div>
                        <div><strong>To:</strong></div>
                        <div style={{ width: "75%" }}>
                            <p className="m-0"><strong>Multitech</strong>,</p>
                            <p className="m-0">Stockholm, Sweden</p>
                            <p className="m-0">Phone:+4678712638767,</p>
                            <p className="m-0">Email: company@multitech.com</p>
                        </div>
                    </div>
                </div>

                <div className='reciept-info mb-5'>
                    <div className="reciept-info-temp">
                        <h6><strong>Reciept Number</strong></h6>
                        <h6>RT{data.invoice.recieptId}</h6>
                    </div>

                    <div className="reciept-info-temp">
                        <h6><strong>Company Name</strong></h6>
                        <h6>Multitech</h6>
                    </div>
                    <div className="reciept-info-temp">
                        <h6><strong>Paid On</strong></h6>
                        <h6>{stockholmDate(data.invoice.paiddate)}</h6>
                    </div>

                </div>

                <div className="row">
                    <div className="col-md-12">
                        <table style={{ pageBreakInside: 'avoid', tableLayout: "auto" }}>
                            <thead>
                                <tr>
                                    <th scope="col" style={{ width: "10%" }}>Id</th>
                                    <th scope="col" style={{ width: "40%" }}>Ride</th>
                                    <th scope="col" style={{ width: "15%" }}>Date</th>
                                    <th scope="col" style={{ width: "10%" }}>Fare</th>
                                    <th scope="col" style={{ width: "10%" }}>Earning Amount(7%)</th>
                                    <th scope="col" style={{ width: "10%" }}>Payable Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.rides.map((el, i) => {
                                    return (
                                        <tr key={i} style={{ pageBreakInside: 'avoid', height: "100px" }} >
                                            <th scope="row">{el.id}</th>
                                            <td>From <strong>{el.picup_location_name}</strong> to <strong>{el.drop_location_name}</strong>
                                                {el.stops.length !== 0 ? <span>&nbsp via <strong>{formatStops(el.stops)}</strong></span> : null}
                                            </td>
                                            <td>{stockholmDate(el.ride_date)}</td>
                                            <td>SEK {el.fare}</td>
                                            <td>SEK {(parseFloat(el.fare) * 0.07).toFixed(2)}</td>
                                            <td>SEK {parseFloat(el.fare) - (parseFloat(el.fare) * 0.07).toFixed(2)}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>

                    </div>
                </div>
                <hr className="mb-4 mt-0" />

                <div className="row my-5">
                    <div className="col-md-12">
                        <div className="d-flex justify-content-end">
                            <div className="row w-75">
                                <div className="col-md-8 text-end font-weight-bold">Total Fare:</div>
                                <div className="col-md-4 font-weight-bold">SEK {data.invoice.amount}</div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end">
                            <div className="row w-75">
                                <div className="col-md-8 text-end font-weight-bold">Total Amount Earned:</div>
                                <div className="col-md-4 font-weight-bold">SEK {data.invoice.earning_amount}</div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end">
                            <div className="row w-75">
                                <div className="col-md-8 text-end font-weight-bold">Total Payed Amount:</div>
                                <div className="col-md-4 font-weight-bold text-success">SEK {data.invoice.amount_to_pay}</div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        // <div className="card container" style={{ padding: '20px', border: "none" }}>
        //     <div className="card-body" >
        //         <div className="p-3">
        //             <div className="row mb-3">
        //                 <div className="col md-12">
        //                     <div className="d-flex justify-content-between align-items-center">
        //                         <h1 class="text-secondary" style={{ fontWeight: 800, fontSize: "3rem" }}>Trixi Taxi</h1>
        //                         <h2><strong></strong></h2>
        //                     </div>
        //                 </div>
        //             </div>

        //             <div className="row mb-5">
        //                 <div className="col-md-12">
        //                     <div className='d-flex justify-content-between align-items-center'>
        //                         <div className="w-100">
        //                             <div><strong>From:</strong></div>
        //                             <div style={{ width: "75%" }}>
        //                                 <p className="m-0"><strong>{data.company.name}</strong>,</p>
        //                                 <p className="m-0">{data.company.address},</p>
        //                                 <p className="m-0">Phone: {data.company.phone_number},</p>
        //                                 <p className="m-0">Email: {data.company.organization_email}</p>
        //                             </div>
        //                         </div>
        //                         <div className="w-100">
        //                             <div><strong>To:</strong></div>
        //                             <div style={{ width: "75%" }}>
        //                                 <p className="m-0"><strong>Multitech</strong>,</p>
        //                                 <p className="m-0">Stockholm, Sweden</p>
        //                                 <p className="m-0">Phone:+4678712638767,</p>
        //                                 <p className="m-0">Email: company@multitech.com</p>
        //                             </div>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>

        //             <div className="row mb-5">
        //                 <div className="col-md-12">
        //                     <div className='row d-flex justify-content-between align-items-center'>
        //                         <div className="col-md-4">
        //                            
        //                         </div>

        //                         <div className="col-md-4">
        //                            
        //                         </div>
        //                         <div className="col-md-4">
        //                            
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>

        //             <div className="row">
        //                 <div className="col-md-12">
        //                 <table class="table" style={{ pageBreakInside: 'avoid' }}>
        //                         <thead class="thead-dark">
        //                             <tr>
        //                                 <th scope="col">Id</th>
        //                                 <th scope="col">Ride</th>
        //                                 <th scope="col">Date</th>
        //                                 <th scope="col">Fare</th>
        //                                 <th scope="col">Earning Amount(7%)</th>
        //                                 <th scope="col">Paid Amount</th>
        //                             </tr>
        //                         </thead>
        //                         <tbody>
        //                             {data.rides.map((el, i) => {
        //                                 return (
        //                                     <tr key={i} style={{ pageBreakInside: 'avoid' }}>
        //                                         <th scope="row">{el.id}</th>
        //                                         <td>From <strong>{el.picup_location_name}</strong> to <strong>{el.drop_location_name}</strong>
        //                                             {el.stops.length !== 0 ? <span>&nbsp via <strong>{el.stops.length !== 0}</strong></span> : null}
        //                                         </td>
        //                                         <td>{stockholmDate(el.ride_date)}</td>
        //                                         <td>SEK {el.fare}</td>
        //                                         <td>SEK {(parseFloat(el.fare) * 0.07).toFixed(2)}</td>
        //                                         <td>SEK {parseFloat(el.fare) - (parseFloat(el.fare) * 0.07).toFixed(2)}</td>
        //                                     </tr>
        //                                 )
        //                             })}
        //                         </tbody>
        //                     </table>

        //                 </div>
        //             </div>
        //             <hr className="mb-4 mt-0" />

        //             <div className="row my-5">
        //                 <div className="col-md-12">
        //                     <div className="d-flex justify-content-end">
        //                         <div className="row w-50">
        //                             <div className="col-md-8 font-weight-bold">Total Fare:</div>
        //                             <div className="col-md-4 font-weight-bold">SEK {data.invoice.amount}</div>
        //                         </div>
        //                     </div>
        //                     <div className="d-flex justify-content-end">
        //                         <div className="row w-50">
        //                             <div className="col-md-8 font-weight-bold">Total Amount Earned:</div>
        //                             <div className="col-md-4 font-weight-bold">SEK {data.invoice.earning_amount}</div>
        //                         </div>
        //                     </div>
        //                     <div className="d-flex justify-content-end">
        //                         <div className="row w-50">
        //                             <div className="col-md-8 font-weight-bold">Total Paid Amount:</div>
        //                             <div className="col-md-4 font-weight-bold text-success">SEK {data.invoice.amount_to_pay}</div>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>
    )
}

export default RecieptTemplate