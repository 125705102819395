import { TextField } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import React, { useState } from 'react';
import dayjs from 'dayjs';

const FilterForm = ({ fields, onFilter, isLoading, onClearFilters }) => {
    const initialFilterData = {};

    fields.forEach((field) => {
        initialFilterData[field.name] = '';
    });

    const [filterData, setFilterData] = useState(initialFilterData);
    console.log(filterData)
    const handleInputChange = (name, value, ftype) => {
        if (ftype === "date"){
            const desiredFormat = "YYYY-MM-DD HH:mm:ss";
            value = value.format(desiredFormat);
        }
        setFilterData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onFilter(filterData);
    };

    const handleClearFilters = () => {
        onClearFilters();
        const isAnyFilterPresent = Object.values(filterData).some((value) => value !== '');
        if (isAnyFilterPresent) setFilterData(initialFilterData);
    };


    return (
        <div className="card shadow mb-3">
            <div className="card-body">
                <form onSubmit={handleSubmit}>
                    <div className="row g-3 align-items-center">
                        {fields.map((field) => (
                            <div className={`mb-3 col-md-${field.col || 4}`} key={field.name}>
                                {field.type === "date" &&
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer
                                        components={[
                                            'DateTimePicker',
                                        ]}
                                    >

                                        <DemoItem>
                                            <DateTimePicker slotProps={{ textField: { size: 'small' } }} label={field.label} onChange={(data) => handleInputChange(field.name, data, field.type)}
                                                value={filterData[field.name] === ''?null:dayjs(filterData[field.name])}
                                            />
                                        </DemoItem>
                                    </DemoContainer>
                                </LocalizationProvider>}
                                {field.type !== "date" && <TextField
                                    sx={{ marginTop: "8px" }}
                                    fullWidth
                                    size='small'
                                    label={field.label}
                                    type={field.type || 'text'}
                                    name={field.name}
                                    value={filterData[field.name]}
                                    onChange={(e) => handleInputChange(e.target.name, e.target.value, field.type)}
                                />}

                            </div>
                        ))}
                    </div>
                    <div className="d-flex mt-3 justify-content-center">
                        <button
                            type="submit"
                            className="btn btn-gradient-primary btn-sm me-2"
                            disabled={isLoading}
                        >
                            {isLoading ? 'Loading...' : 'Apply Filters'}
                        </button>
                        <button
                            type="button"
                            className="btn btn-gradient-light btn-sm ml-4"
                            onClick={handleClearFilters}
                            disabled={!Object.values(filterData).some((value) => value !== '') || isLoading}
                        >
                            Clear Filters
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default FilterForm;
