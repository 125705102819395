import React, { useState } from "react";
import * as XLSX from "xlsx";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faArrowDownWideShort, faArrowUpWideShort, faDownload, faHamburger } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { setUpdateShowFilter } from "../../../redux/filterState";
import CircularProgress from '@mui/material/CircularProgress';



const DasboarTopComp = (props) => {
  const call = props.downloadCall;
  const selectedIds = props.selectedIds
  const [isLoading, setIsLoading] = useState(false);
  const userType = localStorage.getItem("A_usertype");
  const dispatch = useDispatch();
  const showFilter = useSelector((state) => state.showFilter.showFilter);
  const handleUpdateShowFilter = () => {
    dispatch(setUpdateShowFilter());
    console.log(showFilter)
  };
  const handleGetData = async () => {
    setIsLoading(true);
    try {
      const accessToken = localStorage.getItem("Key");
      let url = "";
      if (call === "ride") {
        url = `${process.env.REACT_APP_BASE_URL}all-rides/`;
      } else if (call === "myride") {
        url = `${process.env.REACT_APP_BASE_URL}my-rides/`;
      } else if (call === "driver") {
        url = userType === "ADMIN_USER" ? `${process.env.REACT_APP_BASE_URL}all-driver/` : `${process.env.REACT_APP_BASE_URL}my-drivers/`;
      } else if (call === "company") {
        url = `${process.env.REACT_APP_BASE_URL}all-company/`;
      } else if (call === "employee") {
        url = userType === "ADMIN_USER" ? `${process.env.REACT_APP_BASE_URL}all-employee/` : `${process.env.REACT_APP_BASE_URL}my-employee/`;
      } else if (call === "users") {
        if(selectedIds.length !== 0){
          console.log(selectedIds)
          url = `${process.env.REACT_APP_BASE_URL}all-users/?selectedId=${selectedIds}&ride=true`;
          
        }else{
          url = `${process.env.REACT_APP_BASE_URL}all-users/`;
        }
        
      } else if (call === "cars") {
        url = userType === "ADMIN_USER" ? `${process.env.REACT_APP_BASE_URL}all-car/` : `${process.env.REACT_APP_BASE_URL}my-cars/`;
      } else if (call === "report") {
        url = `${process.env.REACT_APP_BASE_URL}all-report/`;

      }
      const resp = await axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        })
        .then((response) => {
          return response;
        });
      let results = resp.data;
      console.log(results, 'asdfas')
      if (call === "myride") {
        results = resp.data.data[0].past_bookings.concat(resp.data.data[0].upcoming_bookings)
      } else if (call === "driver" || call === "employee" || call === "report" || (call === "cars" && userType === "COMPANY_USER")) {
        results = results.data
      }
      const workbook = XLSX.utils.book_new();
      const sheetData = XLSX.utils.json_to_sheet(results);
      XLSX.utils.book_append_sheet(workbook, sheetData, "Sheet1");
      XLSX.writeFile(workbook, call + ".xlsx");
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const handleClick = (e) => {
    e.preventDefault();
    handleGetData();
  };
  return (
    <>
      <div class="d-sm-flex align-items-center justify-content-between mb-3">
          <h3 class="page-title">
            <span class="page-title-icon bg-gradient-primary text-white me-2">
              <i><FontAwesomeIcon icon={props.headerIcon} /></i>
            </span> {props.headerDash}
          </h3>

          <div className="d-flex justify-content-end align-items-center">
            {
              props.addUrl &&
              <Link className="p-2 rounded bg-gradient-success text-white me-2 shadow-sm" to={props.addUrl} style={{ cursor: "pointer" }}>
                <FontAwesomeIcon icon={faAdd} />
              </Link>
            }



            {
              showFilter ?
                <div className="p-2 rounded bg-gradient-primary text-white me-2 shadow-sm" onClick={() => handleUpdateShowFilter()} style={{ cursor: "pointer" }}>
                  <FontAwesomeIcon icon={faArrowUpWideShort} />
                </div>
                :
                <div className="p-2 rounded bg-gradient-primary text-white me-2 shadow-sm" onClick={() => handleUpdateShowFilter()} style={{ cursor: "pointer" }}>
                  <FontAwesomeIcon icon={faArrowDownWideShort} />
                </div>

            }
            {isLoading ?
              <div className="p-2 rounded bg-gradient-info text-white me-2 shadow-sm" style={{ cursor: "not-allowed" }}>
                <CircularProgress
                  size={"1rem"}
                  sx={{ color: "#fff" }}
                />
              </div>

              :
              <>
                {
                  call &&
                  <div className="p-2 rounded bg-gradient-info text-white me-2 shadow-sm" onClick={handleClick} style={{ cursor: "pointer" }}>
                    <FontAwesomeIcon icon={faDownload} />
                  </div>
                }
              </>
            }


            {/* {isLoading ? (
              <button
                disabled={true}
                class="btn btn-gradient-primary shadow"
              >
                <FontAwesomeIcon icon={faDownload} /> &nbsp;Downloading ...
              </button>
            ) : (
              <button
                onClick={handleClick}
                class="btn btn-gradient-primary shadow"
              >
                <FontAwesomeIcon icon={faDownload} /> &nbsp;Generate Report
              </button>

            )} */}
          </div>
        </div>
    </>
  );
};

export default DasboarTopComp;
