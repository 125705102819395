import React from "react";
import SidebarComp from "../../components/sidebar/sidebar";
import NavbarComp from "../../components/navbar/navbar";
import Billing from "../../components/body/billing/Billing";

const BillingScreen = () => {
  return (
    <div className="container-scroller">
            <NavbarComp />
            <div className="container-fluid page-body-wrapper">
                <SidebarComp />
                <div className="main-panel">
                    <div className="content-wrapper">
                        <Billing />
                    </div>
                </div>
            </div>
        </div>
  );
};

export default BillingScreen;
