import React from "react";
import SidebarComp from "../../components/sidebar/sidebar";
import NavbarComp from "../../components/navbar/navbar";
import AddCarMainComp from "../../components/body/car/addcarcomp/addcarcomp";

const AddCarScreen = () => {
    return (
        <div className="container-scroller">
            <NavbarComp />
            <div className="container-fluid page-body-wrapper">
                <SidebarComp />
                <div className="main-panel">
                    <div className="content-wrapper">
                        <AddCarMainComp />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddCarScreen;