import React from 'react'

const Notification = () => {
    return (
        <div class="card shadow mb-4">
            <div class="card-body">
Notification
            </div>
        </div>
    )
}

export default Notification