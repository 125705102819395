import axios from 'axios';
import React, { useState } from 'react'
import { baseURL } from '../../../../config';
import { toast } from 'react-toastify';

const PayButton = ({company, handlesetPaymentUpdate}) => {
    const userType = localStorage.getItem("A_usertype");
    const accessToken = localStorage.getItem("Key");
    const [paymentLoading, setPaymentLoading] = useState(false);
    const handleOnPay = async (id) => {
        setPaymentLoading(true);
        if (userType === "ADMIN_USER") {
            axios.put(`${baseURL}pay-invoice/${id}`, {},{
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "multipart/form-data",
                },
            }).then((response) => {
                setPaymentLoading(false);
                handlesetPaymentUpdate(true)
                toast("Payment Successfull")
            }).catch((err) => console.log("Error-->", err))
            .finally(()=>{
                setPaymentLoading(false);
            })
        }
    };

    return (
        <>

        {paymentLoading?
                <button
                title="work under construction" // TODO: remove this
                className="btn btn-gradient-danger btn-sm mx-2 my-1"
                disabled
            >
                    Paying...
    
            </button>:
                <button
                title="work under construction" // TODO: remove this
                className="btn btn-gradient-success btn-sm mx-2 my-1"
                disabled={company.is_payed}
                onClick={() => handleOnPay(company.id)}
            >
                    {company.is_payed ? 'Payed' : 'Pay'}
    
            </button>
        }
        
        </>

    )
}

export default PayButton