import React, { useEffect, useState } from "react";
import axios from "axios";
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";
import FilterForm from "../../filter/FilterForm";
import { queryParams } from "../../../shared/utils";

import PaginationComponent from "../PaginationComponent";
import DeleteConfirmationModel from "../deleteConfirmationModel";
import { useSelector } from "react-redux";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CarCards = () => {
  const nav = useNavigate();
  const userType = localStorage.getItem("A_usertype");
  const showFilter = useSelector((state) => state.showFilter.showFilter);
  const [carData, setCarData] = useState({ results: [] });
  const [update, setUpdate] = useState(false);
  const [isRemoveAssign, setIsRemoveAssign] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [isFilterLoading, setIsFilterLoading] = useState(false);
  const [showModel, setShowModel] = useState({
    "show": false,
    "id": null
  });

  const handleShowModel = (id) => {
    setShowModel({
      "show": true,
      "id": id
    })
  }
  const handleCloseModel = () => {
    setShowModel({
      "show": false,
      "id": null
    })
  }


  const accessToken = localStorage.getItem("Key");

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleGetCarData = async () => {
    const endpoint = userType === "ADMIN_USER" ? "all-car" : "my-cars";
    setIsLoading(true);
    try {
      const accessToken = localStorage.getItem("Key");
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}${endpoint}/?paginate=true&page=${currentPage}`,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      let results = response.data;
      setCarData(results);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    handleGetCarData();
  }, [update, currentPage, isRemoveAssign]);

  const handleDeleteCar = async (id) => {
    handleCloseModel()
    setIsLoading(true);
    try {
      const accessToken = localStorage.getItem("Key");
      const url =
        `${process.env.REACT_APP_BASE_URL}` + "retrive-delete-car" + "/" + id;
      const response = await axios.delete(url, {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      });

      if (response.status === 204) {
        toast("Car is deleted");
        setUpdate(!update);
      }
    } catch (error) {
      console.log(error);
      toast("Something went wrong, please try again later");
    }
    setIsLoading(false);
  };


  const ridesFields = [
    { name: 'car_model', label: 'Car Model' },
    { name: 'car_type', label: 'Car Type' },
  ];

  const handleRidesFilter = (data) => {
    const params = queryParams(data)
    setIsFilterLoading(true)
    axios.get(`${process.env.REACT_APP_BASE_URL}filter-all-cars/?${params}`, {
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    }).then((res) => {
      res.data.msg ? toast.error(res.data.msg) : setCarData(res.data)
      setIsFilterLoading(false)
    }).catch((err) => {
      setIsFilterLoading(false)
      toast.error(err);
    })
  };

  const handleClearFilters = () => handleGetCarData();

  return (
    <>
      <DeleteConfirmationModel showModel={showModel} onHandleDelete={handleDeleteCar} handleCloseModel={handleCloseModel}></DeleteConfirmationModel>
      {showFilter && <FilterForm
        fields={ridesFields}
        onFilter={handleRidesFilter}
        onClearFilters={handleClearFilters}
        isLoading={isFilterLoading}
      />}




      {isLoading ?
        <div className="d-flex justify-content-center align-items-center my-5 py-5">
          <CircularProgress />
        </div>
        :

        <>{
          carData.results.map((el, i) => {
            return (
              <div className="card shadow mb-3 grid-margin stretch-card" key={i}>
                <div className="card-body">
                  <h6><span className="text-dark font-weight-bold mr-1">{el.car_model} </span> ({el.registration_number})</h6>
                  <div className="row">
                    <div className="col-md-5">
                      <p className="text-dark mb-1">
                        {el.car_type.map((elem, i) => {
                          return (
                            <span>
                              {elem}
                              {i !== el.car_type.length - 1 && ", "}
                            </span>
                          );
                        })}
                      </p>
                      <p className="text-dark mb-1"><FontAwesomeIcon icon={faUser} className="me-2 text-gray" />{el.driver_name ? el.driver_name : "No Driver Assign"}</p>

                    </div>
                    <div className="col-md-5">
                      {el.added_document_urls.length !== 0 ? (
                        <div className="row">
                          {el.added_document_urls.map((elem, i) => {
                            return (
                              <div className="col-12">
                                <a
                                  href={elem.document_url}
                                  className="fa fa-download"
                                  target="_blank"
                                  style={{ cursor: "pointer" }}
                                >
                                  View Document {i + 1}
                                </a>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        "No Documents"
                      )}
                    </div>
                    <div className="col-md-2">
                      <Link
                        className="my-2 me-2 btn-sm btn btn-gradient-primary"
                        style={{ cursor: "pointer" }}
                        to={"/dashboard/edit-car/" + el.id}
                      >
                        Edit
                      </Link>

                      {userType === "ADMIN_USER" && <button
                        className="my-2 btn-sm btn btn-gradient-danger"
                        onClick={() => handleShowModel(el.id)}
                      >
                        Delete
                      </button>}
                    </div>

                  </div>


                </div>
              </div>
            )
          })
        }</>
      }


      <PaginationComponent
        currentPage={currentPage}
        totalPages={carData.total_pages}
        onPageChange={handlePageChange}
      />
    </>
  );
};

export default CarCards;
