import React, { useEffect, useState } from "react";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { baseURL } from "../../../config";
import PaginationComponent from "../../cards/PaginationComponent";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PayButton from "./buttons/pay";
import { faFileInvoice } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Billing = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [paymentDone, setPaymentDone] = useState(false);
  const nav = useNavigate()
  const [companyExpense, setCompanyExpense] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const userType = localStorage.getItem("A_usertype");
  const accessToken = localStorage.getItem("Key");

  const getCompanyAmount = async () => {
    setIsLoading(true);
    if (userType === "ADMIN_USER") {
      axios.get(`${baseURL}company-amount/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }).then((response) => {
        setIsLoading(false);
        setCompanyExpense(response.data)
      }).catch((err) => console.log("Error-->", err))
    }
  };

  useEffect(() => {
    getCompanyAmount()
  }, [paymentDone])

  const handlesetPaymentUpdate = () => setPaymentDone(Date.now())

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);


  return (
    <>
      <div class="d-sm-flex align-items-center justify-content-between mb-3">
        <h3 class="page-title">
          <span class="page-title-icon bg-gradient-primary text-white me-2">
            <i><FontAwesomeIcon icon={faFileInvoice} /></i>
          </span> Billing & Earnings
        </h3>
      </div>

      {isLoading ?
        <div className="d-flex justify-content-center align-items-center my-5 py-5">
          <CircularProgress />
        </div>
        :

        <>{
          // <td>{company.name}</td>
          //                                       <td>{company.vat_no || 'n/a'}</td>
          //                                       <td>{company.bankaccount || 'n/a'}</td>
          //                                       <td>{company.}</td>
          //                                       <td>{company.}</td>
          //                                       <td>{company.}</td>
          //                                       <td>{company.} S.E.K</td>
          //                                       <td>{company.} S.E.K</td>
          //                                       <td><button
          //                                           className="btn btn-primary"
          //                                           onClick={() =>
          //                                               nav(``)
          //                                           }
          //                                       >
          //                                           view
          //                                       </button></td>

          //                                       <td>
          //                                           
          //                                       </td>
          companyExpense.results.map((el, i) => {
            return (
              <div className="card shadow mb-3 grid-margin stretch-card" key={i}>
                <div className="card-body">
                  <h5 className="mb-3"><strong>Company {el.id}</strong></h5>
                  <div className="row">
                    <div className="col-md-4">

                      <p><strong>Name</strong><br /> <span className="text-dark">{el.name}</span></p>
                      <p><strong>Earning Amount</strong><br /> <span className="text-success">{el.earning_amount} SEK</span></p>
                      <p><strong>Payable Amount</strong><br /> <span className="text-danger">{el.amount_to_pay} SEK</span></p>

                    </div>
                    <div className="col-md-4">
                      <p><strong>VAT Number</strong><br /> <span className="text-dark">{el.vat_no || 'n/a'}</span></p>
                      <p><strong>Bank Account</strong><br /> <span className="text-dark"> {el.bankaccount || 'n/a'}</span></p>
                      <p><strong>Bank Giro Number</strong><br /> <span className="text-dark">{el.bankgironumber || 'n/a'}</span></p>

                    </div>
                    <div className="col-md-4">
                      <p><strong>BIC/Swift Code</strong><br /> <span className="text-dark"> {el.bic_or_swift_code || 'n/a'}</span></p>
                      <p><strong>IBAN</strong><br /> <span className="text-dark"> {el.iban || 'n/a'}</span></p>
                    </div>
                  </div>
                  <div className="mt-3">

                    <Link to={`/dashboard/transaction/${el.id}`} className="btn btn-gradient-primary btn-sm mx-2 my-1" >
                      View
                    </Link>
                    <PayButton company={el} handlesetPaymentUpdate={handlesetPaymentUpdate} />

                  </div>
                </div>
              </div>
            )
          })
        }</>
      }



      <div className="my-3">
        <PaginationComponent
          currentPage={currentPage}
          totalPages={companyExpense?.total_pages}
          onPageChange={handlePageChange}
        />
      </div>


    </>
    // <div className="container-fluid mt-4">
    //     <div className="d-sm-flex align-items-center justify-content-between mb-4">
    //         <h1 className="mx-2 h3 mb-0 text-gray-800">Billing and Earnings</h1>
    //     </div>
    //     <div className="card shadow my-4 mx-2">
    //         <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
    //             <h6 className="m-0 font-weight-bold text-primary">
    //                 Company
    //             </h6>
    //         </div>
    //         <div className="card-body">
    //             <div className="table-responsive">
    //                 <table
    //                     className="table table-bordered"
    //                     id="dataTable"
    //                     width="100%"
    //                     cellSpacing="0"
    //                 >
    //                     <thead>
    //                         <tr>
    //                             <th>Name</th>
    //                             <th>VAT Number</th>
    //                             <th>Bank Account</th>
    //                             <th>Bank Giro Number</th>
    //                             <th>BIC/Swift Code</th>
    //                             <th>IBAN</th>
    //                             <th>Earning Amount</th>
    //                             <th>Payable Amount</th>
    //                             <th>Transactions</th>
    //                             <th>Action</th>
    //                         </tr>
    //                     </thead>
    //                     <tfoot>
    //                         <tr>
    //                            
    //                         </tr>
    //                     </tfoot>
    //                     {isLoading ? (
    //                         <tbody>
    //                             <tr>
    //                                 <td colSpan="10" className="text-center">
    //                                     <CircularProgress />
    //                                 </td>
    //                             </tr>
    //                         </tbody>
    //                     ) : (
    //                         <tbody>
    //                             {companyExpense?.results?.length !== 0 ?
    //                                 <>
    //                                     {companyExpense?.results?.map((company, i) => (
    //                                         <tr key={i}>
    //                                             
    //                                         </tr>
    //                                     ))}
    //                                 </> : <tr>
    //                                     <td colSpan={10} className="text-center">No companies</td>
    //                                 </tr>}
    //                         </tbody>
    //                     )
    //                     }
    //                 </table>
    //                 
    //             </div>

    //             {/* {true && (
    //                 <div className="modal" style={{ display: 'block', zIndex: '1040', backgroundColor: "rgba(0, 0, 0, 0.3)" }}>
    //                     <div
    //                         className="modal show d-block"
    //                         tabIndex="-1"
    //                         role="dialog"
    //                         aria-modal="true"
    //                         style={{ zIndex: '1050', display: 'block' }}
    //                     >
    //                         <div className="modal-dialog modal-dialog-centered" role="document">
    //                             <div className="modal-content">
    //                                 <div className="modal-header">
    //                                     <h5 className="modal-title">Select Preferred Options</h5>
    //                                     <button type="button" className="close">
    //                                         <span aria-hidden="true">&times;</span>
    //                                     </button>
    //                                 </div>
    //                                 <div className="modal-body">

    //                                 </div>
    //                                 <div className="modal-footer">

    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             )} */}
    //         </div>
    //     </div>
    // </div>



  );
};

export default Billing;
