import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import Phoneinput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { faArrowLeft, faBuilding } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TextField } from "@mui/material";
import PhoneInput from "react-phone-number-input";

const EditCompanyForm = () => {
    const [isLoading, setIsLoading] = useState(false); // Add loading state
    const [instanceLoded, setinstanceLoded] = useState(true); // Add loading state
    const [name, setCompanyName] = useState("");
    const [address, setAddress] = useState("");
    const [phone, setPhone] = useState("");
    const [organizationNumber, setOrganizationNumber] = useState("");
    const [email, setEmail] = useState("");
    const [website, setWebsite] = useState("");
    const [bankaccount, setBankAccount] = useState("");
    const [bankgironumber, setbankgironumber] = useState("");
    const [vatNo, setVatno] = useState("");
    const [bicOrSwiftCode, setbicOrSwiftCode] = useState("");
    const [iban, setIBAN] = useState("");
    const [about, setAbout] = useState("");
    const [messege, setMessege] = useState("");
    const { companyId } = useParams();

    const getInstanceData = async () => {
        try {
            setIsLoading(true)
            const accessToken = localStorage.getItem("Key")
            const resp = await axios.get(
                `${process.env.REACT_APP_BASE_URL}edit-company/${companyId}`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + accessToken
                    }
                }).then(
                    (response) => {
                        setCompanyName(response.data.name)
                        setAbout(response.data.about)
                        setAddress(response.data.address)
                        setPhone(response.data.phone_number)
                        setOrganizationNumber(response.data.organization_number)
                        setEmail(response.data.organization_email)
                        setWebsite(response.data.website)
                        setBankAccount(response.data.bankaccount)
                        setVatno(response.data.vat_no)
                        setbankgironumber(response.data.bankgironumber)
                        setbicOrSwiftCode(response.data.bic_or_swift_code)
                        setIBAN(response.data.iban)
                        setIsLoading(false)
                        return response.data;
                    }
                );

        } catch (error) {
            toast("something went wrong")
            setIsLoading(false)
            console.log(error)
        }
    }

    useEffect(() => {
        getInstanceData();
    }, [instanceLoded]);

    const handleEditCompany = async (e) => {
        e.preventDefault()
        if (!about.length > 200) {
            setMessege("About should atleast contain more than 200 character");
            return
        };

        try {
            setIsLoading(true)
            const data = {
                "name": name,
                "about": about,
                "address": address,
                "phone_number": phone,
                "organization_number": organizationNumber,
                "organization_email": email,
                "website": website,
                "bankaccount": bankaccount,
                "vat_no": vatNo,
                "bic_or_swift_code": bicOrSwiftCode,
                "bankgironumber": bankgironumber,
                "iban": iban
            }
            const accessToken = localStorage.getItem("Key")
            const resp = await axios.put(
                `${process.env.REACT_APP_BASE_URL}edit-company/${companyId}`,
                data,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + accessToken
                    }
                }).then(
                    (response) => {
                        return response.data;
                    }
                );
            toast("Company is Updated");
            setIsLoading(false)

        } catch (error) {
            console.log(error)
            if (error.response.status === 401) {
                toast("Your session is Expired, please login again")
            }
            if (error.response.status === 400) {
                toast("Company Name Already Exsist")
            }
            setIsLoading(false)
        }
    }
    return (
        <>
            <div class="d-sm-flex align-items-center justify-content-between my-3">
                <h3 class="page-title">
                    <span class="page-title-icon bg-gradient-primary text-white me-2">
                        <i><FontAwesomeIcon icon={faBuilding} /></i>
                    </span> EDIT COMPANY
                </h3>
                <div className="d-flex justify-content-end align-items-center">
                    <Link className="p-2 rounded bg-gradient-success text-white me-2 shadow-sm" to="/dashboard/all-company" style={{ cursor: "pointer" }}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </Link>
                </div>
            </div>
            <div class="col-12">
                <div class="card shadow" style={{ padding: "1rem" }}>
                    <div class="card-body">
                        {isLoading ? (
                            <div
                                className="mt-3"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginBottom: 20,
                                }}
                            >
                                <CircularProgress />
                            </div>
                        ) :
                            <form class="form-sample" onSubmit={(e) => handleEditCompany(e)}>
                                <h5 className="mb-3">Company Information:</h5>
                                <div class="row mb-3">
                                    <div class="col-md-6">
                                        <div class=" mb-0 p-1 row">
                                            <TextField value={name}
                                                onChange={(e) => setCompanyName(e.target.value)}
                                                type="text"
                                                label="Company Name" />

                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="mb-0 p-1 row">
                                            <TextField value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                type="text"
                                                label="Company Email" />
                                        </div>
                                    </div>
                                </div>


                                <div class="row mb-3">
                                    <div class="col-md-6">
                                        <div class=" mb-0 p-1 row">
                                            <TextField value={address}
                                                onChange={(e) => setAddress(e.target.value)}
                                                type="text"
                                                label="Company Address" />
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="mb-0 p-1 row">
                                            <PhoneInput
                                                className="form-control"
                                                style={{ height: "3.5rem" }}
                                                defaultCountry="SE"
                                                value={phone}
                                                onChange={setPhone}
                                                limitMaxLength={true}
                                                placeholder={"Contact Number"}
                                            />
                                        </div>
                                    </div>
                                </div>

        
                                <div class="row mb-3">
                                    <div class="col-md-6">
                                        <div class=" mb-0 p-1 row">
                                            <TextField value={organizationNumber}
                                                onChange={(e) => setOrganizationNumber(e.target.value)}
                                                type="text"
                                                label="Organisation Number" />
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="mb-0 p-1 row">
                                            <TextField value={website}
                                                 onChange={(e) => setWebsite(e.target.value)}
                                                type="url"
                                                label="Website" />

                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-md-6">
                                        <div class=" mb-0 p-1 row">
                                            <TextField value={about}
                                                onChange={(e) => setAbout(e.target.value)} 
                                                type="text"
                                                label="About" />
                                        </div>
                                    </div>
                                    <div class="col-md-6">

                                        <div class="mb-0 p-1 row">
                                            <TextField value={vatNo}
                                                onChange={(e) => setVatno(e.target.value)}
                                                type="text"
                                                label="VAT Number" />
                                        </div>
                                    </div>
                                </div>

                                <h5 className="mb-3">Bank Information:</h5>
                                <div class="row mb-3">
                                    <div class="col-md-6">
                                        <div class=" mb-0 p-1 row">
                                            <TextField value={bankaccount}
                                                 onChange={(e) => setBankAccount(e.target.value)}
                                                type="text"
                                                label="Account Number" />

                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="mb-0 p-1 row">
                                            <TextField value={bankgironumber}
                                                onChange={(e) => setbankgironumber(e.target.value)}
                                                type="text"
                                                label="Bank Giro" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-md-6">
                                        <div class=" mb-0 p-1 row">
                                            <TextField value={bicOrSwiftCode}
                                               onChange={(e) => setbicOrSwiftCode(e.target.value)} 
                                                type="text"
                                                label="BIC/SWIFT Code" />

                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="mb-0 p-1 row">
                                            <TextField value={iban}
                                                onChange={(e) => setIBAN(e.target.value)}
                                                type="text"
                                                label="IBAN" />
                                        </div>
                                    </div>
                                </div>






                                <div className="d-flex justify-content-center mt-5">
                                    <button type="submit" class="btn btn-gradient-primary">Update</button>
                                </div>

                            </form>
                        }

                    </div>
                </div>
            </div >
        </>
    )
}

export default EditCompanyForm;