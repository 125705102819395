import React from 'react'
import NavbarComp from '../../components/navbar/navbar'
import SidebarComp from '../../components/sidebar/sidebar'
import DasboardMainComp from '../../components/body/main/dashboardmain/dashboardmain'

const LandingScreen = () => {
    return (
        <div className="container-scroller">
            <NavbarComp />
            <div className="container-fluid page-body-wrapper">
                <SidebarComp />
                <div className="main-panel">
                    <div className="content-wrapper">
                        <DasboardMainComp />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LandingScreen