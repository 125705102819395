import React, { useState, useEffect } from "react";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";
import { AiFillDelete } from "react-icons/ai";
import { TbArrowsSort } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PaginationComponent from "../PaginationComponent";
import stockholmDate from "../dateformatConvert";
import FilterForm from "../../filter/FilterForm";
import { formatStops, queryParams } from "../../../shared/utils";
import DeleteConfirmationModel from "../deleteConfirmationModel";
import MapInfoModal from "../../map/MapInfoModal";
import { baseSocketURL } from "../../../config";
import './Ride.css'

const RidesCards = () => {
  // const userType = useSelector(selectUserType)
  const userType = localStorage.getItem("A_usertype");
  const userId = localStorage.getItem("A_userid");
  const showFilter = useSelector((state) => state.showFilter.showFilter);
  const nav = useNavigate();
  const [ridesData, setRidesData] = useState({ results: [] });
  const [update, setupdate] = useState(false);
  const accessToken = localStorage.getItem("Key");
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pcurrentPage, setpCurrentPage] = useState(1);
  const [cCurrentPage, setcCurrentPage] = useState(1);
  const [status, setStatus] = useState("upcoming");
  const [orderBy, setOrderBy] = useState("-ride_date");
  const [isFilterLoading, setIsFilterLoading] = useState(false);
  const [showDriverAssignDetails, setshowDriverAssignDetails] = useState(false);
  const [showCompanyAssignDetails, setshowCompanyAssignDetails] = useState(false);
  const [showModel, setShowModel] = useState({
    "show": false,
    "id": null
  });
  const [mapOpen, setMapOpen] = useState(false)
  const [rideInfo, setMapRideInfo] = useState({
    origin: null,
    destination: null,
    via: null
  })

  const handleShowModel = (id) => {
    setShowModel({
      "show": true,
      "id": id
    })
  }
  const handleCloseModel = () => {
    setShowModel({
      "show": false,
      "id": null
    })
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handlpreviousPageChange = (pageNumber) => {
    setpCurrentPage(pageNumber);
  };
  const handlCancelPageChange = (pageNumber) => {
    setcCurrentPage(pageNumber);
  };

  const handleUpcomingRides = () => {
    setStatus("upcoming");
  };
  const handlePreviousRides = () => {
    setStatus("previous");
  };
  const handleCancelledRides = () => {
    setStatus("cancelled");
  };


  const handleShowDriverAssignDetails = () => {
    setshowDriverAssignDetails(true);
    setshowCompanyAssignDetails(false);
  };
  const handleShowCompanyAssignDetails = () => {
    setshowDriverAssignDetails(false);
    setshowCompanyAssignDetails(true);
  };

  const handleGetRidesData = async () => {
    setIsLoading(true);
    console.log("helo");
    try {
      if (userType === "ADMIN_USER") {
        const resp = await axios
          .get(
            `${process.env.REACT_APP_BASE_URL}all-rides/?paginate=true&page=${currentPage}&ride=${status}&order_by=${orderBy}`,
            {
              headers: {
                Authorization: "Bearer " + accessToken,
              },
            }
          )
          .then((response) => {
            return response;
          });
        let results = resp.data;
        console.log(results);
        setRidesData(results);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetRidesData();
  }, [update, currentPage, orderBy, cCurrentPage, pcurrentPage, status]);


  useEffect(() => {
    const socket = new WebSocket(`${baseSocketURL}/ws/entity_listener/${userId}`);
    socket.addEventListener('message', (event) => {
      const { message } = JSON.parse(event.data);
      if (message.type === "send_rides_message") {
        handleGetRidesData()
        toast.info(message.info)
      }
    });
    return () => {
      socket.close();
    };
  }, []);


  const handleDeleteRides = async (id) => {
    handleCloseModel()
    setIsLoading(true);
    try {
      console.log("acce");
      const resp = await axios
        .delete(
          `${process.env.REACT_APP_BASE_URL}retrive-or-delete-ride/${id}`,
          {
            headers: {
              Authorization: "Bearer " + accessToken,
            },
          }
        )
        .then((response) => {
          console.log(response, "response");
          setupdate(!update);
          toast("successfully deleted!");
          return response.data.data;
        });
      console.log(resp);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      // setMessege("Your session is expired, please Login again")
    }
  };

  const handleSortRideDate = () => {
    if (orderBy === "-ride_date") {
      setOrderBy("ride_date");
    } else {
      setOrderBy("-ride_date");
    }
  };
  const handleSortBookDate = () => {
    if (orderBy === "-created_date") {
      setOrderBy("created_date");
    } else {
      setOrderBy("-created_date");
    }
  };

  const ridesFields = [
    { name: "ride_id", label: "Order Id", type: "number" },
    { name: "name", label: "Name" },
    { name: "mobile_number", label: "Mobile Number", type: "number" },
    { name: "start_date", label: "Start Date", type: "date" },
    { name: "end_date", label: "End Date", type: "date" },
    { name: "pickuplocation", label: "Pickup Location" },
    { name: "droplocation", label: "Drop Location" },
    { name: "car_type", label: "Car Type" },
    { name: "booking_date", label: "Booking Date", type: "date" },
  ];

  const handleRidesFilter = (data) => {
    const params = queryParams(data);
    setIsFilterLoading(true);
    axios
      .get(`${process.env.REACT_APP_BASE_URL}filter-all-rides/?${params}&paginate=true&page=${currentPage}&ride=${status}`, {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      })
      .then((res) => {
        res.data.msg ? toast.error(res.data.msg) : setRidesData(res.data);
        setIsFilterLoading(false);
      })
      .catch((err) => {
        setIsFilterLoading(false);
        toast.error(err);
      });
  };

  const handleClearFilters = () => handleGetRidesData();
  const handleMapOpen = (ride) => {
    setMapOpen(true)
    setMapRideInfo({ origin: ride.picup_location_name, destination: ride.drop_location_name, via: formatStops(ride.stops) })
  }

  return (
    <>
      <DeleteConfirmationModel showModel={showModel} onHandleDelete={handleDeleteRides} handleCloseModel={handleCloseModel}></DeleteConfirmationModel>
      <MapInfoModal isOpen={mapOpen} onClose={() => setMapOpen(false)} title="Map" origin={rideInfo.origin} destination={rideInfo.destination} via={rideInfo.via} />

      {showFilter && <FilterForm
        fields={ridesFields}
        onFilter={handleRidesFilter}
        onClearFilters={handleClearFilters}
        isLoading={isFilterLoading}
      />}

      <div className="d-flex justify-content-start mb-3 rides-btn">
        <button className={`${status === "upcoming" ? 'btn btn-gradient-primary me-3' : 'btn btn-gradient-success me-3'}`} disabled={status === "upcoming" ? true : false} onClick={handleUpcomingRides}>
          Upcoming Rides
        </button>
        <button className={`${status === "previous" ? 'btn btn-gradient-primary me-3' : 'btn btn-gradient-success me-3'}`} disabled={status === "previous" ? true : false} onClick={handlePreviousRides}>
          Previous Rides
        </button>
        <button className={`${status === "cancelled" ? 'btn btn-gradient-primary me-3' : 'btn btn-gradient-success me-3'}`} disabled={status === "cancelled" ? true : false} onClick={handleCancelledRides}>
          Cancelled Rides
        </button>
      </div>



      {isLoading ?
        <div className="d-flex justify-content-center align-items-center my-5 py-5">
          <CircularProgress />
        </div>
        :

        <>{
          ridesData.results.map((ride, i) => {
            return (
              <div className="card shadow mb-3 grid-margin stretch-card" key={i}>
                <div className="card-body">
                  <h5 className="mb-3"><strong>Ride {ride.id}</strong></h5>
                  <div className="row">
                    <div className="col-md-4">

                      <p><strong>From</strong><br /> <span className="text-dark">{ride.picup_location_name}</span></p>
                      <p><strong>To</strong><br />  <span className="text-dark">{ride.drop_location_name}</span></p>
                      <p><strong>Stops</strong><br /> <span className="text-dark">{
                        ride.stops.length !== 0 ?(
                          <span>{formatStops(ride.stops)}</span>
                        ) : (
                          <span>No Stop</span>
                        )
                        }</span></p>
                      <p><strong>Booking Date & Time</strong><br />  <span className="text-dark">{stockholmDate(ride.ride_date)}</span></p>
                      <p><strong>Ride Date & Time</strong><br /> <span className="text-dark">{stockholmDate(ride.created_date)}</span></p>
                    </div>
                    <div className="col-md-4">
                      <p><strong>Rider Name</strong><br />  <span className="text-dark">{ride.ridername}</span></p>
                      <p><strong>Rider Phone</strong><br /> <span className="text-dark">{ride.riderphone}</span></p>
                      <p><strong>Fare</strong><br /> <span className="text-dark">{ride.fare} S.E.K</span></p>
                      <p><strong>Distance</strong><br />  <span className="text-dark">{ride.distance} KM</span></p>
                      <p><strong>Company Name</strong><br />  <span className="text-dark">{ride.company_name
                        ? ride.company_name
                        : "Company Not Assigned"}</span></p>

                    </div>
                    <div className="col-md-4">
                      <p><strong>Car Type</strong><br />  <span className="text-dark">{ride.car_type}</span></p>
                      <p><strong>Car Model</strong><br /> <span className="text-dark">{ride.car_model ? ride.car_model : "Car Not Assigned"}</span></p>
                      <p><strong>Car Registration Number</strong><br /> <span className="text-dark">{ride.car_registration_number
                        ? ride.car_registration_number
                        : "Car Not Assigned"}</span></p>
                      <p><strong>Driver Name</strong><br /> <span className="text-dark">{ride.driver_name
                        ? ride.driver_name
                        : "Driver Not Assigned"}</span></p>
                      <p><strong>Driver Number</strong><br /> <span className="text-dark">  {ride.driver_phone
                        ? ride.driver_phone
                        : "Driver Not Assigned"}</span></p>
                    </div>
                  </div>
                  <div className="mt-3">

                    <span
                      className="btn btn-gradient-info btn-sm mx-2 my-1"
                      style={{ cursor: "none" }}
                    >
                      {ride.online_booking ? "Online" : "Schedule"}
                    </span>

                    <button
                      className="btn btn-gradient-info btn-sm mx-2 my-1"
                      onClick={() => handleMapOpen(ride)}
                    >
                      Route
                    </button>

                    <span
                      className="btn btn-gradient-primary btn-sm mx-2 my-1"
                      style={{ cursor: "none" }}
                    >
                      {ride.status}
                    </span>
                    {status === "upcoming" && (ride.status == "To Be Started") &&
                      <>
                        {
                          (ride.status == "To Be Started") &&
                          <>
                            {ride.driver_assigned !== null ? (
                              <button
                                className="btn btn-gradient-success  btn-sm mx-2 my-1"
                                onClick={() =>
                                  nav("/dashboard/assigndrivercar", {
                                    state: {
                                      id: {
                                        id: ride.id,
                                        type: ride.car_type,
                                      },
                                    },
                                  })
                                }
                              >
                                ReAssign
                              </button>
                            ) : (
                              <>
                                {ride.company_id !== null ? (
                                  <button
                                    className="btn btn-gradient-danger btn-sm mx-2 my-1 "
                                    onClick={() =>
                                      nav("/dashboard/assigndrivercar", {
                                        state: {
                                          id: {
                                            id: ride.id,
                                            type: ride.car_type,
                                          },
                                        },
                                      })
                                    }
                                  >
                                    Company Assigned
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-gradient-success btn-sm mx-2 my-1"
                                    onClick={() =>
                                      nav("/dashboard/assigndrivercar", {
                                        state: {
                                          id: {
                                            id: ride.id,
                                            type: ride.car_type,
                                          },
                                        },
                                      })
                                    }
                                  >
                                    Assign
                                  </button>
                                )}
                              </>
                            )}
                          </>
                        }

                        <button className="btn btn-gradient-danger btn-sm mx-2 my-1" onClick={() => handleShowModel(ride.id)}>
                          Delete
                        </button>
                      </>
                    }



                  </div>
                </div>
              </div>
            )
          })
        }</>
      }

      <div className="my-3">
        {status === "upcoming" && (
          <PaginationComponent
            currentPage={currentPage}
            totalPages={ridesData.total_pages}
            onPageChange={handlePageChange}
          />
        )}
        {status === "previous" && (

          <PaginationComponent
            currentPage={pcurrentPage}
            totalPages={ridesData.total_pages}
            onPageChange={handlpreviousPageChange}
          />

        )}
        {status === "cancelled" && (
          <PaginationComponent
            currentPage={cCurrentPage}
            totalPages={ridesData.total_pages}
            onPageChange={handlCancelPageChange}
          />
        )}
      </div>



    </>
  );
};

export default RidesCards;


{/* <div className="card shadow mb-4">
<div className="card-body">
  <div className="table-responsive">
    <table
      className="table table-bordered"
      // id="dataTable"
      width="100%"
      cellSpacing="0"
    >
      <thead>
        <tr>
          

          <th>
            <span className="mr-2"></span>
            <TbArrowsSort
              style={{ cursor: "pointer" }}
              onClick={handleSortRideDate}
            />
          </th>
          <th>
            <span className="mr-2"></span>
            <TbArrowsSort
              style={{ cursor: "pointer" }}
              onClick={handleSortBookDate}
            />
          </th>
          
         
          <th>Status</th>
          <th>Delete</th>
          <th>Assign</th>
          <th>Details</th>
        </tr>
      </thead>
      <tfoot>
        <tr>
          <th>Order Id</th>
          <th>From</th>
          <th>To</th>
          <th>Stops</th>

          <th>
            <span className="mr-2">Ride Date & Time</span>
            <TbArrowsSort
              style={{ cursor: "pointer" }}
              onClick={handleSortRideDate}
            />
          </th>
          <th>
            <span className="mr-2">Booking Date & Time</span>
            <TbArrowsSort
              style={{ cursor: "pointer" }}
              onClick={handleSortBookDate}
            />
          </th>
          <th>Rider Name</th>
          <th>Rider Phone</th>
          <th>Fare</th>
          <th>Distance</th>
          <th>Car Type</th>
          <th>Car Model</th>
          <th>Car Registration Number</th>
          <th>Driver Name</th>
          <th>Driver Number</th>
          <th>Company Name</th>
          <th>Status</th>
          <th>Delete</th>
          <th>Assign</th>
          <th>Details</th>
        </tr>
      </tfoot>

      (
        <tbody>
          {ridesData?.results?.map((ride) => (
            <tr key={ride.id}>
             

              
              
            </tr>
          ))}
        </tbody>
      )}
    </table>


  </div>
</div>
</div> */}