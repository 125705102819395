import React, { useState, useEffect } from "react";
import axios from "axios";
import { selectUserType, selectCompanyInfo } from "../redux/slicer";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { v4 as uuidv4 } from "uuid";
import { faArrowLeft, faCab } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";


const AddCarForm = () => {
  const [companyId, setCompanyId] = useState(null);
  const [carModel, setCarModel] = useState(null);
  const [carTypeList, setCarTypeList] = useState([]);
  const [carRegistrationNumber, setCarRegistrationNumber] = useState(null);
  const [carRegistrationFile, setCarRegistrationFile] = useState([]);
  const [showCarList, setshowCarList] = useState(false);
  const [IsLoading, setIsLoading] = useState(false);
  // const usertype =useSelector(selectUserType)
  const usertype = localStorage.getItem("A_usertype");
  const ci = localStorage.getItem("A_companyinfo");
  let ccid = null;
  let empid = null;
  if (ci !== "undefined" && ci !== "null") {
    ccid = JSON.parse(ci.replace(/\\/g, "")).company_id;
    empid = JSON.parse(ci.replace(/\\/g, "")).employee_id;
  }
  const [companyData, setCompanyData] = useState([]);
  const [companyLabel, setCompanyLabel] = useState([
    { id: "n/a", name: "n/a", label: "n/a" },
  ]);
  const [isCompanyData, setIsCompanyData] = useState(false);

  const [messege, setMessege] = useState("");
  const accessToken = localStorage.getItem("Key");

  const nav = useNavigate();
  const handleFileInput = (e) => {
    const selectedFiles = e.target.files;
    setCarRegistrationFile(selectedFiles);
  };

  const cartypes = [
    { name: "Trixi", label: "Trixi" },
    { name: "Electric", label: "Electric" },
    { name: "Premium", label: "Premium" },
    { name: "Xl", label: "Xl" },
  ];
  // To Get Compnay Data
  const handleGetCompanyData = async () => {
    if (usertype === "ADMIN_USER") {
      try {
        const resp = await axios
          .get(`${process.env.REACT_APP_BASE_URL}all-company/`, {
            headers: {
              Authorization: "Bearer " + accessToken,
            },
          })
          .then((response) => {
            setCompanyData(response.data);
            setIsCompanyData(true);
            return response.data;
          });
      } catch (error) {
        toast("Somthing went wrong not able to fetch user data");
        setIsCompanyData(false);
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (usertype === "ADMIN_USER") {
      handleGetCompanyData();
    }
  }, []);
  useEffect(() => {
    if (companyData.length > 0) {
      const company_list = companyData.map((el, i) => {
        return {
          label: el.name,
          id: el.id,
          name: el.name,
        };
      });
      setCompanyLabel(company_list);
    }
  }, [isCompanyData]);

  const handleAddCar = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const endpoint = usertype === "ADMIN_USER" ? "add-car" : "create-report-firebase";
    if (usertype === "ADMIN_USER") {
      if (companyId === null) {
        setMessege("You should select a company");
        return;
      }
    }
    if (carTypeList.length === 0) {
      setMessege("You should select a car type");
      return;
    }

    if (carModel === null) {
      setMessege("You should enter a car model");
      return;
    }
    if (carRegistrationNumber === null) {
      setMessege("You should enter car registeration number");
      return;
    }

    try {
      let data;
      data = new FormData();
      data.append("car_type", JSON.stringify(carTypeList));
      data.append("car_model", carModel);
      data.append("registration_number", carRegistrationNumber);
      for (let i = 0; i < carRegistrationFile.length; i++) {
        data.append("added_documents", carRegistrationFile[i]);
      }
      if (usertype === "ADMIN_USER") {
        data.append("company_id", companyId);
      } else {
        data.append("type", "car");
        data.append("send_type", "add");
        data.append("company_id", ccid);
        data.append("created_by", empid);
      }

      const resp = await axios
        .post(`${process.env.REACT_APP_BASE_URL}${endpoint}/`, data, {
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          setIsLoading(false);
          if (usertype === "ADMIN_USER") {
            toast("Car is Created");
            nav("/dashboard/add-cars");
          } else {
            toast("Car Detail Send To Admin");
            nav("/dashboard/add-cars");
          }

          return response.data;
        });
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const handleCarTypeSelectList = (e) => {
    let updatedList = carTypeList;
    if (!updatedList.includes(e)) {
      updatedList.push(e);
    }
    setCarTypeList(updatedList);
    setshowCarList(new Date().getTime());
  };
  const handleCarTypeDeleteSelectList = (e) => {
    let updatedList = carTypeList.filter(
      (carType) => carType !== e.target.innerText
    );
    setCarTypeList(updatedList);
    setshowCarList(new Date().getTime());
  };

  return (
    <>
      <div class="d-sm-flex align-items-center justify-content-between my-3">
        <h3 class="page-title">
          <span class="page-title-icon bg-gradient-primary text-white me-2">
            <i><FontAwesomeIcon icon={faCab} /></i>
          </span> ADD CAR
        </h3>
        <div className="d-flex justify-content-end align-items-center">
        {usertype === "ADMIN_USER" ?
            <Link className="p-2 rounded bg-gradient-success text-white me-2 shadow-sm" to="/dashboard/all-cars" style={{ cursor: "pointer" }}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </Link>
            :
            <Link className="p-2 rounded bg-gradient-success text-white me-2 shadow-sm" to="/dashboard/my-cars" style={{ cursor: "pointer" }}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </Link>
          }

        </div>
      </div>
      <div class="col-12">
        <div class="card shadow" style={{ padding: "1rem" }}>
          <div class="card-body">
            {IsLoading ? (
              <div
                className="mt-3"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: 20,
                }}
              >
                <CircularProgress />
              </div>
            ) :
              <form class="form-sample" onSubmit={(e) => handleAddCar(e)}>

                <div class="row mb-3">
                  <div class="col-md-6">
                    <div class=" mb-0 p-1 row">
                      {usertype === "ADMIN_USER" ? (
                        <FormControl fullWidth>
                          <InputLabel id="emp-type">Select Company</InputLabel>
                          <Select
                            labelId="emp-type"
                            value={companyId}
                            label="Select Company"
                            onChange={(e) => setCompanyId(e.target.value)}
                          >

                            {companyLabel.map((d, i) => {
                              return (
                                <MenuItem key={i} value={d.id}>{d.label}</MenuItem>
                              )
                            })}

                          </Select>
                        </FormControl>

                      ) : null}
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="mb-0 p-1 row">
                      <TextField value={carModel}
                        onChange={(e) => setCarModel(e.target.value)}
                        type="text"
                        label="Car Model" />
                    </div>
                  </div>
                </div>


                <div class="row mb-3">
                  <div class="col-md-6">
                    <div class=" mb-0 p-1 row">
                      <TextField value={carRegistrationNumber}
                        onChange={(e) => setCarRegistrationNumber(e.target.value)}
                        type="text"
                        label="Registration Number" />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-0 p-1 row">

                      <FormControl fullWidth>
                        <InputLabel id="emp-type">Select Type</InputLabel>
                        <Select
                          labelId="emp-type"
                          label="Select Type"
                          onChange={(e) => handleCarTypeSelectList(e.target.value)}
                        >

                          {cartypes.map((d, i) => {
                            return (
                              <MenuItem key={i} value={d.name}>{d.label}</MenuItem>
                            )
                          })}

                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>


                <div class="row mb-3">
                  <div class="col-md-6">
                    <div class=" mb-0 p-1 row">
                      <input
                        type="file"
                        className="form-control"
                        accept=".pdf, image/*"
                        onChange={handleFileInput}
                        multiple
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-0 p-1">
                    {showCarList
                        ? carTypeList.map((elem, i) => {
                          return (
                            <span
                              key={i}
                              onClick={(e) => handleCarTypeDeleteSelectList(e)}
                              className="btn btn-outline-danger btn-sm me-2 mb-2"
                            >
                              {elem}
                            </span>
                          );
                        })
                        : null}

                    </div>
                  </div>
                </div>

                <div className="d-flex justify-content-center mt-5">
                  <button type="submit" class="btn btn-gradient-primary">Submit</button>
                </div>

              </form>
            }

          </div>
        </div>
      </div >
    </>
  );
};

export default AddCarForm;
