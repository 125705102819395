import React, { useState, useEffect } from "react";
import axios from "axios";
import { selectUserType } from "../../redux/slicer";
import { useSelector } from "react-redux";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import CircularProgress from "@mui/material/CircularProgress";
import PaginationComponent from "../PaginationComponent"; // Replace "path/to" with the actual path
import { toast } from "react-toastify";
import FilterForm from "../../filter/FilterForm";
import { queryParams } from "../../../shared/utils";
import { Link, useNavigate } from "react-router-dom";
import DeleteConfirmationModel from "../deleteConfirmationModel";
import { BsFillTelephoneForwardFill } from "react-icons/bs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLocation, faLocationDot, faMessage, faPhone } from "@fortawesome/free-solid-svg-icons";

const EmployeeCards = () => {
  const nav = useNavigate();
  const userType = localStorage.getItem("A_usertype");
  const showFilter = useSelector((state) => state.showFilter.showFilter);
  const [employeeData, setEmployeeData] = useState({ results: [] });
  const [isLoading, setIsLoading] = useState(true); // Add loading state
  const [currentPage, setCurrentPage] = useState(1);
  const [update, setupdate] = useState(false);
  const [isFilterLoading, setIsFilterLoading] = useState(false);
  const [showModel, setShowModel] = useState({
    "show": false,
    "id": null
  });

  const handleShowModel = (id) => {
    setShowModel({
      "show": true,
      "id": id
    })
  }
  const handleCloseModel = () => {
    setShowModel({
      "show": false,
      "id": null
    })
  }


  const accessToken = localStorage.getItem("Key");

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleGetEmployeeData = async () => {
    try {
      const endpoint =
        userType === "ADMIN_USER" ? "all-employee" : "my-employee";
      const resp = await axios
        .get(
          `${process.env.REACT_APP_BASE_URL}${endpoint}/?paginate=true&page=${currentPage}`,
          {
            headers: {
              Authorization: "Bearer " + accessToken,
            },
          }
        )
        .then((response) => {
          return response;
        });
      let results = resp.data;
      console.log(results);
      setEmployeeData(results);
      setIsLoading(false); // Update loading state
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteEmployee = async (id) => {
    handleCloseModel()
    setIsLoading(true);
    try {
      console.log("acce");
      const resp = await axios
        .delete(
          `${process.env.REACT_APP_BASE_URL}retrive-delete-employee-account/${id}`,
          {
            headers: {
              Authorization: "Bearer " + accessToken,
            },
          }
        )
        .then((response) => {
          console.log(response, "response");
          toast("Deleted successfully!");
          setupdate(!update);
          return response.data.data;
        });
      console.log(resp);
    } catch (error) {
      console.log(error);
      // setMessege("Your session is expired, please Login again")
    }
    setIsLoading(false);
  };
  useEffect(() => {
    handleGetEmployeeData();
  }, [update, currentPage]);

  const ridesFields = [
    { name: 'employees_name', label: 'Name' },
    { name: 'mobile_number', label: 'Mobile Number', type: 'number' },
    { name: 'email', label: 'Email', type: 'email' },
  ];

  const handleRidesFilter = (data) => {
    const params = queryParams(data)
    setIsFilterLoading(true)
    axios.get(`${process.env.REACT_APP_BASE_URL}filter-all-employees/?${params}&paginate=true`, {
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    }).then((res) => {
      res.data.msg ? toast.error(res.data.msg) : setEmployeeData(res.data)
      setIsFilterLoading(false)
    }).catch((err) => {
      setIsFilterLoading(false)
      toast.error(err);
    })
  };

  const handleClearFilters = () => handleGetEmployeeData();

  return (
    <>
      <DeleteConfirmationModel showModel={showModel} onHandleDelete={handleDeleteEmployee} handleCloseModel={handleCloseModel}></DeleteConfirmationModel>
      {showFilter && <FilterForm
        fields={ridesFields}
        onFilter={handleRidesFilter}
        onClearFilters={handleClearFilters}
        isLoading={isFilterLoading}
      />}
      {isLoading ?
        <div className="d-flex justify-content-center align-items-center my-5 py-5">
          <CircularProgress />
        </div>
        :

        <>{
          employeeData.results.map((el, i) => {
            return (
              <div className="card shadow mb-3 grid-margin stretch-card" key={i}>
                <div className="card-body">
                  <h6><span className="text-dark font-weight-bold mr-1">{String(userType === "ADMIN_USER" ? el.name : el.employee_name).toLocaleUpperCase()} </span> {userType === "ADMIN_USER" && `(${el.company_name})`}</h6>
                  <div className="row">
                    <div className="col-md-6">
                      <p className="text-dark mb-1"><FontAwesomeIcon icon={faEnvelope} className="me-2 text-gray" />{userType === "ADMIN_USER" ? el.registered_email : el.employee_email}</p>
                      <p className="text-dark mb-1"><FontAwesomeIcon icon={faLocationDot} className="me-2 text-gray" />{el.employee_address}</p>
                      <p className="text-dark mb-0"><FontAwesomeIcon icon={faPhone} className="me-2 text-gray" />{userType === "ADMIN_USER" ?
                        el.phone
                        :
                        el.employee_phone
                      }</p>
                    </div>
                    <div className="col-md-4">

                      {el.added_document_urls.length !== 0 ? (
                        <div className="row">
                          {el.added_document_urls.map((elem, i) => {
                            return (
                              <div className="col-12">
                                <a
                                  href={elem.document_url}
                                  className="fa fa-download"
                                  target="_blank"
                                  style={{ cursor: "pointer" }}
                                >
                                  View Document {i + 1}
                                </a>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        "No Documents"
                      )}
                    </div>
                    <div className="col-md-2">
                    <Link
                          className="my-2 me-2 btn-sm btn btn-gradient-primary"
                          style={{ cursor: "pointer" }}
                          to={"/dashboard/edit-employee/" + el.id}
                        >
                          Edit
                        </Link>

                        {userType === "ADMIN_USER" && <button
                          className="my-2 btn-sm btn btn-gradient-danger"
                          onClick={() => handleShowModel(el.id)}
                        >
                          Delete
                        </button>}
                    </div>

                  </div>


                </div>
              </div>
            )
          })
        }</>
      }

      <div className="my-3">
        <PaginationComponent
          currentPage={currentPage}
          totalPages={employeeData.total_pages}
          onPageChange={handlePageChange}
        />
      </div>
    </>
  );
};

export default EmployeeCards;
