import React, { useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { selectUserType } from "../redux/slicer";
import { useSelector } from "react-redux";
import faceIcon from "../../static/images/faces/face1.jpg"
import LocalTaxiIcon from '@mui/icons-material/LocalTaxi';
import "./sidebar.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faCab, faCalendar, faCircleCheck, faFileCircleCheck, faFileInvoice, faHome, faMoneyBills, faUser, faUserGear, faUserTie, faUsers, faUsersLine } from "@fortawesome/free-solid-svg-icons";
import { WebSocketContext } from "../../app/App";

const SidebarComp = () => {
    // const userType = useSelector(selectUserType);
    const {sideBarActive} = useContext(WebSocketContext)
    const userType = localStorage.getItem('A_usertype')
    const ci = localStorage.getItem("A_companyinfo");
    const fname = localStorage.getItem("A_firstname")
    const lname = localStorage.getItem("A_lastname")
    const profile = localStorage.getItem("A_profile_image")
    let COMPANYNAME = null;
    if (ci !== "undefined" && ci !== "null") {
        COMPANYNAME = JSON.parse(ci.replace(/\\/g, "")).company_name;
    }

    const nav = useNavigate();
    console.log("user type .....", userType)
    const handleAccess = () => {
        if (document.getElementById("access-id").classList.contains("collapsed")) {
            document.getElementById("access-id").classList.remove("collapsed");
            document.getElementById("access-id").ariaExpanded = true;
            document.getElementById("collapseTwo").classList.add("show");
        } else {
            document.getElementById("access-id").classList.add("collapsed");
            document.getElementById("access-id").ariaExpanded = false;
            document.getElementById("collapseTwo").classList.remove("show");
        }
    };

    const handleUtility = () => {
        if (document.getElementById("utility-id").classList.contains("collapsed")) {
            document.getElementById("utility-id").classList.remove("collapsed");
            document.getElementById("utility-id").ariaExpanded = true;
            document.getElementById("collapseUtilities").classList.add("show");

        } else {
            document.getElementById("utility-id").classList.add("collapsed");
            document.getElementById("utility-id").ariaExpanded = false;
            document.getElementById("collapseUtilities").classList.remove("show");
        }
    }
    return (



        //         <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
        //             <div className="bg-white py-2 collapse-inner rounded">
        //                 {
        //                     userType === "ADMIN_USER" ?
        //                         < a style={{ cursor: "pointer" }} className="collapse-item" onClick={() => nav("/dashboard/add-company")}>Add Company</a>
        //                         :
        //                         <a></a>
        //                 }
        //                 {
        //                     userType === "ADMIN_USER" ?
        //                         < a style={{ cursor: "pointer" }} className="collapse-item" onClick={() => nav("/dashboard/add-user")}>Add User</a>
        //                         :
        //                         <a></a>
        //                 }
        //                 < a style={{ cursor: "pointer" }} className="collapse-item" onClick={() => nav("/dashboard/add-ride")}>Add Rides</a>
        //                 < a style={{ cursor: "pointer" }} className="collapse-item" onClick={() => nav("/dashboard/add-cars")}>Add Car</a>
        //                 < a style={{ cursor: "pointer" }} className="collapse-item" onClick={() => nav("/dashboard/add-employee")}>Add Employee</a>
        //             </div>
        //         </div>
        //     </li>




        //     <hr className="sidebar-divider" />


        //     {/* <div className="sidebar-heading">
        //                 Addons
        //             </div>


        //             <li className="nav-item">
        //                 < a style={{cursor: "pointer"}} className="nav-link nav-align collapsed"  data-toggle="collapse" data-target="#collapsePages"
        //                     aria-expanded="true" aria-controls="collapsePages">
        //                     <i className="fas fa-fw fa-folder"></i>
        //                     <span>Pages</span>
        //                 </a>
        //                 <div id="collapsePages" className="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
        //                     <div className="bg-white py-2 collapse-inner rounded">
        //                         <h6 className="collapse-header">Login Screens:</h6>
        //                         < a style={{cursor: "pointer"}} className="collapse-item" href="login.html">Login</a>
        //                         < a style={{cursor: "pointer"}} className="collapse-item" href="register.html">Register</a>
        //                         < a style={{cursor: "pointer"}} className="collapse-item" href="forgot-password.html">Forgot Password</a>
        //                         <div className="collapse-divider"></div>
        //                         <h6 className="collapse-header">Other Pages:</h6>
        //                         < a style={{cursor: "pointer"}} className="collapse-item" href="404.html">404 Page</a>
        //                         < a style={{cursor: "pointer"}} className="collapse-item" href="blank.html">Blank Page</a>
        //                     </div>
        //                 </div>
        //             </li>


        //             <li className="nav-item">
        //                 < a style={{cursor: "pointer"}} className="nav-link nav-align" href="charts.html">
        //                     <i className="fas fa-fw fa-chart-area"></i>
        //                     <span>Charts</span></a>
        //             </li>


        //             <li className="nav-item">
        //                 < a style={{cursor: "pointer"}} className="nav-link nav-align" href="tables.html">
        //                     <i className="fas fa-fw fa-table"></i>
        //                     <span>Tables</span></a>
        //             </li>


        //             <hr className="sidebar-divider d-none d-md-block" /> */}

        // </ul>
        <nav className={`sidebar sidebar-offcanvas sidebar-bg-color ${sideBarActive?'active':''}`} id="sidebar">
            <ul className="nav">
                <li className="nav-item nav-profile">
                    <a href="#" className="nav-link">
                        <div className="nav-profile-image">
                            <img src={profile} alt="profile" />
                            <span className="login-status online"></span>

                        </div>
                        <div className="nav-profile-text d-flex flex-column">
                            <span className="font-weight-bold mb-2">{fname} {lname}</span>
                            <span className="text-secondary text-small">
                                {userType === "ADMIN_USER" ? "Admin" : COMPANYNAME}
                            </span>
                        </div>
                        <i className="mdi mdi-bookmark-check text-success nav-profile-badge"></i>
                    </a>
                </li>
                <li className="nav-item">
                    <Link className="nav-link nav-align" href="#" to="/dashboard">
                        <span className="menu-title">Dashboard</span>

                    </Link>
                </li>
                <li className="nav-item">
                    <a className="nav-link nav-align" href="#" onClick={() => nav("/dashboard/profile")}>
                        <span className="menu-title">Pofile</span>

                    </a>
                </li>

                {userType === "ADMIN_USER" &&
                    <li className="nav-item">
                        <a className="nav-link nav-align" href="#" onClick={() => nav("/dashboard/billing")}>
                            <span className="menu-title">Billing & Earnings</span>

                        </a>
                    </li>}


                {
                    userType === "ADMIN_USER" ?

                        <li class="nav-item">
                            <a class="nav-link nav-align nav-drop" data-bs-toggle="collapse" href="#ui-basic" aria-expanded="false" aria-controls="ui-basic">
                                <span class="menu-title">Rides</span>
                                <i class="menu-arrow"></i>

                            </a>
                            <div class="collapse" id="ui-basic">
                                <ul class="nav flex-column sub-menu">
                                    <li className="nav-item">
                                        <a className="nav-link nav-align" href="#" onClick={() => nav("/dashboard/all-rides")}>
                                            <span className="menu-title">All Rides</span>

                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link nav-align" href="#" onClick={() => nav("/dashboard/ongoing-rides")}>
                                            <span className="menu-title">Ongoing Rides</span>

                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </li>

                        :
                        <>
                            <li class="nav-item">
                                <a class="nav-link nav-align nav-drop" data-bs-toggle="collapse" href="#ui-basic" aria-expanded="false" aria-controls="ui-basic">
                                    <span class="menu-title">Rides</span>
                                    <i class="menu-arrow"></i>

                                </a>
                                <div class="collapse" id="ui-basic">
                                    <ul class="nav flex-column sub-menu">
                                        <li className="nav-item">
                                            <a className="nav-link nav-align" href="#" onClick={() => nav("/dashboard/my-rides")}>
                                                <span className="menu-title">My Rides</span>

                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link nav-align" href="#" onClick={() => nav("/dashboard/ongoing-rides")}>
                                                <span className="menu-title">Ongoing Rides</span>

                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link nav-align" href="#" onClick={() => nav("/dashboard/all-available-rides")}>
                                                <span className="menu-title">All Available Rides</span>

                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </li>

                        </>
                }



                <li class="nav-item">
                    <a class="nav-link nav-align nav-drop" data-bs-toggle="collapse" href="#ui-driver" aria-expanded="false" aria-controls="ui-driver">
                        <span class="menu-title">Driver</span>
                        <i class="menu-arrow"></i>

                    </a>
                    <div class="collapse" id="ui-driver">
                        <ul class="nav flex-column sub-menu">
                            {
                                userType === "ADMIN_USER" ?
                                    <li className="nav-item">
                                        <a className="nav-link nav-align" href="#" onClick={() => nav("/dashboard/all-drivers")}>
                                            <span className="menu-title">All Driver</span>

                                        </a>
                                    </li>


                                    :
                                    <li className="nav-item">
                                        <a className="nav-link nav-align" href="#" onClick={() => nav("/dashboard/my-driver")}>
                                            <span className="menu-title">My Driver</span>

                                        </a>
                                    </li>
                            }
                            <li className="nav-item">
                                <a className="nav-link nav-align" href="#" onClick={() => nav("/dashboard/driver-rides")}>
                                    <span className="menu-title">Track Driver Rides</span>

                                </a>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link nav-align" href="#" onClick={() => nav("/dashboard/driver-locations")}>
                                    <span className="menu-title">Track Drivers Location</span>

                                </a>
                            </li>

                        </ul>
                    </div>
                </li>





                {
                    userType === "ADMIN_USER" &&
                    <li className="nav-item">
                        <a className="nav-link nav-align" href="#" onClick={() => nav("/dashboard/all-company")}>
                            <span className="menu-title">All Companies</span>

                        </a>
                    </li>
                }
                {
                    userType === "ADMIN_USER" ?
                        <li className="nav-item">
                            <a className="nav-link nav-align" href="#" onClick={() => nav("/dashboard/all-employee")}>
                                <span className="menu-title">All Employees</span>

                            </a>
                        </li>


                        :
                        <li className="nav-item">
                            <a className="nav-link nav-align" href="#" onClick={() => nav("/dashboard/my-employee")}>
                                <span className="menu-title">My Employees</span>

                            </a>
                        </li>


                }
                {
                    userType === "ADMIN_USER" ?
                        <li className="nav-item">
                            <a className="nav-link nav-align" href="#" onClick={() => nav("/dashboard/all-cars")}>
                                <span className="menu-title">All Cars</span>

                            </a>
                        </li>


                        :
                        <li className="nav-item">
                            <a className="nav-link nav-align" href="#" onClick={() => nav("/dashboard/my-cars")}>
                                <span className="menu-title">My Cars</span>

                            </a>
                        </li>


                }

                {
                    userType === "ADMIN_USER" &&
                    <li className="nav-item">
                        <a className="nav-link nav-align" href="#" onClick={() => nav("/dashboard/all-users")}>
                            <span className="menu-title">All Users</span>

                        </a>
                    </li>
                }

                {
                    userType === "ADMIN_USER" &&
                    <li className="nav-item">
                        <a className="nav-link nav-align" href="#" onClick={() => nav("/dashboard/all-report")}>
                            <span className="menu-title">All Reports</span>

                        </a>
                    </li>
                }


            </ul>
        </nav>
    )
}

export default SidebarComp;