import React from 'react'
import SidebarComp from "../../components/sidebar/sidebar";
import NavbarComp from "../../components/navbar/navbar";
import AssignDriverCarComp from '../../components/body/assigndriverandcar/AssignDriverCarComp';
import { useLocation } from 'react-router-dom';
const AssignDriverCar = () => {
  const params = useLocation()
  const id = params.state.id
  return (
    <div className="container-scroller">
      <NavbarComp />
      <div className="container-fluid page-body-wrapper">
        <SidebarComp />
        <div className="main-panel">
          <div className="content-wrapper">
            <AssignDriverCarComp id={id} />

          </div>
        </div>
      </div>
    </div>
  )
}

export default AssignDriverCar