import React from "react";
import EditEmployeeMidComp from "./editemployeecomp";

const EditEmployeeMainComp=()=>{
    return(
        <div class="container-fluid">
            <EditEmployeeMidComp></EditEmployeeMidComp>
        </div>
    )
}

export default EditEmployeeMainComp;