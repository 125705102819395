import React, { useState, useRef, useEffect, useCallback, useMemo } from "react";
import {
  useJsApiLoader,
  DirectionsService,
} from "@react-google-maps/api";
// import SelectCarComp from "../selectcar/selectcar";
import axios from "axios";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "flatpickr/dist/themes/airbnb.css";
import moment from "moment-timezone";
import { getDistance, getTime } from "geolib";
import { toast } from "react-toastify";
import PlacesAutocomplete from "./CurrentPlace";
import { useNavigate, Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import MapComponent from "../../map/MapComponent";
import { calculateRidePrice } from "../../../shared/utils";
import { googleMapApiKey } from "../../../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Autocomplete, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import clsx from 'clsx';
import { styled, css } from '@mui/system';
import { Modal as BaseModal } from '@mui/base/Modal';

const DirectionApiKey = "AIzaSyD_vtOwddYmBMxMVtorQ7AeWI-f4O2Wfz0";

const STOCKHOLM_MIN_LATITUDE = 56.03367149;
const STOCKHOLM_MAX_LATITUDE = 65.59663477;
const STOCKHOLM_MIN_LONGITUDE = 12.85558712;
const STOCKHOLM_MAX_LONGITUDE = 22.15837833;

const AddrideForm = () => {
  const [date, onChangeDate] = useState(dayjs());
  const [PickupLocation, setPickupLocation] = useState({
    latitude: null,
    longitude: null,
  });
  const [DropLocation, setDropLocation] = useState({
    latitude: null,
    longitude: null,
  });
  const [ViaLocation, setViaLocation] = useState({
    latitude: null,
    longitude: null,
  });
  const [fomattAdressPickUpLocation, setfomatedPickUpAdress] = useState();
  const [fomattAdressDropLocation, setfomattAdressDropLocation] = useState();
  const [fomattAdressViaLocation, setfomattAdressViaLocation] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isULoading, setUIsLoading] = useState(false);
  const [directions, setDirections] = useState(null);
  const [Distance, setdistance] = useState("");
  const [duration, setduration] = useState("");
  const [riderName, setRiderName] = useState("");
  const [email, setEmail] = useState("");
  const [minutes, setminutes] = useState("");
  const [phone, setPhone] = useState("");
  const [showViaLocationInput, setShowViaLocationInput] = useState(false);
  const [Fare, setFare] = useState("");
  const [showExistingUser, setshowExistingUser] = useState(true);
  const [showUnRegUser, setshowUnRegUser] = useState(false);
  const [userData, setUserData] = useState([]);
  const [userLabels, setUserLabels] = useState([]);
  const [isUserData, setisUserData] = useState(false);
  const [createdFor, setCreatedFor] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const [childSeatChecked, setChildSeatChecked] = useState(false);
  const [petChecked, setPetChecked] = useState(false);
  const [luggageChecked, setLuggageChecked] = useState(false);

  const usertype = localStorage.getItem("A_usertype");
  const [carType, setCarType] = useState("Trixi");
  const [rideResult, setRideResult] = useState(null);

  const ci = localStorage.getItem("A_companyinfo");
  let ccid = null;
  if (ci !== "undefined" && ci !== "null") {
    ccid = JSON.parse(ci.replace(/\\/g, "")).company_id;
  }

  const id = localStorage.getItem("");
  const nav = useNavigate();
  const accessToken = localStorage.getItem("Key");

  const carTypeLabel = [
    { value: "Trixi", label: "Trixi" },
    { value: "Electric", label: "Electric" },
    { value: "Premium", label: "Premium" },
    { value: "XL", label: "XL" },
  ];

  const dateFormat = (date) => {
    const formattedDate = date.format("YYYY-MM-DD HH:mm:ss");
    return formattedDate;
  };

  //  Get Duration and Distance Function
  const directionsCallback = (response) => {
    if (response !== null) {
      setminutes(Math.round(response.routes[0].legs[0].duration.value / 60));
      setDirections(response);
      setduration(response.routes[0].legs[0].duration.text);
    }
  };
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: googleMapApiKey,
    libraries: ["places"],
  });
  // update the duration and distance
  useEffect(() => {
    if (fomattAdressPickUpLocation && fomattAdressDropLocation) {
      let distanceInMeters = 0;
      let distanceInMeters2 = 0;
      let total_distance = 0;
      const distance = getDistance(
        {
          latitude: PickupLocation.latitude,
          longitude: PickupLocation.longitude,
        },
        { latitude: DropLocation.latitude, longitude: DropLocation.longitude }
      );

      total_distance = distance / 1000;

      if (fomattAdressViaLocation) {
        distanceInMeters = getDistance(
          {
            latitude: PickupLocation.latitude,
            longitude: PickupLocation.longitude,
          },
          { latitude: ViaLocation.latitude, longitude: ViaLocation.longitude }
        );
        distanceInMeters2 = getDistance(
          { latitude: ViaLocation.latitude, longitude: ViaLocation.longitude },
          { latitude: DropLocation.latitude, longitude: DropLocation.longitude }
        );
        total_distance = (distanceInMeters + distanceInMeters2) / 1000;
      }
      setFare(total_distance * 1.5 + minutes);
      setdistance(total_distance.toFixed(2));
      setDirections(null);
    }
  }, [
    fomattAdressPickUpLocation,
    fomattAdressDropLocation,
    fomattAdressViaLocation,
  ]);

  // handle Add Ride Api Call
  const handleAddRideApiCall = async () => {
    if (fomattAdressPickUpLocation && fomattAdressDropLocation) {
      const dates = new Date();
      let addRideData = {};
      if (
        PickupLocation.latitude >= STOCKHOLM_MIN_LATITUDE &&
        PickupLocation.latitude <= STOCKHOLM_MAX_LATITUDE
      ) {
        if (dates > date) {
          toast("Please select an upcoming date and time");
          return;
        }

        if (showExistingUser) {
          if (createdFor === null) {
            toast("Please select user");
            return;
          }
          let selected_user = userData.filter((el) => el.id == createdFor)[0];

          addRideData = {
            id: id ? id : null,
            picup_location_name: fomattAdressPickUpLocation,
            drop_location_name: fomattAdressDropLocation,
            picup_location_latitude: PickupLocation.latitude,
            picup_location_longitude: PickupLocation.longitude,
            drop_location_latitude: DropLocation.latitude,
            drop_location_longitude: DropLocation.longitude,
            ridername: selected_user.first_name + ' ' + selected_user.last_name,
            riderphone: selected_user.phone,
            rideremail: selected_user.email,
            created_for: createdFor,
            company_id: ccid,
            car_type: carType,
            is_crated_for_self: true,
            ride_date: dateFormat(date),
            fare: Math.round(fairCharges),
            distance: Math.round(rideResult.distance.value / 1000),
            duration: Math.round(rideResult.duration.value),
            pet_sit: petChecked ? 1 : 0,
            child_sit: childSeatChecked ? 1 : 0,
            luggage_sit: luggageChecked ? 1 : 0,
            stops: [
              {
                location_name: fomattAdressViaLocation?fomattAdressViaLocation:null,
                location_latitude: ViaLocation.latitude,
                location_longitude: ViaLocation.longitude,
              },
            ],
          };

        } else if (showUnRegUser) {
          if (!email || !phone || riderName === "") {
            if (!email) {
              toast("Email Is Required");
            }
            if (!phone) {
              toast("Phone Number Is Required");
            }
            if (riderName === "") {
              toast("FullName Is Required");
            }
            return;
          }
          addRideData = {
            id: id ? id : null,
            picup_location_name: fomattAdressPickUpLocation,
            drop_location_name: fomattAdressDropLocation,
            picup_location_latitude: PickupLocation.latitude,
            picup_location_longitude: PickupLocation.longitude,
            drop_location_latitude: DropLocation.latitude,
            drop_location_longitude: DropLocation.longitude,
            ridername: riderName,
            riderphone: phone,
            rideremail: email,
            car_type: carType,
            created_for: null,
            company_id: ccid,
            is_crated_for_self: true,
            ride_date: dateFormat(date),
            fare: Math.round(fairCharges),
            distance: Math.round(rideResult.distance.value / 1000),
            duration: Math.round(rideResult.duration.value),
            pet_sit: petChecked ? 1 : 0,
            child_sit: childSeatChecked ? 1 : 0,
            luggage_sit: luggageChecked ? 1 : 0,
            stops: [
              {
                location_name: fomattAdressViaLocation,
                location_latitude: ViaLocation.latitude,
                location_longitude: ViaLocation.longitude,
              },
            ],
          };
        } else {
          toast.error("please select the user")
          return;
        }

        const options = {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        };
        try {
          setIsLoading(true);
          // for initial data creation
          const url = `${process.env.REACT_APP_BASE_URL}add-ride/`;
          const resp = await axios
            .post(url, addRideData, options)
            .then((response) => {
              toast("Ride Successfully Created");
              setIsLoading(false);
              setshowExistingUser(false);
              setshowUnRegUser(false);
              nav("/dashboard/add-ride");
              return response;

            });
        } catch (error) {
          console.log(error)
          if (error.response.status === 401) {
            toast("session expired");
          } else {
            console.log(error);
            toast("Somthing went wrong, please try later");
          }
        }
      } else {
        toast("Ride for selected location is not available");
      }
    } else {
      toast("Please select Pickup And Destination");
    }
    setIsLoading(false);
  };
  // handle Pickup Location Function
  const handlePickupLocation = (data) => {
    setPickupLocation(data);
  };
  const handlePickUpFormattedAddress = (data, Erorr) => {
    setfomatedPickUpAdress(data);
    console.log("erorr ", Erorr);
    // setPickupErr(Erorr)
  };
  // handle Drop Location Function
  const handleDropLocation = (data) => {
    setDropLocation(data);
  };
  const handleDropFormattedAddress = (data, Erorr) => {
    setfomattAdressDropLocation(data);
    // setPickupErr(Erorr)
  };
  // handle Via Location Function

  const handleViaLocation = (data) => {
    setViaLocation(data);
  };
  const handleViaFormattedAddress = (data) => {
    setfomattAdressViaLocation(data);
  };
  const handleShowURegisterUser = () => {
    setshowExistingUser(false);
    setshowUnRegUser(true);
    setCreatedFor(null);
  };
  const handleShowRegisterUser = () => {
    setshowExistingUser(true);
    setshowUnRegUser(false);
    setPhone("");
    setEmail("");
    setRiderName("");
  };

  const handleGetUserData = async () => {
    setUIsLoading(true);
    try {
      const resp = await axios
        .get(`${process.env.REACT_APP_BASE_URL}users-data/`, {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data) {
            setUserData(response.data.data);
            setisUserData(true);
          }
          setUIsLoading(false);
          return response.data;
        });
    } catch (error) {
      setUserData([]);
      setisUserData(false);
      console.log(error);
    }
    setUIsLoading(false);
  };

  useEffect(() => {
    if (showExistingUser) {
      handleGetUserData();
    }
  }, [showExistingUser]);

  useEffect(() => {
    if (userData.length > 0) {
      const user_list = userData.map((el, i) => {
        return {
          id: el.id,
          label: el.phone,
        };
      });
      setUserLabels(user_list);
    }
  }, [isUserData]);

  const handleViaLocationInput = () => {
    if (!showViaLocationInput) {
      setShowViaLocationInput(true);
    } else {
      setShowViaLocationInput(false);
      setfomattAdressViaLocation()
    }
  };

  const fairCharges = useMemo(() => {
    if (rideResult && carType) {
      return calculateRidePrice(
        carType,
        parseInt(rideResult?.distance?.text, 10),
        (rideResult?.duration?.value || 0) / 60,
        childSeatChecked,
        petChecked,
        luggageChecked
      );
    }
    return null;
  }, [rideResult, carType, childSeatChecked, petChecked, luggageChecked]);

  const handleCloseModal = () => setShowModal(false);
  const handleChildSeatChange = () => setChildSeatChecked(!childSeatChecked)
  const handlePetChange = () => setPetChecked(!petChecked);
  const handleLuggageChange = () => setLuggageChecked(!luggageChecked);
  const handleCarTypeChange = (e) => {
    setCarType(e.target.value)
    if (e.target.value !== " Trixi") {
      setChildSeatChecked(false)
      setPetChecked(false)
    }
  }

  useEffect(() => {
    if (rideResult && carType) {
      setShowModal(true);
    }
  }, [rideResult, carType]);

  return (
    <>

      <div class="d-sm-flex align-items-center justify-content-between my-3">
        <h3 class="page-title">
          <span class="page-title-icon bg-gradient-primary text-white me-2">
            <i><FontAwesomeIcon icon={faCalendar} /></i>
          </span> ADD RIDE
        </h3>
        <div className="d-flex justify-content-end align-items-center">
          {usertype === "ADMIN_USER" ?
            <Link className="p-2 rounded bg-gradient-success text-white me-2 shadow-sm" to="/dashboard/all-rides" style={{ cursor: "pointer" }}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </Link>
            :
            <Link className="p-2 rounded bg-gradient-success text-white me-2 shadow-sm" to="/dashboard/my-rides" style={{ cursor: "pointer" }}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </Link>
          }
        </div>
      </div>
      <div class="col-12">
        <div class="card shadow" style={{ padding: "1rem" }}>
          <div class="card-body">
            {isLoading ? (
              <div
                className="mt-3"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: 20,
                }}
              >
                <CircularProgress />
              </div>
            ) :
              <form class="form-sample">
                <div class="row">
                  <div class="col-md-6">
                    <div class=" mb-0 p-1 row">
                      {isLoaded &&
                        <PlacesAutocomplete
                          placeholder="Pickup Location"
                          passLocation={handlePickupLocation}
                          addStop={false}
                          handleViaLocationInput={() => handleViaLocationInput()}
                          passFormattedAddress={
                            handlePickUpFormattedAddress
                          }
                          // setPickupErr={setPickupErr}
                          value={fomattAdressPickUpLocation}
                        ></PlacesAutocomplete>
                      }
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-0 p-1 row">
                      {isLoaded &&
                        <PlacesAutocomplete
                          placeholder="Drop Location"
                          passLocation={handleDropLocation}
                          addStop={false}
                          handleViaLocationInput={() => handleViaLocationInput()}
                          passFormattedAddress={
                            handleDropFormattedAddress
                          }
                        // setPickupErr={setPickupErr}
                        ></PlacesAutocomplete>
                      }

                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-0 p-1">
                      {showViaLocationInput &&
                        <>
                          {isLoaded &&
                            <PlacesAutocomplete
                              placeholder="Stop Location"
                              passLocation={handleViaLocation}
                              passFormattedAddress={handleViaFormattedAddress}
                              value={fomattAdressDropLocation}
                              addStop={true}
                              handleViaLocationInput={() => handleViaLocationInput()}
                            ></PlacesAutocomplete>
                          }

                        </>

                      }
                    </div>
                    {!showViaLocationInput &&
                      <div className="d-flex justify-content-center">
                        <button
                          type="button"
                          onClick={handleViaLocationInput}
                          className="btn btn-gradient-success"
                          style={{ margin: "0.5rem 0" }}
                        >
                          Add Stop
                        </button>
                      </div>
                    }

                  </div>

                  <div class="col-md-6">
                    <div class="mb-0 p-1">

                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={[
                            'DateTimePicker',
                          ]}
                        >

                          <DemoItem>
                            <DateTimePicker label="Ride Date And Time" onChange={onChangeDate}
                              value={date} />
                          </DemoItem>

                        </DemoContainer>
                      </LocalizationProvider>

                    </div>


                  </div>
                </div>

                <div class="row mt-3">
                  <div class="col-md-6">
                    <div class="mb-0 p-1">
                      <FormControl fullWidth>
                        <InputLabel id="car-type">Car Type</InputLabel>
                        <Select
                          labelId="car-type"
                          value={carType}
                          label="Car Type"
                          onChange={(e) => handleCarTypeChange(e)}
                        >

                          {carTypeLabel.map((d, i) => {
                            return (
                              <MenuItem key={i} value={d.value}>{d.label}</MenuItem>
                            )
                          })}

                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-0 p-1">
                      <div className="d-flex justify-content-around align-items-center">

                        <button
                          type="button"
                          onClick={handleShowRegisterUser}
                          className="btn btn-gradient-primary"
                        >
                          Ride For Registered User
                        </button>
                        <button
                          type="button"
                          onClick={handleShowURegisterUser}
                          className="btn btn-gradient-danger"
                        >
                          Ride For UnRegistered User
                        </button>

                      </div>
                    </div>
                  </div>
                </div>

                {showUnRegUser && <div class="row  mt-3">
                  <div class="col-md-4">
                    <div class="mb-0 p-1">
                      <TextField value={riderName}
                        fullWidth
                        onChange={(e) => setRiderName(e.target.value)}
                        type="text"
                        placeholder={"Enter Rider Full Name"} />

                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="mb-0 p-1">
                      <TextField value={email}
                        fullWidth
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                        placeholder="Enter Rider Email Address" />

                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="mb-0 p-1">
                      <PhoneInput
                        className="form-control"
                        defaultCountry="SE"
                        value={phone}
                        limitMaxLength={true}
                        onChange={setPhone}
                        placeholder={"Enter Rider Mobile Number"}
                      />

                    </div>
                  </div>



                </div>}


                <div class="row  mt-3">
                  <div class="col-md-6">
                    <div class="mb-0 p-1">

                      {showExistingUser &&
                        <>{isULoading ?
                          <div
                            className="mt-3"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginBottom: 20,
                            }}
                          >
                            <CircularProgress />
                          </div> :
                          <Autocomplete
                            fullWidth
                            disablePortal
                            id="select user"
                            options={userLabels}
                            onChange={(event, newValue) => {
                              if (newValue) {
                                setCreatedFor(newValue.id);
                              }
                            }}
                            renderInput={(params) => <TextField {...params} label="Select User" />}

                          />
                        }
                        </>}

                    </div>
                    <div class="col-md-6">
                      <div class="mb-0 p-1">
                      </div>
                    </div>
                  </div>
                </div>



                <div class="row  mt-3">
                  <div class="col-md-6">
                    <div class="mb-0 p-1">
                      <div className="card shadow-sm ">
                        <div className="card-body" style={{ padding: 0 }}>
                          <MapComponent
                            origin={fomattAdressPickUpLocation}
                            destination={fomattAdressDropLocation}
                            waypoints={[fomattAdressViaLocation]}
                            setRideResult={setRideResult}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-0 p-1">
                      {rideResult?.distance && carType ? (
                        <div className="d-flex justify-content-center align-items-center">
                          <div className="card shadow-sm border-primary">
                            <div className="card-header text-center">Ride Details</div>
                            <div className="card-body">
                              <p className="card-text">Distance: <span>{Math.round(rideResult.distance.value / 1000)} KM</span></p>
                              <p className="card-text">Fair Charges: <span>{Math.round(fairCharges)} SEK</span></p>
                            </div>
                          </div>
                        </div>
                      ) : <div className="d-flex justify-content-center">
                        <p>
                          Please select a Pickup-Drop location and Car Type to proceed
                        </p>
                      </div>
                      }
                    </div>
                  </div>
                </div>

                <div className="d-flex justify-content-center mt-5">
                  <button type="button" class="btn btn-gradient-primary" onClick={handleAddRideApiCall}>Submit</button>
                </div>

              </form>
            }

          </div>
        </div>
      </div>

      {showModal && (
        <Modal
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
          open={showModal}
          keepMounted
        >
          <ModalContent sx={{ width: 400 }}>
            <h4 id="keep-mounted-modal-title" className="modal-title">
              Select Preferred Options
            </h4>
            <form class="forms-sample">
              <div className="mb-3">
                <div class="form-check-primary">
                  <label class="">
                    <input type="checkbox" class="form-check-input"
                      id="childSeatCheckbox"
                      checked={childSeatChecked}
                      onChange={handleChildSeatChange} /> Child Seat </label>
                </div>
              </div>

              <div className="mb-3">
                <div class="form-check-primary">
                  <label class="">
                    <input type="checkbox" class="form-check-input"
                      id="petCheckbox"
                      checked={petChecked}
                      onChange={handlePetChange} /> Pet Seat </label>
                </div>
              </div>

              <div className="mb-3">
                <div class="form-check-primary">
                  <label class="">
                    <input type="checkbox" class="form-check-input"
                      id="luggageCheckbox"
                      checked={luggageChecked}
                      onChange={handleLuggageChange} /> Luggage Seat </label>
                </div>
              </div>



              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>
                  Ok
                </button>
                <button type="button" className="btn-danger btn" onClick={() => setShowModal(false)}>Close</button>
              </div>
            </form>

          </ModalContent>
        </Modal>
        // <div className="modal" style={{ display: 'block', zIndex: '1040', backgroundColor: "rgba(0, 0, 0, 0.3)" }}>
        //   <div
        //     className="modal show d-block"
        //     tabIndex="-1"
        //     role="dialog"
        //     aria-modal="true"
        //     style={{ zIndex: '1050', display: 'block' }}
        //   >
        //     <div className="modal-dialog modal-dialog-centered" role="document">
        //       <div className="modal-content">
        //         <div className="modal-header">
        //           <h5 className="modal-title"></h5>
        //           <button type="button" className="close" onClick={handleCloseModal}>
        //             <span aria-hidden="true">&times;</span>
        //           </button>
        //         </div>
        //         <div className="modal-body">

        //       </div>
        //     </div>
        //   </div>
        // </div>
      )}

      {directions === null &&
        fomattAdressPickUpLocation &&
        fomattAdressDropLocation ? (
        <DirectionsService
          options={{
            destination: fomattAdressDropLocation,
            origin: fomattAdressPickUpLocation,
            ...(fomattAdressViaLocation
              ? { waypoints: [{ location: fomattAdressViaLocation }] }
              : {}),
            travelMode: "DRIVING",
          }}
          callback={directionsCallback}
        />
      ) : (
        <></>
      )}
    </>


    // </>
  );
};




const blue = {
  200: '#99CCFF',
  300: '#66B2FF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  700: '#0066CC',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;


const ModalContent = styled('div')(
  ({ theme }) => css`
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    text-align: start;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border-radius: 8px;
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0 4px 12px
      ${theme.palette.mode === 'dark' ? 'rgb(0 0 0 / 0.5)' : 'rgb(0 0 0 / 0.2)'};
    padding: 24px;
    color: ${theme.palette.mode === 'dark' ? grey[50] : grey[900]};

    & .modal-title {
      margin: 0;
      line-height: 1.5rem;
      margin-bottom: 8px;
    }

    & .modal-description {
      margin: 0;
      line-height: 1.5rem;
      font-weight: 400;
      color: ${theme.palette.mode === 'dark' ? grey[400] : grey[800]};
      margin-bottom: 4px;
    }
  `,
);

const TriggerButton = styled('button')(
  ({ theme }) => css`
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.5;
    padding: 8px 16px;
    border-radius: 8px;
    transition: all 150ms ease;
    cursor: pointer;
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);

    &:hover {
      background: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
      border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
    }

    &:active {
      background: ${theme.palette.mode === 'dark' ? grey[700] : grey[100]};
    }

    &:focus-visible {
      box-shadow: 0 0 0 4px ${theme.palette.mode === 'dark' ? blue[300] : blue[200]};
      outline: none;
    }
  `,
);


export default AddrideForm;