import React from "react";
import AllDataCommonComp from "../common/common";
import DriverCard from "../../../cards/driver/drivercards";
import { faUser } from "@fortawesome/free-solid-svg-icons";

const AllDriverMainComp=()=>{
    return(
        <>
            <AllDataCommonComp addUrl="/dashboard/add-employee"  headerIcon={faUser} allemployee="All Drivers"   downloadCall="driver"></AllDataCommonComp>
            <DriverCard></DriverCard>
        </>
        
    )
}

export default AllDriverMainComp;