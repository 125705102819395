import React,{useEffect} from "react";
import SidebarComp from "../../components/sidebar/sidebar";
import NavbarComp from "../../components/navbar/navbar";
import AvailableRidesMainComp from "../../components/body/alldatacomponent/rides/allavailableridesmaincomponent";

const AvailableRideScreen=()=>{
   
    
    return(
        <div className="container-scroller">
            <NavbarComp />
            <div className="container-fluid page-body-wrapper">
                <SidebarComp />
                <div className="main-panel">
                    <div className="content-wrapper">
                        <AvailableRidesMainComp />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AvailableRideScreen;