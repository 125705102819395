import React from "react";
import SidebarComp from "../../../components/sidebar/sidebar";
import NavbarComp from "../../../components/navbar/navbar";
import AllDriverMainComp from "../../../components/body/alldatacomponent/driver/alldrivermaincomp";

const MyDriver = () => {
  return (
    <div className="container-scroller">
    <NavbarComp />
    <div className="container-fluid page-body-wrapper">
        <SidebarComp />
        <div className="main-panel">
            <div className="content-wrapper">
                <AllDriverMainComp />
            </div>
        </div>
    </div>
</div>
  )
}

export default MyDriver