const stockholmDate = (date) => {
  const datetime = new Date(date);
  const options = {
    timeZone: "Europe/Stockholm",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  const stockholmDatetimeString = datetime.toLocaleString("en-US", options);
  return stockholmDatetimeString
};

export default stockholmDate;
