import React from "react";
import DasboarTopComp from "../../main/dashboardtop/dashboardtop";
import DashboardTopMidComp from "../../main/dashboardtopmid/dashboardtopmid";
import AddCarMidComp from "../addcarmaincomp/addcarmaincomp";

const AddCarMainComp=()=>{
    return(
        <div class="container-fluid">
            <AddCarMidComp></AddCarMidComp>
        </div>
    )
}

export default AddCarMainComp;