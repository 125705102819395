import React from "react";
import SidebarComp from "../../components/sidebar/sidebar";
import NavbarComp from "../../components/navbar/navbar";
import AllCompanyMainComp from "../../components/body/alldatacomponent/company/allcompanymaincomp";

const AllCompanyeeScreen=()=>{
    return(
        <div className="container-scroller">
            <NavbarComp />
            <div className="container-fluid page-body-wrapper">
                <SidebarComp />
                <div className="main-panel">
                    <div className="content-wrapper">
                        <AllCompanyMainComp />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AllCompanyeeScreen;