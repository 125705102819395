import React from "react";
import AllDataCommonComp from "../common/common";
import EmployeeCards from "../../../cards/employee/employeecard";
import { faUserTie } from "@fortawesome/free-solid-svg-icons";

const AllEmployeesMainComp=()=>{
    return(
        <>
            <AllDataCommonComp addUrl="/dashboard/add-employee"  headerIcon={faUserTie} allemployee="All Employee"  downloadCall="employee"></AllDataCommonComp>
            <EmployeeCards></EmployeeCards>
        </>
        
    )
}

export default AllEmployeesMainComp;